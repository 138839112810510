import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import { handleFormikChange, handleFormikSubmit, underScoreToTitlecase } from "../../../Utils/helpers";
import FormField from "../../FormFields/FormField";
import { useDispatch } from "react-redux";
import ButtonElement from "../ButtonElement";
import { useFormik } from "formik";
import * as yup from "yup";
import FormFieldArea from "../../FormFields/FormFieldArea";
import FormFieldSelect from "../../FormFields/FormFieldSelect";
import { RatingConstants } from "../../../Constants/rating";

function AppointmentFeedbackModal(props) {

  const validationSchema = yup.object({
    rating: yup.string().required(),
    feedback: yup.string().required(),
  });

  const modalForm = useFormik({
    initialValues: {
      rating: "",
      feedback: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props?.onConfirm(values);
    },
  });

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        className=""
        size="lg"
      >
        <Modal.Header closeButton>Rate Appointment</Modal.Header>
        <Modal.Body>

          <Form onSubmit={(e) => handleFormikSubmit(e, modalForm)}>

            <FormFieldSelect
              id="rating"
              name="rating"
              label={"Rating"}
              options={[
                { _id: RatingConstants.TYPE.poor, name: underScoreToTitlecase(RatingConstants.TYPE.poor) },
                { _id: RatingConstants.TYPE.veryPoor, name: underScoreToTitlecase(RatingConstants.TYPE.veryPoor) },
                { _id: RatingConstants.TYPE.average, name: underScoreToTitlecase(RatingConstants.TYPE.average) },
                { _id: RatingConstants.TYPE.good, name: underScoreToTitlecase(RatingConstants.TYPE.good) },
                { _id: RatingConstants.TYPE.veryGood, name: underScoreToTitlecase(RatingConstants.TYPE.veryGood) },
                { _id: RatingConstants.TYPE.poor, excellent: underScoreToTitlecase(RatingConstants.TYPE.excellent) },
              ]}
              value={modalForm.values.rating}
              onChange={(e) => handleFormikChange(e, modalForm)}
              showError={modalForm.touched.rating}
              errorText={modalForm.errors.rating}
              placeholder={"Select Rating"}
            />

            <FormFieldArea
              rows="10"
              id="feedback"
              name="feedback"
              label={"Feedback"}
              value={modalForm.values.feedback}
              onChange={(e) => handleFormikChange(e, modalForm)}
              showError={modalForm.touched.feedback}
              errorText={modalForm.errors.feedback}
              placeholder={"Enter Feedback"}
            />

            <div className="theme-formbtn">
              <ButtonElement variant="primary" type="submit" className="w-100">
                Submit
              </ButtonElement>
            </div>

          </Form>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default AppointmentFeedbackModal;
