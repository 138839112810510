import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { getFaqs } from "../../../Services/public-service";
import FaqItem from "./FaqItem";

const Faqs = (props) => {

  const [faqs, setFaq] = useState([]);

  const loadData = () => {
    getFaqs().then((res: any) => {
      setFaq(res?.data?.docs);
    }).catch((err) => {
      
    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <section id="faq" className="section-pad">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="section-title section-title-2 left">
                <h1>Frequently asked questions</h1>
                <p>Contact us for more info</p>
              </div>
            </Col>
            <Col lg={8}>
              <div className="Faqsryt">
                {faqs && faqs.map((faq, index) => (
                  <Col md={12}>
                    <Accordion>
                      <FaqItem faq={faq} i={index + 1} />
                    </Accordion>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Faqs;
