import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { Suspense } from "react";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AppProvider } from './Hooks/useAppContext';
import store from './Redux/store'
import MainLoader from './Components/Loader/MainLoader';

const rootElement: any = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<MainLoader />}>
          <AppProvider>
            <App />
          </AppProvider>
      </Suspense>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
