import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const SiteLayout = () => {


    return (
        <>
            <Header />
            <div className='site-wrapper'>
                <Outlet />
            </div>
            <Footer />
        </>
    );
}

export default SiteLayout;