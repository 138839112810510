import React from "react";

import AdminLoginForm from "../../Components/Auth/AdminLoginForm";
import useTranslator from "../../Hooks/useTranslator";

const AdminLogin = (props: any) => {
  const { _t } = useTranslator();

  return (
    <>
      <div className="formtitlebar">
        <h4>Welcome</h4>
        <p>Welcome! Please enter your details.</p>
      </div>
      <AdminLoginForm />
    </>
  );
};

export default AdminLogin;
