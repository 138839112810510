import * as yup from "yup";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ImageSelector from '../../Components/FormFields/ImageSelector';
import BlogItem from '../../Components/Site/Blog/BlogItem';
import { handleFormikChange, handleFormikSubmit, haveValue, initialPaginationData } from '../../Utils/helpers';
import FormField from '../../Components/FormFields/FormField';
import { useFormik } from 'formik';
import { useDispatch } from "react-redux";
import FormFieldArea from "../../Components/FormFields/FormFieldArea";
import ButtonElement from "../../Components/Common/ButtonElement";
import { useEffect, useRef, useState } from "react";
import { LuImagePlus } from "react-icons/lu";
import { BiPlus } from "react-icons/bi";
import { uploadBlogImage } from "../../Services/media-service";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { addBlog, getBlog, updateBlog } from "../../Services/blogs-service";
import Pagination from "../../Components/Common/Pagination";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const AdminBlogs = () => {

  const inputElement: any = useRef()
  const dispatch = useDispatch();

  const [blogId, setBlogId]: any = useState(null);
  const [page, setPage] = useState(1);

  const handlePageChange = (page) => {
    setPage(page);
  }

  const [paginationData, setPaginationData] = useState(initialPaginationData());

  const openFilePicker = () => {
    inputElement.current.click();
  }

  useEffect(() => {
    loadBlogs();
  }, [page])

  const handleChange = (e: any) => {
    var formData = new FormData();
    formData.append("media", e.target.files[0]);
    dispatch(showLoadingToast("Uploading Image"))
    uploadBlogImage(formData)
      .then((res: any) => {
        dispatch(hideLoadingToast(null));
        let item = res?.data[0];
        form?.setFieldValue("imgUrl", item?.path);
      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      })
  }

  const validationSchema = yup.object({
    title: yup
      .string()
      .required("title is Required"),
    author: yup
      .string()
      .required("author is Required"),
    body: yup
      .string()
      .required("body is Required"),
    imgUrl: yup
      .string()
      .required("image is Required"),
  });

  const loadBlogs = () => {
    getBlog({
      page
    })
      .then((res: any) => {
        setPaginationData(res?.data);
      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      });
  }

  const form = useFormik({
    initialValues: {
      title: "",
      author: "",
      body: "",
      imgUrl: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(showLoadingToast("Saving Blog"))
      let request: any = null;
      if (haveValue(blogId)) {
        request = updateBlog(blogId, values);
      } else {
        request = addBlog(values);
      }
      request.then(res => {
        dispatch(showSuccessToast("Blog Saved"))
      })
        .catch(err => {
          dispatch(showErrorToast(err?.message))
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
          loadBlogs();
          setBlogId(null);
        })
    },
  });

  const handleEdit = (blog: any) => {
    setBlogId(blog?._id);
    form?.setFieldValue("title", blog?.title);
    form?.setFieldValue("author", blog?.author);
    form?.setFieldValue("body", blog?.body);
    form?.setFieldValue("imgUrl", blog?.imgUrl);
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Blogs</h1>
              </div>
            </Col>
          </Row>

          <form onSubmit={(e) => handleFormikSubmit(e, form)}>
            <Row className='mb-4'>
              <Col md={3}>
                <div className="d-none">
                  <input ref={inputElement} name="media" type="file" onChange={handleChange} />
                </div>
                <div onClick={openFilePicker} className="img-selector img-selector-clicker">
                  <LuImagePlus />
                  <h6>
                    <BiPlus />
                    <span>Add Image</span>
                  </h6>
                  <p>(Recommended size 1080X1080)</p>
                </div>
              </Col>
              <Col md={9}>

                <Row>
                  <Col>
                    <FormField
                      id="title"
                      name="title"
                      label={"Title"}
                      value={form.values.title}
                      onChange={(e) => handleFormikChange(e, form)}
                      showError={form.touched.title}
                      errorText={form.errors.title}
                      placeholder={"Enter Title"}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormField
                      id="author"
                      name="author"
                      label={"Author"}
                      value={form.values.author}
                      onChange={(e) => handleFormikChange(e, form)}
                      showError={form.touched.author}
                      errorText={form.errors.author}
                      placeholder={"Enter Author"}
                    />
                  </Col>
                </Row>

              </Col>
            </Row>


            <Row>
              <Col className="mb-3">
                <CKEditor
                  editor={ClassicEditor}
                  data={form.values.body}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleFormikChange(data, form, "body");
                  }}
                />
              </Col>
            </Row>

            <ButtonElement type="submit">
              Save Blog
            </ButtonElement>
          </form>

          <hr />

          <Row>
            <Col md={12}>
              <h1 className='section-title-6'>Recently Added</h1>
            </Col>
          </Row>

          <Row>
            {
              paginationData?.docs?.map((blog) => {
                return <>
                  <Col md={12}>
                    <BlogItem handleEdit={handleEdit} onRefresh={loadBlogs} blog={blog} actions={true} />
                  </Col>
                </>;
              })
            }
          </Row>

          <Row>
            <Col md={12}>
              {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                  <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                </> : null
              }
            </Col>
          </Row>

        </div>
      </div>
    </>
  )
}

export default AdminBlogs;