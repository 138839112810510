import React, { useEffect, useState } from 'react';
import { CChart } from '@coreui/react-chartjs';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { AiOutlineRight } from 'react-icons/ai'
import { ProgressBar } from 'react-bootstrap';
import FormFieldSelect from '../../../Components/FormFields/FormFieldSelect';

const GraphData = () => {


    useEffect(() => {
        changeDuration('weekly')
    }, [])

    const [duration, setDuration]: any = useState({
        labels: [],
        tenantData: [],
        landlordData: [],
        propertiesData: [],
    });

    const changeDuration = (value) => {
        if (value == "weekly") {
            setDuration({
                ...duration,
                labels: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
                tenantData: [40, 20, 12, 39, 10, 40, 39, 80, 40],
                landlordData: [50, 12, 28, 29, 7, 25, 12, 70, 60],
                propertiesData: [50, 12, 28, 29, 7, 25, 12, 70, 60]
            })
        }
        if (value == "monthly") {
            setDuration({
                ...duration,
                labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                tenantData: [40, 20, 12, 39, 10, 40, 39, 80, 40, 23, 28, 12, 40, 32, 20, 90],
                landlordData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60],
                propertiesData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60]
            })
        }
        if (value == "yearly") {
            setDuration({
                ...duration,
                labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                tenantData: [40, 20, 12, 39, 10, 40, 39, 80, 40, 23, 28, 12, 40, 32, 20, 90],
                landlordData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60],
                propertiesData: [50, 12, 28, 29, 7, 25, 12, 70, 60, 25, 10, 55, 12, 14, 10, 60]
            })
        }
    }


    return (
        <>
            <div>
                <Row>
                    <Col md={9}>
                        <div className='line_chart_admin border_background'>
                            <div className='buttons_graph'>
                                <FormFieldSelect
                                    style={{ minWidth: "200px" }}
                                    id="type"
                                    name="type"
                                    label={false}
                                    options={[
                                        { _id: "doctor-patients", name: "Doctor-Patients" },
                                    ]}
                                    value={"doctor-patients"}
                                    placeholder={"Select Type"}
                                />
                            </div>
                            <CChart
                                type="line"

                                data={{
                                    labels: duration?.labels,
                                    datasets: [
                                        {
                                            label: "Doctors",
                                            backgroundColor: "#1199DE",
                                            borderColor: "#1199DE",
                                            pointBackgroundColor: "#1199DE",
                                            pointBorderColor: "#fff",
                                            data: duration.tenantData,
                                        },
                                        {
                                            label: "Patients",
                                            backgroundColor: "rgba(1, 1, 1, 0.84)",
                                            borderColor: "rgba(1, 1, 1, 0.84)",
                                            pointBackgroundColor: "rgba(1, 1, 1, 1)",
                                            pointBorderColor: "#fff",
                                            data: duration.landlordData
                                        },
                                    ],
                                }}
                            />
                        </div>

                    </Col>
                    <Col md={3}>

                        <div className='border_background suppliers_bar'>
                            <FormFieldSelect
                                id="type"
                                name="type"
                                label={false}
                                options={[
                                    { _id: "doctor-patients", name: "Patients by Age" },
                                ]}
                                value={"doctor-patients"}
                                placeholder={"Select Type"}
                            />
                            <Row>
                                <Col md={10}>
                                    <p>18-24</p>
                                </Col>
                                <Col md={2}>
                                    <p>44%</p>
                                </Col>
                                <ProgressBar now={100} variant="dark" visuallyHidden />
                                <Col md={12}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <p>24-32</p>
                                </Col>
                                <Col md={2}>
                                    <p>22%</p>
                                </Col>
                                <ProgressBar now={90} variant="dark" visuallyHidden />
                                <Col md={12}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <p>32-42</p>
                                </Col>
                                <Col md={2}>
                                    <p>14%</p>
                                </Col>
                                <ProgressBar now={70} variant="dark" visuallyHidden />
                                <Col md={12}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <p>12-18</p>
                                </Col>
                                <Col md={2}>
                                    <p>8%</p>
                                </Col>
                                <ProgressBar now={40} variant="dark" visuallyHidden />
                                <Col md={12}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <p>54-68</p>
                                </Col>
                                <Col md={2}>
                                    <p>8%</p>
                                </Col>
                                <ProgressBar now={40} variant="dark" visuallyHidden />
                                <Col md={12}>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <p>42-54</p>
                                </Col>
                                <Col md={2}>
                                    <p>4%</p>
                                </Col>
                                <ProgressBar now={10} variant="dark" visuallyHidden />
                                <Col md={12}>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

              
            </div>
        </>
    )
}

export default GraphData;