import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const FormFieldSelect = (props: any) => {
  // Prop Destructuring
  const {
    label = "",
    errorText = "",
    onChange = () => { },
    placeholder = "",
    options = [],
    value = null,
    showError = false
  } = props;

  // State Variables
  const [haveError, setHaveError] = useState(false);

  // Mounting
  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
  }, [props]);

  return (
    <>
      <Form.Group
        className={`theme-forminput ${haveError ? "erroractive" : ""}`}
      >
        {
          label ? <>
            <Form.Label>{label}</Form.Label>
          </> : null
        }
        <InputGroup>
          <div className="form-Input-group-fields">
            <Form.Select
              className="form-control"
              aria-label="Default select Industry"
              {...props}
              onChange={onChange}
            >
              <option value="">{placeholder}</option>
              {options.length &&
                options.map((e) => (
                  <option
                    key={e?._id}
                    value={e?._id}
                    selected={e?._id === value}
                  >
                    {e?.name}
                  </option>
                ))}
            </Form.Select>
          </div>
        </InputGroup>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormFieldSelect;
