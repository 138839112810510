import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import NoTabRow from "../../Components/Common/NoTabRow";
import Pagination from "../../Components/Common/Pagination";
import SearchBar from "../../Components/Common/SearchBar";
import DoctorBox from "../../Components/Site/Doctors/DoctorBox";
import { hideLoadingToast, showErrorToast, showLoadingToast } from "../../Redux/slices/toastSlice";
import { getDoctors } from "../../Services/public-service";
import { initialPaginationData } from "../../Utils/helpers";

const FindDoctors = (props) => {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm]: any = useState(searchParams.get("q"));


  useEffect(() => {
    setSearchTerm(searchParams.get("q"));
  }, [searchParams]);

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getDoctors({
      page,
      search: searchTerm
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  return (
    <>

      <section id="find-doctors" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>Find Doctors</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={12}>
              <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeholder="Search doctor here..." />
            </Col>
          </Row>

          <Row>

            {
              paginationData?.docs && paginationData?.docs.length > 0 ? <>
                {paginationData?.docs?.map((data: any, index) => (
                  <Col key={index} lg={2} md={3} sm={6}>
                    <DoctorBox link={`/doctors/${data?._id}`} doctor={data} />
                  </Col>
                ))}
              </> : <>
                <NoTabRow colSpan={5} />
              </>
            }

          </Row>
          <Row>
            <Col>
              <Pagination paginationData={paginationData} />
            </Col>
          </Row>

        </Container >
      </section >

    </>
  );
};

export default FindDoctors;
