import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RiDashboardLine } from 'react-icons/ri';
import { BsBag, BsCurrencyDollar, BsChat, BsWallet } from 'react-icons/bs';
import { MdLogout, MdOutlineVerified } from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../../Redux/slices/authSlice';
import { NavDropdown } from 'react-bootstrap';
import { BiCalendar, BiCalendarCheck, BiDesktop, BiNews } from 'react-icons/bi';

const Sidebar = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: any) => state.auth);

    const handleLogout = () => {
        dispatch(logout(null));
    }

    return ( 
        <>
            <div className="sidebar-panel">
                <div className="sidebarhead">
                    <Link to="/" className="logobar">
                        <img src="/images/logo-hd.png" alt="logo" />
                    </Link>
                    <ul>
                        <li>
                            <NavLink to='/admin/dashboard'>
                                <RiDashboardLine size={20} />
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/home'>
                                <BiDesktop size={20} />
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/blogs'>
                                <BiNews size={20} />
                                Blogs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/appointments'>
                                <BiCalendar size={20} />
                                Appointments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/previous-appointments'>
                                <BiCalendarCheck size={20} />
                                Previous Appointments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/patients'>
                                <BsCurrencyDollar size={20} />
                                Patients
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/doctors'>
                                <BsChat size={20} />
                                Doctors
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admin/corporates'>
                                <BsWallet size={20} />
                                Corporate
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleLogout} to='/'>
                                <MdLogout size={20} />
                                Logout
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>


        </>
    )
}

export default Sidebar