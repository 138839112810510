import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiEdit, BiLocationPlus } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import FormFieldSelect from '../../Components/FormFields/FormFieldSelect';
import ButtonElement from '../../Components/Common/ButtonElement';
import SearchBar from '../../Components/Common/SearchBar';
import DoctorBox2 from '../../Components/Site/Doctors/DoctorBox2';
import { useEffect, useState } from 'react';
import { addPatientsToCorporates, deleteCorporate, deletePatient, getCorporate, getCorporates, getPatients, removePatientFromCorporate } from '../../Services/admin-service';
import { haveValue, initialPaginationData, printFullName, printTime } from '../../Utils/helpers';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Components/Common/Pagination';
import NoTabRow from '../../Components/Common/NoTabRow';
import CorporateFormModal from '../../Components/Modal/CorporateFormModal';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import FormFieldSelectSearch from '../../Components/FormFields/FormFieldSelectSearch';
import PatientBox from '../../Components/Patient/PatientBox';

const CorporateDetails = () => {

  const params = useParams();
  const dispatch = useDispatch();

  const [modelData, setModelData]: any = useState(null);
  const [searchTerm, setSearchTerm]: any = useState("");
  const [sortType, setSortType]: any = useState("newest");

  const [loading, setLoading]: any = useState(false);
  const [showModal, setShowModel]: any = useState(false);

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);

  const [selectedPatients, setSelectedPatients] = useState([]);

  const { showingLoading } = useSelector((state: any) => state.toast);

  const hideModal = () => {
    setShowModel(false);
  }

  const addModalOpen = () => {
    setShowModel(true);
  }

  useEffect(() => {
    if (haveValue(params?.corporateId)) {
      setLoading(true);
      getCorporate({ id: params?.corporateId }).then((res: any) => {
        setModelData(res?.data);
        setLoading(false);
      }).catch((err: any) => {
        dispatch(showErrorToast("Failed to load corporate details"));
      });
    }
  }, [params]);

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getPatients({
      page,
      search: searchTerm,
      sort: sortType,
      corporate: params?.corporateId
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, sortType, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  const deleteIt = (data) => {
    if (window.confirm("Are you sure you want to remove this patient from corporate?") === true) {
      removePatientFromCorporate({
        corporateId: modelData?._id,
        patient: data?._id
      }).then((res) => {
        dispatch(showSuccessToast("Removed successfully"));
        loadData(page);
      }).catch((err) => {
        dispatch(showErrorToast("Failed to remove"));
      });
    }
  }

  const closeModal = () => {
    setShowModel(false);
    loadData(page);
    setModelData(null);
  }

  const handleEdit = (data) => {
    setModelData(data);
    setShowModel(true);
  }

  const loadPatients = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    getPatients({
      search: inputValue,
      reason: 'toAddInCorporates'
    }).then((res: any) => {
      let itemsTe: any = [];
      let data = res?.data;
      if (data.docs.length > 0) {
        for (let item of data.docs) {
          itemsTe.push({
            value: item?._id,
            label: printFullName(item)
          });
        }
      }
      callback(itemsTe);
    }).catch((err: any) => {
      callback([]);
    });
  };

  const addPatients = () => {
    if (selectedPatients.length > 0) {
      dispatch(showLoadingToast(null));
      addPatientsToCorporates({
        corporateId: modelData?._id,
        patients: selectedPatients
      }).then((res: any) => {
        dispatch(showSuccessToast(res?.message));
        setSelectedPatients([]);
        loadData(1);
        hideModal();
      })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    } else {
      dispatch(showErrorToast("Select atleast one patient"));
    }
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Corporate Patients</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4>{modelData?.name}</h4>
              <p className='mb-3'> <BiLocationPlus /> {modelData?.address}</p>
              <div className="table-head-options">
                <div className='left'>
                  <FormFieldSelect
                    id="type"
                    name="type"
                    label={false}
                    onChange={(e) => setSortType(e?.target?.value)}
                    options={[
                      { _id: "newest", name: "Newest" },
                      { _id: "oldest", name: "Oldest" },
                    ]}
                    value={sortType}
                    placeholder={"Select Type"}
                  />
                </div>
                <div className='right'>
                  <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  <ButtonElement onClick={addModalOpen} className="ms-2" variant="primary" >
                    Add Patients to Corporate
                  </ButtonElement>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date of Birth</th>
                      <th>Gender</th>
                      <th>Date Joined</th>
                      <th>Mobile No.</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paginationData?.docs && paginationData?.docs.length > 0 ? <>
                        {paginationData?.docs?.map((data: any, index) => (
                          <tr key={index}>
                            <td className="table-doctor-box">
                              <PatientBox patient={data} />
                            </td>
                            <td>{data?.dateOfBirth}</td>
                            <td>{data?.gender}</td>
                            <td>{printTime(data?.createdAt)}</td>
                            <td>{data?.mobileNo}</td>
                            <td>{data?.email}</td>
                            <td>
                              <div className='table-actions'>
                                {/* <a href={""}>View Details</a> */}
                                <a onClick={(e) => deleteIt(data)} href={undefined} className="danger"><AiOutlineDelete /></a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </> : <>
                        <NoTabRow colSpan={5} />
                      </>
                    }

                  </tbody>
                </table>
              </div>
            </Col>

            <Col md={12}>
              {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                  <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                </> : null
              }
            </Col>

          </Row>

        </div>
      </div>

      <Modal
        className="verification_main_modal"
        show={showModal}
        size="lg"
        onHide={() => hideModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Add Patients to Corporate</Modal.Header>
        <Modal.Body>

          <Row>
            <Col>
              <FormFieldSelectSearch
                multiple="true"
                id="patients"
                name="patients"
                label={"Patients"}
                value={selectedPatients}
                onChange={(e) => {
                  setSelectedPatients(e);
                }}
                placeholder={"Search Patients"}
                loadOptions={loadPatients}
                defaultOptions={[]}
              />
            </Col>
          </Row>

          <div className="theme-formbtn text-end">
            <ButtonElement
              variant="primary"
              type="button" onClick={addPatients}
              disabled={showingLoading}
            >
              Save
            </ButtonElement>
          </div>

        </Modal.Body>
      </Modal>

    </>
  )
}

export default CorporateDetails;