import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import { handleFormikChange, handleFormikSubmit } from "../../../Utils/helpers";
import FormField from "../../FormFields/FormField";
import { useDispatch } from "react-redux";
import ButtonElement from "../ButtonElement";
import { useFormik } from "formik";
import * as yup from "yup";
import FormFieldArea from "../../FormFields/FormFieldArea";

function ErrorReasonAppointmentModal(props) {

  const validationSchema = yup.object({
    reason: yup.string().required(),
  });

  const modalForm = useFormik({
    initialValues: {
      reason: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props?.onConfirm(values);
    },
  });

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        className=""
        size="lg"
      >
        <Modal.Header closeButton>Error Occured</Modal.Header>
        <Modal.Body>

          <Form onSubmit={(e) => handleFormikSubmit(e, modalForm)}>

            <FormFieldArea
              rows="10"
              id="reason"
              name="reason"
              label={"Reason"}
              value={modalForm.values.reason}
              onChange={(e) => handleFormikChange(e, modalForm)}
              showError={modalForm.touched.reason}
              errorText={modalForm.errors.reason}
              placeholder={"Enter Reason of not able to join"}
            />

            <div className="theme-formbtn">
              <ButtonElement variant="primary" type="submit" className="w-100">
                Submit
              </ButtonElement>
            </div>

          </Form>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ErrorReasonAppointmentModal;
