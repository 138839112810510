import { AiFillStar } from "react-icons/ai";
import { printDate, printFullName, withBackendUrl } from "../../../Utils/helpers";
import RatingElement from "../../Common/Rating/RatingElement";

const DoctorAppointmentTestimonial = (props) => {

    const { appointment } = props;

    return (
        <>
            <div className="testimonial-box">
                <div className="user-info">
                    <div className="img" style={{ backgroundImage: `url(${withBackendUrl(appointment?.user?.profileImage)})` }}></div>
                    <div>
                        <h4>{printFullName(appointment?.user)}</h4>
                        <p>{printDate(appointment?.ratingByPatient?.time)}</p>
                    </div>
                    <div className="rating">  
                        <h5>
                            <span>{appointment?.ratingByPatient?.ratingStars}</span>
                            <span><AiFillStar /></span>
                        </h5>
                    </div>
                </div>
                <div className="review">
                    {appointment?.ratingByPatient?.feedback}
                </div>
            </div>
        </>
    );
};

export default DoctorAppointmentTestimonial;
