import { Col, Container, Row } from "react-bootstrap";
import { AiFillPlayCircle, AiOutlineDelete } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { deleteAllAbout } from "../../Services/all-about-service";
import { withBackendUrl } from "../../Utils/helpers";

const CaseStudyItem = (props) => {

    const { actions } = props;

    const dispatch = useDispatch();

    const deleteItem = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            deleteAllAbout(props?.allAbout?._id).then((res) => {
                dispatch(showSuccessToast("Item has been deleted"));
                props?.onRefresh();
            }).catch((err) => {
                dispatch(showErrorToast("Failed to delete"));
            });
        }
    }

    return (
        <>
            <div className="blog-box">
                {
                    actions ? <>
                        <div className='item-actions'>
                            {/* <a href={undefined} onClick={(e) => props?.handleEdit(props?.allAbout)}><BsPencil /></a> */}
                            <a href={undefined} onClick={deleteItem} className="danger"><AiOutlineDelete /></a>
                        </div>
                    </> : null
                }
                <div className="img-box">
                    <img src={withBackendUrl(props?.allAbout?.videoUrl)} />
                    <div className="img-box-actions">
                        <a href={undefined}><AiFillPlayCircle /></a>
                    </div>
                </div>
                <div className="content-box">
                    <div className="title">
                        <h1>{props?.allAbout?.title}</h1>
                    </div>
                    <p>{props?.allAbout?.body}</p>
                </div>
            </div>
        </>
    );
};

export default CaseStudyItem;
