import { Accordion, Col, Form, Modal, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useTranslator from '../../Hooks/useTranslator';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import ButtonElement from '../Common/ButtonElement';
import FormFieldSelect from '../FormFields/FormFieldSelect';
import { handleFormikChange, handleFormikSubmit, haveValue, printFullName, withBackendUrl } from '../../Utils/helpers';
import FormField from '../FormFields/FormField';
import TelephoneField from '../FormFields/TelephoneField';
import FormFieldArea from '../FormFields/FormFieldArea';
import { addCorporate, editCorporate } from '../../Services/admin-service';
import { upload } from '../../Services/media-service';
import FormFieldSelectSearch from '../FormFields/FormFieldSelectSearch';
import { getDoctors } from '../../Services/public-service';

const CorporateFormModal = (props) => {

    const { showModal, modelData } = props;

    const hideModal = () => {
        modalForm.resetForm();
        props?.onModalHide();
    }

    const inputRef = useRef(null);
    const { _t } = useTranslator();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showingLoading } = useSelector((state: any) => state.toast);

    const [defaultDoctors, setDefaultDoctors] = useState([]);

    let schemaObject: any = {
        name: yup.string().required(_t("Name is Required")),
        mobileNo: yup.string().required(_t("Phone No. is Required")),
        type: yup.string().required(_t("Type is Required")),
        address: yup.string().required(_t("Address is Required")),
        email: yup.string().required(_t("Email is Required")),
        doctors: yup.array(),
    };

    useEffect(() => {
        for (let k in modelData) {
            if (haveValue(schemaObject[k])) {
                handleFormikChange(modelData[k], modalForm, k);
            }
        }
        if (haveValue(modelData?.user)) {
            handleFormikChange(modelData?.user?.email, modalForm, 'email');
            handleFormikChange(modelData?.user?.mobileNo, modalForm, 'mobileNo');
        }
        handleFormikChange(parseDoctors(modelData?.doctors), modalForm, 'doctors'); 
    }, [modelData]);

    const validationSchema = yup.object(schemaObject);

    const parseDoctors = (doctors) => {
        let items: any = [];
        if (haveValue(doctors)) {
            for (let doctorItem of doctors) {
                items.push({
                    value: doctorItem?._id,
                    label: printFullName(doctorItem) + " (" + doctorItem?.speciality + ") "
                });
            }
        }
        return items;
    }

    const modalForm = useFormik({
        initialValues: {
            name: modelData?.name,
            mobileNo: modelData?.mobileNo,
            type: modelData?.type,
            address: modelData?.address,
            email: modelData?.user?.email,
            doctors: parseDoctors(modelData?.doctors)
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {
            dispatch(showLoadingToast(null));

            let request: any = null;

            if (haveValue(modelData?._id)) {
                request = editCorporate({
                    _id: modelData?._id,
                    ...values
                });
            } else {
                request = addCorporate(values);
            }

            request.then((res: any) => {
                dispatch(showSuccessToast(res?.message));
                hideModal();
            })
                .catch((err: any) => {
                    dispatch(showErrorToast(err?.message));
                })
                .finally(() => {
                    dispatch(hideLoadingToast(null));
                });
        },
    });

    const mediaChange = (e: any) => {
        dispatch(showLoadingToast("Uploading Image"))
        upload({
            media: e.target.files[0]
        })
            .then((res: any) => {
                handleFormikChange(res?.data, modalForm, "profileImage")
                dispatch(hideLoadingToast(null));
            })
            .catch(err => {
                dispatch(showErrorToast(err?.message))
            })
            .finally(() => {
                dispatch(hideLoadingToast(null));
            })
    }

    const openFilePicker = () => {
        if (inputRef?.current !== null) {
            // @ts-ignore
            inputRef.current.click();
        }
    }

    const loadDoctors = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        getDoctors({
            q: inputValue
        }).then((res: any) => {
            let doctors: any = [];
            let data = res?.data;
            if (data.docs.length > 0) {
                for (let item of data.docs) {
                    doctors.push({
                        value: item?._id,
                        label: printFullName(item) + " (" + item?.speciality + ") "
                    });
                }
            }
            callback(doctors);
        }).catch((err: any) => {
            callback([]);
        });
    };

    return (
        <>
            <div className='d-none'>
                <input ref={inputRef} type="file" placeholder="Upload Video" onChange={mediaChange} />
            </div>
            
            <Modal
                className="verification_main_modal"
                show={showModal}
                size="lg"
                onHide={() => hideModal()}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>Manage Corporate</Modal.Header>
                <Modal.Body>

                    <Form onSubmit={(e) => handleFormikSubmit(e, modalForm)}>

                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <FormField
                                            id="name"
                                            name="name"
                                            fieldType="name"
                                            label={"Name"}
                                            value={modalForm.values.name}
                                            onChange={(e) => handleFormikChange(e, modalForm)}
                                            showError={modalForm.touched.name}
                                            errorText={modalForm.errors.name}
                                            placeholder={"Enter Name"}
                                        />
                                    </Col>
                                    <Col>
                                        <FormFieldSelect
                                            id="type"
                                            name="type"
                                            label={"Type"}
                                            options={[
                                                { _id: "private", name: "Private" },
                                                { _id: "public", name: "Public" }
                                            ]}
                                            value={modalForm.values.type}
                                            onChange={(e) => handleFormikChange(e, modalForm)}
                                            showError={modalForm.touched.type}
                                            errorText={modalForm.errors.type}
                                            placeholder={"Select Type"}
                                        />
                                    </Col>
                                </Row>

                                <TelephoneField
                                    id="mobileNo"
                                    name="mobileNo"
                                    fieldType="mobileNo"
                                    label={"Mobile No."}
                                    value={modalForm.values.mobileNo}
                                    showError={modalForm.touched.mobileNo}
                                    onChange={(mobileNo) =>
                                        handleFormikChange(mobileNo, modalForm, "mobileNo")
                                    }
                                    errorText={modalForm.errors.mobileNo}
                                />

                                <FormField
                                    id="email"
                                    name="email"
                                    fieldType="email"
                                    label={"Email"}
                                    value={modalForm.values.email}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.email}
                                    errorText={modalForm.errors.email}
                                    placeholder={"Enter Email Address"}
                                />

                                <FormFieldArea
                                    rows="5"
                                    id="address"
                                    name="address"
                                    fieldType="textarea"
                                    label={"Address"}
                                    value={modalForm.values.address}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.address}
                                    errorText={modalForm.errors.address}
                                    placeholder={"Enter Address"}
                                />


                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <FormFieldSelectSearch
                                    multiple="true"
                                    id="doctors"
                                    name="doctors"
                                    label={"Doctors"}
                                    value={modalForm.values.doctors}
                                    onChange={(e) => {
                                        console.log(e);
                                        handleFormikChange(e, modalForm, "doctors")
                                    }}
                                    showError={modalForm.touched.doctors}
                                    errorText={modalForm.errors.doctors}
                                    placeholder={"Search Doctors"}
                                    loadOptions={loadDoctors}
                                    defaultOptions={defaultDoctors}
                                />
                            </Col>
                        </Row>

                        <div className="theme-formbtn text-end">
                            <ButtonElement
                                variant="primary"
                                type="submit"
                                disabled={showingLoading}
                            >
                                Save Corporate
                            </ButtonElement>
                        </div>

                    </Form>

                </Modal.Body>
            </Modal>

        </>
    )
}

export default CorporateFormModal;