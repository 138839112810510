import { AiOutlineArrowRight } from "react-icons/ai";

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="custom-next-arrow slick-arrow slick-2" onClick={onClick}>
            <AiOutlineArrowRight />
        </div>
    );
};

export default CustomNextArrow;