import ResetPasswordForm from "../../Components/Auth/ResetPasswordForm";
import useTranslator from "../../Hooks/useTranslator";

const ResetPassword = (props: any) => {
  const { _t } = useTranslator();

  return (
    <>
      <div className="formtitlebar">
        <h4>{_t("resetPasswordTitle")}</h4>
        <p>{_t("resetPasswordSubTitle")}</p>
      </div>

      <ResetPasswordForm />
    </>
  );
};

export default ResetPassword;
