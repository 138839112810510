import Accordion from "react-bootstrap/Accordion";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../Redux/slices/toastSlice";
import { deleteFaq } from "../../../Services/faq-service";

const FaqItem = (props) => {

  const { faq, i, actions } = props;
  const dispatch = useDispatch();

  const deleteItem = () => {
    if (window.confirm("Are you sure you want to delete?") === true) {
      deleteFaq(faq?._id).then((res) => {
        dispatch(showSuccessToast("Faq has been deleted"));
        props?.onRefresh();
      }).catch((err) => {
        dispatch(showErrorToast("Failed to delete FAQ"));
      });
    }
  }

  return (
    <>
      <Accordion.Item eventKey={i}>
        <Accordion.Header>
          <span>{i}</span>
          {faq?.question}
          {
            actions ? <>
              <div className='item-actions'>
              <a href={undefined} onClick={(e) => props?.handleEdit(faq)}><BsPencil /></a>
                <a href={undefined} onClick={deleteItem} className="danger"><AiOutlineDelete /></a>
              </div>
            </> : null
          }
        </Accordion.Header>
        <Accordion.Body>
          {faq?.answer}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );

};

export default FaqItem;
