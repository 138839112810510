import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFieldSelect from '../../../Components/FormFields/FormFieldSelect';
import { BsCurrencyRupee, BsPeople } from 'react-icons/bs';
import { BiCalendar, BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';
import { AiOutlineMedicineBox } from 'react-icons/ai';
import DoctorBox2 from '../../../Components/Site/Doctors/DoctorBox2';
import { getDashboard } from '../../../Services/doctor-service';
import { printAmount, printNumber } from '../../../Utils/helpers';
import NoTabRow from '../../../Components/Common/NoTabRow';
import DoctorAppointmentsList from '../../../Components/Site/Appointments/DoctorList';
import DoctorAppointmentsTodayList from '../../../Components/Site/Appointments/DoctorTodayList';
import DoctorPatientRow from '../../../Components/Patient/DoctorPatientRow';


const DoctorDashboard = () => {

    const [dashboardData, setDashboardData]: any = useState({});
    const [durationType, setDurationType]: any = useState("all");

    const loadDashhboard = () => {
        getDashboard({
            durationType
        }).then((res: any) => {
            setDashboardData(res?.data);
        }).catch((err) => {

        }).finally(() => {

        });
    }

    useEffect(() => {
        loadDashhboard();
    }, [durationType]);

    return (
        <>
            <div className='dashboard_layout ml-0'>
                <div className="cardcontainer">

                    <Row>
                        <Col>
                            <div className="section-title section-title-5 left">
                                <h1>Dashboard</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-2'>
                        <Col md={2}>
                            <FormFieldSelect
                                id="durationType"
                                name="durationType"
                                label={false}
                                options={[
                                    { _id: "all", name: "All" },
                                    { _id: "weekly", name: "Weekly" },
                                    { _id: "monthly", name: "Monthly" }
                                ]}
                                onChange={(e) => setDurationType(e.target.value)}
                                value={durationType}
                                placeholder={"Select Duration Type"}
                            />
                        </Col>
                    </Row>

                    <Row gap={5}>
                        <Col md={3} sm={12}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <BsCurrencyRupee />
                                        </div>
                                        <div className="text_data">
                                            <p>Total Earnings</p>
                                            <h1>
                                                <span className="val">{printAmount(dashboardData?.stats?.totalEarnings)}</span>
                                                <span className='growth d-none up'>
                                                    <span><BiUpArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <BsPeople />
                                        </div>
                                        <div className="text_data">
                                            <p>Patients</p>
                                            <h1>
                                                <span className="val">{printNumber(dashboardData?.stats?.totalPatients)}</span>
                                                <span className='growth d-none up'>
                                                    <span><BiUpArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <BiCalendar />
                                        </div>
                                        <div className="text_data">
                                            <p>Total Appointments</p>
                                            <h1>
                                                <span className="val">{printNumber(dashboardData?.stats?.totalAppointments)}</span>
                                                <span className='growth d-none up'>
                                                    <span><BiUpArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>

                <div className="section-pad pb-0">

                    <Row>
                        <Col>
                            <div className="section-title section-title-4 left">
                                <h1>Today</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} className="mx-auto">
                            <DoctorAppointmentsTodayList />
                        </Col>
                    </Row>


                </div>

                <div className="section-pad pb-0">


                    <Row>
                        <Col>
                            <div className="section-title section-title-4 left">
                                <h1>Upcoming Appointments</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} className="mx-auto">
                            <DoctorAppointmentsList />
                        </Col>
                    </Row>


                </div>

                <div className="section-pad">


                    <Row>
                        <Col>
                            <div className="section-title section-title-4 left">
                                <h1>Patients</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className='table-responsive'>
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <th>Patient</th>
                                            <th>Date of Birth</th>
                                            <th>Sex</th>
                                            <th>Email</th>
                                            <th>Phone No.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dashboardData?.patients?.docs.length > 0 ? <>
                                                {
                                                    dashboardData?.patients?.docs.map((data) => {
                                                        return <>
                                                            <DoctorPatientRow patient={data} />
                                                        </>
                                                    })
                                                }
                                            </> : <>
                                                <NoTabRow colSpan={6} />
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>


                </div>

            </div>
        </>
    )
}

export default DoctorDashboard;