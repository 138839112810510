import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ButtonElement from "../../Components/Common/ButtonElement";
import DateFormField from "../../Components/FormFields/DateFormField";
import DoctorProfileModal from "../../Components/Modal/DoctorProfileModal";
import { getLoginToken, haveValue, printAmount, withBackendUrl } from "../../Utils/helpers";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { deleteSlots, saveSlots } from "../../Services/user-service";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { endpoint } from "../../Config/endpoints";

const DoctorProfile = (props) => {

  const dispatch = useDispatch();
  const fullCalRef: any = useRef(null);
  const { user } = useSelector((state: any) => state.auth);

  const [showModal, setShowModel]: any = useState(false);
  const [stateUpdate, setStateUpdate] = useState(false);

  const closeModal = () => {
    setShowModel(false);
  }

  const handleDateSelect = (e: any) => {
    let startDate: any = moment(e.start);
    let endDate: any = moment(e.end);
    dispatch(showLoadingToast("Saving Slots"));
    saveSlots({
      start: startDate,
      end: endDate
    }).then((res: any) => {
      dispatch(showSuccessToast(res?.message));
      setStateUpdate(!stateUpdate);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  const handleEventClick = (eventClickInfo: any) => {
    if (window.confirm("This will remove this slot, you sure you want to delete it?") === true) {
      dispatch(showLoadingToast("Deleting Slot(s)"));
      deleteSlots({
        slotId: eventClickInfo.event.id
      }).then((res: any) => {
        dispatch(showSuccessToast(res?.message));
        setStateUpdate(!stateUpdate);
      }).catch((err: any) => {
        dispatch(showErrorToast(err?.message));
      }).finally(() => {
        dispatch(hideLoadingToast(null));
      });
    }
  }

  return (
    <>

      <DoctorProfileModal onModalHide={(e) => closeModal()} showModal={showModal} />

      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Profile</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} className="mx-auto">
              <div className="profile-info-box">
                <Row>
                  <Col>
                    <div id="doctor-info-box">
                      <div className="doctor-info">
                        <div className="img-box" style={{ backgroundImage: 'url("' + withBackendUrl(user?.profileImage) + '")' }}></div>
                        <div className="title">
                          <h1>{user?.firstName} {user?.lastName}</h1>
                          <h2>{user?.highestQualification}</h2>
                        </div>
                      </div>
                      <div className="doctor-details">
                        <div className="info-card">
                          <h3>About Doctor</h3>
                          <p>{user?.about}</p>
                        </div>
                        <div className="info-card">
                          <h3>Experience</h3>
                          <p>{user?.experience}</p>
                        </div>
                        <div className="info-card with-actions">
                          <div className="info">
                            <h3>Consultation Fee</h3>
                            <p className="price">{printAmount(user?.appointmentPrice)}</p>
                          </div>
                          <div className="button-box">
                            <ButtonElement onClick={(e) => setShowModel(true)} text="Edit Profile" variant="primary" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="user-list-info user-list-info-2-1">
                      <ul>
                        <li>
                          <p>Email:</p>
                          <h5>{user?.email}</h5>
                        </li>
                        <li>
                          <p>Phone No:</p>
                          <h5>{user?.mobileNo}</h5>
                        </li>
                        <li>
                          <p>Gender:</p>
                          <h5>{user?.gender}</h5>
                        </li>
                        <li>
                          <p>Date of Birth::</p>
                          <h5>{user?.dateOfBirth}</h5>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="section-title section-title-4 mt-4 left pb-2">
                <h1>Appointment Slots</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} className="mx-auto">
              <div className="profile-info-box">
                <Row>
                  <Col>
                    <div className="appointment-calendar">
                      <FullCalendar
                        ref={fullCalRef}
                        plugins={[timeGridPlugin, interactionPlugin]}
                        initialView="timeGridWeek"
                        slotDuration="00:30:00"
                        selectable={true}
                        select={handleDateSelect}
                        eventClick={handleEventClick}
                        events={{
                          url: withBackendUrl(endpoint.user.SLOT_EVENTS),
                          extraParams: function () {
                            return {
                              token: getLoginToken()
                            };
                          }
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

        </div>
      </div>

    </>
  );
};

export default DoctorProfile;
