import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import FormFieldSelect from '../../Components/FormFields/FormFieldSelect';
import ButtonElement from '../../Components/Common/ButtonElement';
import SearchBar from '../../Components/Common/SearchBar';
import DoctorBox2 from '../../Components/Site/Doctors/DoctorBox2';
import DoctorFormModal from '../../Components/Modal/DoctorFormModal';
import { useEffect, useState } from 'react';
import { deleteDoctor, getDoctors, markDoctorApproved, markDoctorRejected } from '../../Services/admin-service';
import { haveValue, initialPaginationData, printDate, printTime, withBackendUrl } from '../../Utils/helpers';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../Components/Common/Pagination';
import NoTabRow from '../../Components/Common/NoTabRow';
import { FaCross } from 'react-icons/fa';
import { MdCancel, MdDelete } from 'react-icons/md';

const AdminDoctors = () => {

  const dispatch = useDispatch();

  const [modelData, setModelData]: any = useState(null);
  const [searchTerm, setSearchTerm]: any = useState("");
  const [sortType, setSortType]: any = useState("newest");

  const [showModal, setShowModel]: any = useState(false);
  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);

  const addDoctorOpen = () => {
    setShowModel(true);
  }

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getDoctors({
      page,
      search: searchTerm,
      sort: sortType
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, sortType, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  const deleteIt = (data) => {
    deleteDoctor({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Deleted successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to delete"));
    });
  }

  const markApproved = (data) => {
    markDoctorApproved({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Approved successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to approve"));
    });
  }

  const markRejected = (data) => {
    markDoctorRejected({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Rejected successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to approve"));
    });
  }

  //markApproved

  const closeModal = () => {
    setShowModel(false);
    loadData(page);
    setModelData(null);
  }

  const handleEdit = (data) => {
    setModelData(data);
    setShowModel(true);
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Doctors</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="table-head-options">
                <div className='left'>
                  <FormFieldSelect
                    id="type"
                    name="type"
                    label={false}
                    onChange={(e) => setSortType(e?.target?.value)}
                    options={[
                      { _id: "newest", name: "Newest" },
                      { _id: "oldest", name: "Oldest" },
                    ]}
                    value={sortType}
                    placeholder={"Select Type"}
                  />
                </div>
                <div className='right'>
                  <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  <ButtonElement onClick={addDoctorOpen} className="ms-2" variant="primary" >
                    Add Doctor
                  </ButtonElement>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Experience</th>
                      <th>Qualification</th>
                      <th>Specially</th>
                      {/* <th>Symptoms</th> */}
                      <th>Email</th>
                      <th>Documents</th>
                      <th>Approval</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paginationData?.docs && paginationData?.docs.length > 0 ? <>
                        {paginationData?.docs?.map((data: any, index) => (
                          <tr key={index}>
                            <td className="table-doctor-box">
                              <DoctorBox2 link={`/doctors/${data?._id}`} doctor={data} />
                            </td>
                            <td>{data?.experience}</td>
                            <td>{data?.highestQualification}</td>
                            <td>{data?.speciality}</td>
                            {/* <td>{data?.symptoms}</td> */}
                            <td>{data?.email}</td>
                            <td>
                              <ul className='list-links'>
                                {
                                  data?.documents.map((document, i) => {
                                    return <>
                                      <li>
                                        <a className='' target="_blank" href={withBackendUrl(document)}>Document {i + 1}</a>
                                      </li>
                                    </>
                                  })
                                }
                              </ul>
                            </td>
                            <td>
                              <div className='table-actions'>
                                {
                                  haveValue(data?.doctorApprovedAt) ? <>
                                    <p>Approved At: <br /> {printTime(data?.doctorApprovedAt)}</p>
                                  </> : <>
                                    <p>
                                      <a href="javascript:;" onClick={(e) => markApproved(data)}><BiCheck /> Mark Approved</a>
                                      <a href="javascript:;" onClick={(e) => markRejected(data)}><AiOutlineDelete /> Mark Rejected</a>
                                    </p>
                                  </>
                                }
                              </div>
                            </td>
                            <td>
                              <div className='table-actions'>
                                {/* <a href={""}>View Details</a> */}
                                <a onClick={(e) => handleEdit(data)} href={undefined}><BsPencil /></a>
                                <a onClick={(e) => deleteIt(data)} href={undefined} className="danger"><AiOutlineDelete /></a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </> : <>
                        <NoTabRow colSpan={5} />
                      </>
                    }

                  </tbody>
                </table>
              </div>
            </Col>

            <Col md={12}>
              {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                  <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                </> : null
              }
            </Col>

          </Row>

        </div>
      </div>

      <DoctorFormModal modelData={modelData} onModalHide={(e) => closeModal()} showModal={showModal} />

    </>
  )
}

export default AdminDoctors;