import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Video from 'twilio-video';
import ContentLoader from '../../Components/Loader/ContentLoader';
import { showErrorToast } from '../../Redux/slices/toastSlice';
import Participant from './Participant';

const Room = (props) => {
    const { roomName, token } = props;

    const dispatch = useDispatch();

    const [room, setRoom]: any = useState(null);
    const [participants, setParticipants]: any = useState([]);

    useEffect(() => {
        const participantConnected = (participant: any) => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        }).catch((err)=>{
            dispatch(showErrorToast(err?.message));
        });

        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);

    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant} />
    ));

    return (
        <div className="room">
            <div className="local-participant participant-box">
                {room ? (
                    <Participant
                        key={room.localParticipant.sid}
                        participant={room.localParticipant}
                    />
                ) : (
                    ''
                )}
            </div>
            {
                remoteParticipants.length > 0 ? <>
                    <div className="remote-participants participant-box">{remoteParticipants}</div>
                </> : <>
                    <div className="no-participants"><span className='me-2'>Waiting for Other to Join</span> <ContentLoader/></div>
                </>
            }
        </div>
    );
};

export default Room;