import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const addBlog = (payload: any = {}) => {
    return sendRequest(endpoint.blog, "POST", payload);
};

export const getBlog = (payload: any = {}) => {
    return sendRequest(endpoint.blog, "GET", payload);
};

export const deleteBlog = (id: any) => {
    return sendRequest(`${endpoint.blog}/${id}`, "DELETE");
};

export const updateBlog = (id: any, payload: any = {}) => {
    return sendRequest(`${endpoint.blog}/${id}`, "POST", payload);
};


