import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import { handleFormikChange, handleFormikSubmit, haveValue, printFullName, printTime } from "../../../Utils/helpers";
import FormField from "../../FormFields/FormField";
import { useDispatch } from "react-redux";
import ButtonElement from "../ButtonElement";
import { useFormik } from "formik";
import * as yup from "yup";
import FormFieldArea from "../../FormFields/FormFieldArea";
import RatingElement from "../Rating/RatingElement";

function ViewAppointmentFeedback(props) {

  const { appointment } = props;

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        className=""
        size="lg"
      >
        <Modal.Header closeButton>Appointment Feedback</Modal.Header>
        <Modal.Body>

          <h6>Feedback by Patient</h6>
          {
            haveValue(appointment?.ratingByPatient?.rating) ? <>
              <div className="rating-box">
                <RatingElement value={appointment?.ratingByPatient?.ratingStars} disabled={true} />
              </div>
              <br />
              <p>{appointment?.ratingByPatient?.feedback}</p>
              <br />
              <p>Added On: <strong>{printTime(appointment?.ratingByPatient?.time)}</strong></p>
              <br />
            </> : <>
              <p>No Rating Given Yet</p>
            </>  
          }

          <hr />

          <h6>Feedback by Doctor</h6>
          {
            haveValue(appointment?.ratingByDoctor?.rating) ? <>
              <div className="rating-box">
                <RatingElement value={appointment?.ratingByDoctor?.ratingStars} disabled={true} />
              </div>
              <br />
              <p>{appointment?.ratingByDoctor?.feedback}</p>
              <br />
              <p>Added On: <strong>{printTime(appointment?.ratingByDoctor?.time)}</strong></p>
              <br />
            </> : <>
              <p>No Rating Given Yet</p>
            </>
          }

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewAppointmentFeedback;
