import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { haveValue } from "../../Utils/helpers";
import ButtonLinkElement from "../Common/ButtonLinkElement";

const PageTitleBar = (props) => {

  return (
    <>
      <section id="page-title-bar" className="page-title-bar">
        <Container>
          <Row>
            <Col md={12} lg={12}>
              <div className="content">
                {
                  haveValue(props?.preTitle) ? <>
                    <h6>{props?.preTitle}</h6>
                  </> : null
                }
                <h1>{props?.title}</h1>
                {
                  haveValue(props?.subTitle) ? <>
                    <h2>{props?.subTitle}</h2>
                  </> : null
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PageTitleBar;
