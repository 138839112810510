import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { getTestimonials } from "../../../Services/public-service";
import { slickOptions } from "../../../Utils/constants";
import TestimonialBox from "./TestimonialBox";

const TestimonialsCarousel = (props) => {

  const settings = {
    ...slickOptions
  };

  const [testimonials, setTestimonials] = useState([]);

  const loadData = () => {
    getTestimonials().then((res: any) => {
      setTestimonials(res?.data?.docs);
    }).catch((err) => {

    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <section id="doctors" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title left">
                <p>Client</p>
                <h1>Testimonials</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="mx-auto">
              <Slider {...settings}>
                {
                  testimonials.map((testimonial) => {
                    return <>
                      <TestimonialBox testimonial={testimonial} />
                    </>
                  })
                }
              </Slider>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  );
};

export default TestimonialsCarousel;
