import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";

import {
  checkAccount,
  getGoogleLoginLink,
  login,
  loginWithEmail,
  loginWithGoogleCode,
} from "../../Services/auth-service";
import {
  clearGoogleRecapcthaElem,
  handleFormikChange,
  handleFormikSubmit,
  haveValue,
  isValidEmail,
  isValidMobileNumber,
} from "../../Utils/helpers";
import useTranslator from "./../../Hooks/useTranslator";
import { Button } from "react-bootstrap";

import FormField from "../FormFields/FormField";
import PasswordField from "../FormFields/PasswordField";
import CheckboxField from "../FormFields/CheckboxField";
import { useDispatch } from "react-redux";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast,
  showSuccessToast,
} from "../../Redux/slices/toastSlice";
import { setLogin } from "../../Redux/slices/authSlice";
import ButtonElement from "../Common/ButtonElement";
import FormFieldRadios from "../FormFields/FormFieldRadios";
import { UserConstants } from "../../Constants/user";

import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import TelephoneField from "../FormFields/TelephoneField";
import { MdEmail } from "react-icons/md";

const LoginForm = (props: any) => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const { showingLoading } = useSelector((state: any) => state.toast);

  const [searchParams, setSearchParams] = useSearchParams();
  const [googleAuthCode, setGoogleAuthCode] = useState("");
  const [confirmationRes, setConfirmationRes]: any = useState(null);

  const { configurations } = useSelector((state: any) => state);

  const [loginVia, setLoginVia] = useState("mobile");
  const [loginStep, setLoginStep] = useState(1);

  const [loginHash, setLoginHash] = useState(null);

  useEffect(() => {
    const code: any = searchParams.get("code");
    if (haveValue(code)) {
      setGoogleAuthCode(code);
    }
  }, [searchParams]);

  useEffect(() => {
    if (haveValue(googleAuthCode)) {
      continueWithGoogleCallback(googleAuthCode);
      setSearchParams({});
      setGoogleAuthCode("");
    }
  }, [googleAuthCode]);

  const continueWithGoogleCallback = (code: string) => {
    dispatch(showLoadingToast(_t("checkingLoginDetails")));
    loginWithGoogleCode({ code })
      .then((res: any) => {
        dispatch(
          setLogin({
            token: res.data.token,
            user: res.data.user,
          })
        );
      })
      .catch((err: any) => {
        dispatch(showErrorToast(err?.message));
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      });
  };

  const continueWithGoogle = () => {
    dispatch(showLoadingToast(null));
    getGoogleLoginLink()
      .then((res: any) => {
        if (haveValue(res.data)) {
          window.location.replace(res.data);
        } else {
          dispatch(showErrorToast(_t("requestFailed")));
        }
      })
      .catch((err: any) => {
        dispatch(showErrorToast(err?.message));
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      });
  };

  const validationSchema = yup.object({
    username: yup
      .string()
      .required(loginVia === "mobile" ? "Phone No. is Required" : "Email is Required"),
    // password: yup
    //   .string()
    //   .required("Password is Required"),
  });

  const loginForm = useFormik({
    initialValues: {
      username: "",
      // password: "",
      terms: "",
      type: "",
      otp: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!haveValue(values.type)) {
        dispatch(showErrorToast("Select Account Type"));
      } else if (!values.terms) {
        dispatch(showErrorToast("Please accept terms to Continue"));
      } else {
        let username: any = values.username;
        //check if account exist
        dispatch(showLoadingToast(_t("Please wait...")));

        checkAccount({
          type: UserConstants.ROLES.patient,
          username
        }).then(async (res) => {


          if (loginVia === "email") {
            loginWithEmail({
              email: username
            })
              .then((res: any) => {
                setLoginHash(res?.data?.token);
                dispatch(showSuccessToast("OTP for login has been sent to your email."));
                dispatch(hideLoadingToast(null));
                setLoginStep(2);
              })
              .catch((err: any) => {
                dispatch(showErrorToast(err?.message));
              })
              .finally(() => {
                dispatch(hideLoadingToast(null));
              });
          } else if (loginVia === "mobile") {
            clearGoogleRecapcthaElem();
            const app = initializeApp(configurations?.firebase);
            const auth = getAuth(app);
            let recaptchaVerifier = await new RecaptchaVerifier(auth, 'recaptcha-container', {
              'size': 'invisible',
              'expired-callback': () => {
                dispatch(showErrorToast("Failed, please retry"));
                dispatch(hideLoadingToast(null));
              }
            });
            signInWithPhoneNumber(auth, username, recaptchaVerifier)
              .then((confirmationResult) => {
                setConfirmationRes(confirmationResult);
                dispatch(showSuccessToast("OTP for login has been sent to your mobile no."));
                dispatch(hideLoadingToast(null));
                setLoginStep(2);
              })
              .catch((error) => {
                dispatch(showErrorToast(error.message));
                dispatch(hideLoadingToast(null));
              });
          }


        }).catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
          .finally(() => {
            dispatch(hideLoadingToast(null));
          });


      }
    },
  });

  const doLogin = (hashCode) => {
    login({
      ...loginForm.values,
      hash: hashCode,
      loginMethod: loginVia
    })
      .then((res: any) => {
        if (!haveValue(res?.data?.user?.emailVerifiedAt)) {
          return dispatch(showErrorToast("Email is not Verified"));
        }
        dispatch(
          setLogin({
            token: res.data.token,
            user: res.data.user,
          })
        );
      })
      .catch((err: any) => {
        dispatch(showErrorToast(err?.message));
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      });
  }

  const submitOtp = () => {
    if (haveValue(loginForm.values?.otp)) {
      if (loginVia === "mobile") {
        if (confirmationRes !== null) {
          dispatch(showLoadingToast(_t("Validating OTP")));
          confirmationRes.confirm(loginForm.values?.otp).then((result) => {
            doLogin(result._tokenResponse.idToken);
          }).catch((error) => {
            dispatch(showErrorToast(error.message));
            dispatch(hideLoadingToast(null));
          });
        }
      } else if (loginVia === "email") {
        doLogin(loginHash);
      }
    } else {
      dispatch(showErrorToast("OTP is required"));
    }
  }

  const changeLoginVia = (loginViaType) => {
    setLoginVia(loginViaType);
    setLoginStep(1);
    loginForm.resetForm();
  }

  return (
    <>
      <Form onSubmit={(e) => handleFormikSubmit(e, loginForm)}>

        {
          loginStep == 1 ? <>
            <FormFieldRadios
              id="type"
              name="type"
              label={null}
              value={loginForm.values.type}
              onChange={(e) => handleFormikChange(e, loginForm)}
              showError={loginForm.touched.type}
              errorText={loginForm.errors.type}
              options={[
                { value: UserConstants.ROLES.patient, label: "Individual" },
                { value: UserConstants.ROLES.corporate, label: "Corporate" }
              ]}
            />

            {
              loginVia === "mobile" ? <>
                <TelephoneField
                  id="username"
                  name="username"
                  label={"Mobile No."}
                  value={loginForm.values.username}
                  onChange={(username) =>
                    handleFormikChange(username, loginForm, "username")
                  }
                  showError={loginForm.touched.username}
                  errorText={loginForm.errors.username}
                  placeholder={"Enter Mobile No."}
                />
              </> : null
            }

            {
              loginVia === "email" ? <>
                <FormField
                  id="username"
                  name="username"
                  label={"Email"}
                  value={loginForm.values.username}
                  onChange={(e) => handleFormikChange(e, loginForm)}
                  showError={loginForm.touched.username}
                  errorText={loginForm.errors.username}
                  placeholder={"Enter Email"}
                />
              </> : null
            }

            {/* <PasswordField
          id="password"
          name="password"
          label={"Password"}
          value={loginForm.values.password}
          onChange={(e) => handleFormikChange(e, loginForm)}
          showError={loginForm.touched.password}
          errorText={loginForm.errors.password}
          placeholder={"Enter Password"}
        /> */}

            <div className="d-flex align-items-center justify-content-between">
              <CheckboxField
                id="terms"
                name="terms"
                label={"I accept the terms and conditions"}
                checked={loginForm.values.terms}
                onChange={(e) => handleFormikChange(e, loginForm)}
                errorText={loginForm.errors.terms}
              />
            </div>

            <div className="theme-formbtn">
              <ButtonElement variant="primary" type="submit" disabled={showingLoading}>
                Login
              </ButtonElement>
            </div>
          </> : null
        }

        {
          loginStep == 2 ? <>
            <FormField
              id="otp"
              name="otp"
              label={"OTP"}
              value={loginForm.values.otp}
              onChange={(e) => handleFormikChange(e, loginForm)}
              showError={loginForm.touched.otp}
              errorText={loginForm.errors.otp}
              placeholder={"Enter OTP"}
            />

            <div className="theme-formbtn">
              <ButtonElement variant="primary" type="button" onClick={submitOtp} disabled={showingLoading}>
                Login
              </ButtonElement>
            </div>
          </> : null
        }

        <div className="formbtngroups">
          <h6>
            Don't have an Account?
            <Link className="linkbtn" to="/signup">
              Signup
            </Link>
          </h6>
        </div>

        <div className="orbar">
          <span className="line"></span>
          <p>{_t("or")}</p>
          <span className="line"></span>
        </div>

        <div className="googlebtn">
          <button
            type="button"
            onClick={continueWithGoogle}
            className="googlebtn btn-green"
          >
            <img src="/images/google.svg" alt={"Google Login"} />
            Login With Google
          </button>
        </div>

        {
          loginVia === "mobile" ? <>
            <div className="googlebtn">
              <button
                type="button"
                onClick={(e) => changeLoginVia("email")}
                className="mt-3 btn-green"
              >
                Login With Email
              </button>
            </div>
          </> : null
        }

        {
          loginVia === "email" ? <>
            <div className="googlebtn">
              <button
                type="button"
                onClick={(e) => changeLoginVia("mobile")}
                className="mt-3 btn-green"
              >
                Login With Mobile No.
              </button>
            </div>
          </> : null
        }



      </Form>
    </>
  );
};

export default LoginForm;
