import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useTranslator from "../../Hooks/useTranslator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import FormField from "../FormFields/FormField";
import { handleFormikChange, handleFormikSubmit } from "../../Utils/helpers";
import { Col, Row } from "react-bootstrap";
import FormFieldArea from "../FormFields/FormFieldArea";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { submitContact } from "../../Services/public-service";

const ContactForm = (props: any) => {
  // Locaization
  const { _t } = useTranslator();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showingLoading } = useSelector((state: any) => state.toast);

  // Prop Destructuring
  const { selectedRole = [] } = props;

  // Mounting
  useEffect(() => { }, []);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is Required"),
    fullName: yup.string().required("Full Name is Required"),
    message: yup.string().required("Message is Required")
  });

  const contactForm = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      message: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(showLoadingToast(_t("Please wait...")));
      submitContact(values)
        .then((res: any) => {
          dispatch(showSuccessToast(res?.message));
          navigate("/");
        })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    },
  });

  return (
    <>
      <Form onSubmit={(e) => handleFormikSubmit(e, contactForm)}>

        <Row>
          <Col>
            <FormField
              id="fullName"
              name="fullName"
              label={"Full Name"}
              value={contactForm.values.fullName}
              onChange={(e) => handleFormikChange(e, contactForm)}
              showError={contactForm.touched.fullName}
              errorText={contactForm.errors.fullName}
              placeholder={"Enter Full Name"}
            />
          </Col>
        </Row>

        <FormField
          id="email"
          name="email"
          fieldType="email"
          label={"Email"}
          value={contactForm.values.email}
          onChange={(e) => handleFormikChange(e, contactForm)}
          showError={contactForm.touched.email}
          errorText={contactForm.errors.email}
          placeholder={"Enter Email Address"}
        />

        <FormFieldArea
          id="message"
          name="message"
          label={"Message"}
          value={contactForm.values.message}
          onChange={(e) => handleFormikChange(e, contactForm)}
          showError={contactForm.touched.message}
          errorText={contactForm.errors.message}
          placeholder={"Enter Message"}
        />

        <div className="theme-formbtn">
          <Button
            className="btn-full"
            variant="primary"
            type="submit"
            disabled={showingLoading}
          >
            Send Message
          </Button>
        </div>

      </Form>
    </>
  );
};

export default ContactForm;
