import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppointmentPrescription from "../../Components/Appointment/AppointmentPrescription";
import ButtonElement from "../../Components/Common/ButtonElement";
import ContentLoader from "../../Components/Loader/ContentLoader";
import { AppointmentConstants } from "../../Constants/appointment";
import { UserConstants } from "../../Constants/user";
import { useAppContext } from "../../Hooks/useAppContext";
import { showErrorToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { getAppointmentDetail, getAppointmentRoomDetail } from "../../Services/appointment-service";
import { haveValue, printFullName, printOnlyTime } from "../../Utils/helpers";
import Room from "../Twilio/Room";

const AppointmentCall = (props) => {

  const params = useParams();
  const { appointmentId } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentRole }: any = useAppContext();

  const [loading, setLoading] = useState(true);
  const [appointmentDetails, setAppointmentDetails]: any = useState({});
  let [roomDetails, setRoomDetails]: any = useState({});

  useEffect(() => {
    if (haveValue(appointmentId)) {
      loadAppointmentDetails();
    }
  }, [appointmentId]);

  useEffect(() => {
    const interval = setInterval(() => {
      loadAppointmentDetails(false);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const loadAppointmentDetails = (updateStates = true) => {
    if (updateStates) {
      setLoading(true);
    }
    getAppointmentDetail({
      appointmentId: params?.appointmentId
    }).then((res: any) => {
      let appointment = res?.data;
      if (updateStates) {
        setAppointmentDetails(appointment);
        setLoading(false);
      }
      switch (appointment.status) {
        case AppointmentConstants.STATUS.cancelled:
          dispatch(showErrorToast("Appointment is cancelled"));
          goBack();
          break;
        case AppointmentConstants.STATUS.completed:
          dispatch(showSuccessToast("Appointment is completed"));
          goBack();
          break;
        case AppointmentConstants.STATUS.scheduled:
          dispatch(showErrorToast("Please join again on appointment time only"));
          goBack();
          break;
        case AppointmentConstants.STATUS.ongoing:
          if (!haveValue(roomDetails?.token)) {
            setAppointmentDetails(appointment);
            loadRoomDetails();
          }
          break;
      }
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
      setLoading(false);
    });
  }

  const loadRoomDetails = () => {
    setLoading(true);
    getAppointmentRoomDetail({
      appointmentId: params?.appointmentId
    }).then((res: any) => {
      roomDetails = res?.data;
      setRoomDetails(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      setLoading(false);
    });
  }

  const goBack = () => {
    if (currentRole === UserConstants.ROLES.doctor) {
      navigate('/doctor/index');
    } else if (currentRole === UserConstants.ROLES.patient) {
      navigate('/appointments');
    }
  }

  return (
    <>

      <main className="video-call-area">
        {
          (loading) ? <>
            <div className="loading-view">
              <ContentLoader />
              <p className="mt-3">Loading Appointment Details</p>
            </div>
          </> : <>

            {
              haveValue(appointmentDetails?._id) ? <>
                <div className="video-screen">
                  {
                    haveValue(roomDetails?.token) ? <>
                      <Room token={roomDetails?.token} identity={roomDetails?.identity} roomName={roomDetails?.roomName} />
                      {
                        currentRole === UserConstants.ROLES.doctor ? <>
                          <AppointmentPrescription appointment={appointmentDetails} />
                        </> : null
                      }
                    </> : null
                  }
                </div>
                <div className="video-toll-bar">
                  <div className="info">
                    <h5>Appointment ID: {appointmentDetails?._id}</h5>
                    <p>Patient: <strong>{printFullName(appointmentDetails?.user)}</strong> | Doctor: <strong>{printFullName(appointmentDetails?.doctor)}</strong> | Time: <strong>{printOnlyTime(appointmentDetails?.start)} - {printOnlyTime(appointmentDetails?.end)}</strong></p>
                  </div>
                  <div className="actions">
                    <ButtonElement variant="danger" onClick={goBack} text="End Call" />
                  </div>
                </div>
              </> : <>
                <div className="loading-view">
                  <ButtonElement onClick={goBack} text="Go Back" />
                  <p className="mt-3">Failed to Complete your Request</p>
                </div>
              </>
            }

          </>
        }

      </main>

    </>
  );
};

export default AppointmentCall;
