import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import SiteLayout from "./Components/Layout/SiteLayout";
import AuthLayout from "./Components/Layout/AuthLayout";

import AuthBlankLayout from "./Components/Layout/AuthBlankLayout";

import Home from "./Pages/Site/Home";

import Signup from "./Pages/Auth/Signup";
import Login from "./Pages/Auth/Login";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import ResetPassword from "./Pages/Auth/ResetPassword";

import EmailActivation from "./Pages/Auth/EmailActivation";
import { switchToRole } from "./Redux/slices/authSlice";
import { UserConstants } from "./Constants/user";
import AdminLayout from "./Components/Layout/AdminLayout";
import AdminDashboard from "./Pages/Admin/Dashboard";
import { haveValue } from "./Utils/helpers";
import AboutUs from "./Pages/Site/AboutUs";
import Pharmacy from "./Pages/Site/Pharmacy";
import Blogs from "./Pages/Site/Blogs";
import FindDoctors from "./Pages/Site/FindDoctors";
import Appointments from "./Pages/Site/Appointments";
import Doctor from "./Pages/Site/Doctor";
import PrivacyPolicy from "./Pages/Site/PrivacyPolicy";
import Terms from "./Pages/Site/Terms";
import ContactUs from "./Pages/Site/ContactUs";
import Profile from "./Pages/Site/Profile";
import EditProfile from "./Pages/Site/EditProfile";
import AdminLogin from "./Pages/Admin/Login";
import BlankLayout from "./Components/Layout/AuthBlankLayout";
import HomeSettings from "./Pages/Admin/HomeSettings";
import AdminBlogs from "./Pages/Admin/AdminBlogs";
import AdminCorporates from "./Pages/Admin/AdminCorporates";
import AdminDoctors from "./Pages/Admin/AdminDoctors";
import AdminPatients from "./Pages/Admin/AdminPatients";
import AdminAppointments from "./Pages/Admin/AdminAppointments";
import AuthUserLayout from "./Components/Layout/AuthUserLayout";
import DoctorLogin from "./Pages/Doctor/Login";
import DoctorRegister from "./Pages/Doctor/Register";
import DoctorLayout from "./Components/Layout/DoctorLayout";
import DoctorDashboard from "./Pages/Doctor/Dashboard";
import DoctorPatients from "./Pages/Doctor/DoctorPatients";
import DoctorAppointments from "./Pages/Doctor/DoctorAppointments";
import DoctorPreviousAppointments from "./Pages/Doctor/DoctorPreviousAppointments";
import DoctorProfile from "./Pages/Doctor/DoctorProfile";
import ChooseRole from "./Pages/Auth/ChooseRole";
import AppointmentCall from "./Pages/Extra/AppointmentCall";
import CorporateDetails from "./Pages/Admin/CorporateDetails";
import AdminPrevAppointments from "./Pages/Admin/AdminPrevAppointments";
import CorporateReports from "./Pages/Admin/CorporateReports";
import Blog from "./Pages/Site/Blog";


function AppRouter() {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state: any) => state.auth);

  const [currentRole, setCurrentRole] = useState(null);

  useEffect(() => {
    let navToggler = document.querySelector("button.navbar-toggler");
    if (navToggler != null) {
      if (!navToggler.classList.contains("collapsed")) {
        // @ts-ignore
        navToggler.click();
      }
    }
  }, [history]);

  useEffect(() => {
    if (isAuthenticated && haveValue(user?.role)) {
      if (haveValue(user?.preference?.currentRole)) {
        setCurrentRole(user?.preference?.currentRole);
      } else {
        if (user.role.length > 0) {
          dispatch(switchToRole(user.role[0]));
        } else {
          setCurrentRole(null);
        }
      }
    } else {
      setCurrentRole(null);
    }
  }, [isAuthenticated, user]);

  return (
    <>
      <Routes>

        <Route element={<SiteLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/pharmacy" element={<Pharmacy />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<Blog />} />
          <Route path="/find-doctors" element={<FindDoctors />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/doctors/:slug" element={<Doctor />} />
        </Route>

        {isAuthenticated ? (
          <>
            {user?.role?.length === 0 ? (
              <>

                <Route element={<AuthLayout />}>
                  <Route path="/confirm-role" element={<ChooseRole />} />
                </Route>

                <Route
                  path="*"
                  element={<Navigate to="/confirm-role" replace={true} />}
                />

              </>
            ) : (
              <>

                <Route path="/join-appointment/:appointmentId" element={<AppointmentCall />} />

                {currentRole === UserConstants.ROLES.patient ? (
                  <>

                    {
                      haveValue(user.mobileNo) && haveValue(user.firstName) ? <>
                      </> : <>

                      </>
                    }

                    <Route element={<SiteLayout />}>
                      <Route path="/appointments" element={<Appointments />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/edit-profile" element={<EditProfile />} />
                    </Route>

                    <Route
                      path="*"
                      element={
                        <Navigate to="/appointments" replace={true} />
                      }
                    />
                  </>
                ) : null}

                {currentRole === UserConstants.ROLES.doctor ? (
                  <>

                    <Route element={<DoctorLayout />}>
                      <Route path="/doctor/index" element={<DoctorDashboard />} />
                      <Route path="/doctor/appointments" element={<DoctorAppointments />} />
                      <Route path="/doctor/previous-appointments" element={<DoctorPreviousAppointments />} />
                      <Route path="/doctor/patients" element={<DoctorPatients />} />
                      <Route path="/doctor/profile" element={<DoctorProfile />} />
                    </Route>

                    <Route
                      path="*"
                      element={
                        <Navigate to="/doctor/index" replace={true} />
                      }
                    />
                  </>
                ) : null}

                {currentRole === UserConstants.ROLES.admin ? (
                  <>

                    <Route element={<AdminLayout />}>
                      <Route path="/admin/dashboard" element={<AdminDashboard />} />
                      <Route path="/admin/home" element={<HomeSettings />} />
                      <Route path="/admin/blogs" element={<AdminBlogs />} />
                      <Route path="/admin/appointments" element={<AdminAppointments />} />
                      <Route path="/admin/previous-appointments" element={<AdminPrevAppointments />} />
                      <Route path="/admin/patients" element={<AdminPatients />} />
                      <Route path="/admin/doctors" element={<AdminDoctors />} />
                      <Route path="/admin/corporates" element={<AdminCorporates />} />
                      <Route path="/admin/corporate/:corporateId" element={<CorporateDetails />} />
                      <Route path="/admin/report/:corporateId" element={<CorporateReports />} />
                    </Route>

                    <Route
                      path="*"
                      element={
                        <Navigate to="/admin/dashboard" replace={true} />
                      }
                    />
                  </>
                ) : null}

              </>
            )}

          </>
        ) : (
          <>

            <Route element={<AuthLayout />}>
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/request/forgot-password/:hash"
                element={<ResetPassword />}
              />
              <Route
                path="/request/email-activation/:hash"
                element={<EmailActivation />}
              />
            </Route>

            <Route element={<AuthBlankLayout />}>
              <Route path="/admin/login" element={<AdminLogin />} />
            </Route>

            <Route element={<AuthUserLayout />}>
              {/* <Route path="/doctor/login" element={<DoctorLogin />} />
              <Route path="/doctor/signup" element={<DoctorRegister />} /> */}
              <Route path="/doctor/login" element={<Navigate to="/login" replace={true} />} />
              <Route path="/doctor/signup" element={<Navigate to="/signup" replace={true} />} />
            </Route>

            <Route
              path="*"
              element={
                <Navigate to="/home" replace={true} />
              }
            />

          </>
        )}

        <Route path="/" element={<Navigate to="/home" replace={true} />} />
      </Routes>
    </>
  );
}

export default AppRouter;
