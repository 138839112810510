import React, { useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import { createSearchParams, Link, NavLink, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { useSelector } from "react-redux";
import SearchBar from "../../Common/SearchBar";
import ButtonLinkElement from "../../Common/ButtonLinkElement";
import { AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import UserDropdown from "./UserDropdown";
import { useAppContext } from "../../../Hooks/useAppContext";
import { UserConstants } from "../../../Constants/user";

const HeaderNav = (props) => {

  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state: any) => state.auth);
  const { currentRole }: any = useAppContext();

  const [searchTerm, setSearchTerm] = useState("");

  const handlelogo = () => {
    navigate("/home");
  };

  const redirectToLogin = () => {
    navigate("/login");
  }

  const handleEnter = () => {
    console.log("enter");
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setSearchTerm("");
      navigate({
        pathname: "/find-doctors",
        search: createSearchParams({
          q: searchTerm
        }).toString()
      });
    }
  }

  const handleSearchTerm = (term:string) => {
    setSearchTerm(term)
    navigate({
      pathname: "/find-doctors",
      search: createSearchParams({
        q: term
      }).toString()
    });
  }

  return (
    <>

      <div className="MainHeader">
        <Navbar expand="sm">
          <Container fluid className="head_nav">
            <Navbar.Brand onClick={handlelogo}>
              <img src="/images/logo.png" alt="logo" />
            </Navbar.Brand>
            <div className="nav-toggler">
              <UserDropdown />
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-menu-items">
                <Nav.Item>
                  <NavLink to="/home" className="clickeffect" >
                    Home
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/about-us">
                    About Us
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/pharmacy">
                    Pharmacy
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/blogs" >
                    Blogs
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/find-doctors" >
                    Find Doctors
                  </NavLink>
                </Nav.Item>
                {
                  isAuthenticated ? <>
                    {
                      currentRole === UserConstants.ROLES.patient ? <>
                        <Nav.Item>
                          <NavLink to="/appointments" >
                            Appointments
                          </NavLink>
                        </Nav.Item>
                      </> : null
                    }
                  </> : null
                }
              </Nav>
            </Navbar.Collapse>
            <div className="right-nav-things">
              <SearchBar onKeyDown={handleKeyDown} searchTerm={searchTerm} setSearchTerm={handleSearchTerm} placeholder="Search doctors..." />
              {
                isAuthenticated ? <>
                  <UserDropdown />
                </> : <>
                  <ButtonLinkElement to={"/login"} text="Login / Sign Up" variant="primary" />
                </>
              }
            </div>
          </Container>
        </Navbar>
      </div>

    </>
  );
};

export default HeaderNav;
