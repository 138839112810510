import { AiOutlineDelete } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../Redux/slices/toastSlice";
import { deleteTestimonial } from "../../../Services/testimonial-service";
import RatingElement from "../../Common/Rating/RatingElement";

const TestimonialBox = (props) => {

    const { actions, testimonial } = props;
    const { rating, body, author, authorAddress } = props?.testimonial;

    const dispatch = useDispatch();

    const deleteItem = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            deleteTestimonial(testimonial?._id).then((res) => {
                dispatch(showSuccessToast("Testimonial has been deleted"));
                props?.onRefresh();
            }).catch((err) => {
                dispatch(showErrorToast("Failed to delete Testimonial"));
            });
        }
    }

    return (
        <>
            <div className="testimonial-box">
                {
                    actions ? <>
                        <div className='item-actions'>
                            <a href={undefined} onClick={(e) => props?.handleEdit(testimonial)}><BsPencil /></a>
                            <a href={undefined} onClick={deleteItem} className="danger"><AiOutlineDelete /></a>
                        </div>
                    </> : null
                }
                <div className="rating-box">
                    <RatingElement value={rating} disabled={true} />
                </div>
                <div className="review">
                    {body}
                </div>
                <div className="user-info">
                    <div className="img" style={{ backgroundImage: 'url(/images/doctor1.png)' }}></div>
                    <div>
                        <h4>{author}</h4>
                        <p>{authorAddress}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TestimonialBox;
