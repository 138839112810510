import { endpoint } from "../Config/endpoints";
import { objectToFormData } from "../Utils/helpers";
import { sendRequest } from "./http-service";


export const upload = (payload: any) => {
  const contentType = "multipart/form-data";
  return sendRequest(
    endpoint.media.UPLOAD,
    "POST",
    objectToFormData(payload),
    null,
    contentType
  );
}

export const uploadMultiple = (payload: any) => {
  const contentType = "multipart/form-data";
  return sendRequest(
    endpoint.media.UPLOAD_MULTIPLE,
    "POST",
    objectToFormData(payload),
    null,
    contentType
  );
}

export const uploadCerificate = (payload: any) => {
  const contentType = "multipart/form-data";
  return sendRequest(
    endpoint.media.UPLOAD_CERTIFICATE,
    "POST",
    objectToFormData(payload),
    null,
    contentType
  );
}

export const uploadBannerImage = (payload: any) => {
  const contentType = "multipart/form-data";
  return sendRequest(
    endpoint.media.UPLOAD_BANNER,
    "POST",
    payload,
    null,
    contentType
  );
}

export const uploadBlogImage = (payload: any) => {
  const contentType = "multipart/form-data";
  return sendRequest(
    endpoint.media.UPLOAD_IMAGE,
    "POST",
    payload,
    null,
    contentType
  );
}

export const uploadVideo = (payload: any) => {
  const contentType = "multipart/form-data";
  return sendRequest(
    endpoint.media.UPLOAD_IMAGE,
    "POST",
    payload,
    null,
    contentType
  );
}
