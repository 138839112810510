import { Col, Container, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

const AuthUserLayout = () => {


    return (
        <>
            <div className="auth-layout auth-user-layout">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={12} lg={12} className="mx-auto p-0">
                            <div className="auth-box auth-user-box">
                                <Row>
                                    <Col lg={6} md={12}>
                                        <div className="banner">

                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} className="auth-area">
                                        <Row className="w-100">
                                            <Col lg={12} md={12} className="mx-auto">
                                                <div className="auth-form">
                                                    <div className="img-area">
                                                        <Link to="/">
                                                            <img src='/images/logo-hd.png' alt='Joyfullmind' />
                                                        </Link>
                                                    </div>
                                                    <Outlet />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AuthUserLayout;