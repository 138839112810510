export const endpoint = {
  public: {
    CONFIGURATIONS: "/public/configurations",
    CONTACT: "/public/contact",
    BLOGS: "/public/blogs",
    DOCTORS: "/public/doctors",
    DOCTOR: "/public/doctor",
    AVAILABLE_SLOTS: "/public/available-slots",
    ALL_ABOUTS: "/public/all-abouts",
    BLOG_DETAILS: "/public/blogs-details",
    TESTIMONIALS: "/public/testimonials",
    FAQ: "/public/faq",
    BANNER_IMG: "/public/banner-img"
  },
  auth: {
    CHECK_ACCOUNT: "/auth/check-account",
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    REGISTER_DOCTOR: "/auth/register-doctor",
    GOOGLE_LOGIN_URL: "/auth/google",
    LOGIN_EMAIL: "/auth/email-login",
    GOOGLE_LOGIN_CALLBACK: "/auth/google/callback",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
    ACTIVATE_ACCOUNT: "/auth/activate-account",
  },
  user: {
    UPDATE_PROFILE: "/user/update-profile",
    UPDATE_ROLE: "/user/update-role",
    UPDATE_PREFERENCE: "/user/update-preference",
    SLOT_EVENTS: "/user/slot-events",
    CREATE_SLOTS: "/user/create-slots",
    DELETE_SLOTS: "/user/delete-slots",
    BOOK_APPOINTMENT: "/user/book-appointment",
    CONFIRM_PAYMENT: "/user/confirm-payment",
    APPOINTMENTS: "/user/appointments"
  },
  doctor: {
    DASHBOARD: "/doctor/dashboard",
    APPOINTMENTS: "/doctor/appointments",
    PATIENTS: "/doctor/patients",
    SAVE_PRESCRIPTIONN: "/doctor/save-prescription",
    SEND_PRESCRIPTIONN: "/doctor/send-prescription",
  },
  appointment: {
    DETAILS: "/appointments/details",
    ROOM_DETAILS: "/appointments/room-details",
    CANCEL: "/appointments/cancel",
    RATING: "/appointments/rating",
    ERROR_REASON: "/appointments/error-reason",
  },
  media: {
    UPLOAD_MULTIPLE: "/media/upload-multiple",
    UPLOAD: "/media/upload",
    UPLOAD_CERTIFICATE: "/media/upload-certificate",
    UPLOAD_BANNER: "/admin/banner",
    UPLOAD_IMAGE: "/media/upload-media"
  },
  admin: {
    Banner: {
      UPLOAD_BANNER: "/admin/banner",
      GET_BANNERS: "/admin/banner",
    },
    doctors: "/admin/doctors",
    addDoctor: "/admin/add-doctor",
    editDoctor: "/admin/edit-doctor",
    deleteDoctor: "/admin/delete-doctor",
    approveDoctor: "/admin/approve-doctor",
    rejectDoctor: "/admin/reject-doctor",
    patients: "/admin/patients",
    addPatient: "/admin/add-patient",
    editPatient: "/admin/edit-patient",
    deletePatient: "/admin/delete-patient",
    appointments: "/admin/appointments",
    corporates: "/admin/corporates",
    corporate: "/admin/corporate",
    addCorporate: "/admin/add-corporate",
    editCorporate: "/admin/edit-corporate",
    deleteCorporate: "/admin/delete-corporate",
    addPatientsToCorporate: "/admin/add-corporate-patients",
    removePatientFromCorporate: "/admin/remove-corporate-patient",
    activateCorporate: "/admin/activate-corporate",
    deActivateCorporate: "/admin/deactivate-corporate",
  },
  testimonial: '/testimonials',
  blog: '/blogs',
  faq: '/faqs',
  allAbout: "/all-abouts"
};
