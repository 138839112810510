import { Link } from "react-router-dom";
import { withBackendUrl } from "../../Utils/helpers";

const PatientBox = (props) => {

    const { patient } = props;

    return (
        <>
            <div className="doctor-box doctor-box-2 no-pad-no-border">
                <div className="img-box" style={{ backgroundImage: 'url(' + withBackendUrl(patient?.profileImage) + ')' }}></div>
                <div className="content-box">
                    <div className="title">
                        <h1>{patient?.firstName} {patient?.lastName}</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientBox;
