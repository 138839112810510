import { createSlice } from '@reduxjs/toolkit'
// import { getCompany } from '../../Services/company-service';
import { Dispatch } from 'redux';


const initialState = {

}

const authSlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        updateCompanyDetails(state: any, action) {
            return {
                ...state,
                ...action.payload
            };
        },
    }
});

export const { updateCompanyDetails } = authSlice.actions;

export default authSlice.reducer;

export const loadCompanyDetails = () => {
    return async (dispatch: Dispatch) => {
        try {
            // getCompany().then((res: any) => {
            //     dispatch(updateCompanyDetails(res?.data));
            // }).catch((err: any) => {
            //     dispatch(updateCompanyDetails({}));
            // })
        } catch (error) {
            dispatch(updateCompanyDetails({}));
        }
    };
};
