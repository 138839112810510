import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useTranslator from "../../Hooks/useTranslator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import FormField from "../FormFields/FormField";
import { handleFormikChange, handleFormikSubmit, withBackendUrl } from "../../Utils/helpers";
import { Col, Row } from "react-bootstrap";
import FormFieldSelect from "../FormFields/FormFieldSelect";
import DateFormField from "../FormFields/DateFormField";
import { AiOutlineCamera } from "react-icons/ai";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { upload } from "../../Services/media-service";
import { updateProfile } from "../../Services/user-service";
import TelephoneField from "../FormFields/TelephoneField";
import { updateUserDetails } from "../../Redux/slices/authSlice";

const ProfileForm = (props: any) => {
    // Locaization
    const { _t } = useTranslator();

    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showingLoading } = useSelector((state: any) => state.toast);

    const { user } = useSelector((state: any) => state.auth);

    // Mounting
    useEffect(() => { }, []);

    const validationSchema = yup.object({
        // email: yup
        //     .string()
        //     .email("Email is not valid")
        //     .required("Email is Required"),
        firstName: yup.string().required("First Name is Required"),
        lastName: yup.string().required("Last Name is Required"),
        mobileNo: yup.string().required("Mobile No. is Required"),
        gender: yup.string().required("Gender is Required"),
        dateOfBirth: yup.string().required("Date of Birth is Required")
    });

    const profileForm = useFormik({
        initialValues: {
            profileImage: user?.profileImage,
            firstName: user?.firstName,
            lastName: user?.lastName,
            mobileNo: user?.mobileNo,
            email: user?.email,
            gender: user?.gender,
            dateOfBirth: user?.dateOfBirth
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {
            delete values.email;
            dispatch(showLoadingToast(null));
            updateProfile(values).then((res: any) => {
                dispatch(showSuccessToast(res?.message));
                dispatch(updateUserDetails(res.data));
            }).catch((err: any) => {
                dispatch(showErrorToast(err?.message));
            }).finally(() => {  
                dispatch(hideLoadingToast(null));
            });
        },
    });

    const mediaChange = (e: any) => {
        dispatch(showLoadingToast("Uploading Image"))
        upload({
            media: e.target.files[0]
        })
            .then((res: any) => {
                handleFormikChange(res?.data, profileForm, "profileImage")
                dispatch(hideLoadingToast(null));
            })
            .catch(err => {
                dispatch(showErrorToast(err?.message))
            })
            .finally(() => {
                dispatch(hideLoadingToast(null));
            })
    }

    const openFilePicker = () => {
        if (inputRef?.current !== null) {
            // @ts-ignore
            inputRef.current.click();
        }
    }

    return (
        <>

            <div className='d-none'>
                <input ref={inputRef} type="file" placeholder="Upload Video" onChange={mediaChange} />
            </div>

            <Form onSubmit={(e) => handleFormikSubmit(e, profileForm)}>

                <Row>
                    <Col>
                        <div className="big-img-box center">
                            <p><span>Add Photo</span></p>
                            <div className="img-box" style={{ backgroundImage: 'url("' + withBackendUrl(profileForm.values.profileImage) + '")' }}>
                                <a onClick={openFilePicker} href={undefined}>
                                    <AiOutlineCamera />
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormField
                            id="firstName"
                            name="firstName"
                            label={"First Name"}
                            value={profileForm.values.firstName}
                            onChange={(e) => handleFormikChange(e, profileForm)}
                            showError={profileForm.touched.firstName}
                            errorText={profileForm.errors.firstName}
                            placeholder={"Enter First Name"}
                        />
                    </Col>
                    <Col>
                        <FormField
                            id="lastName"
                            name="lastName"
                            label={"Last Name"}
                            value={profileForm.values.lastName}
                            onChange={(e) => handleFormikChange(e, profileForm)}
                            showError={profileForm.touched.lastName}
                            errorText={profileForm.errors.lastName}
                            placeholder={"Enter Last Name"}
                        />
                    </Col>
                </Row>

                <TelephoneField
                    id="mobileNo"
                    name="mobileNo"
                    fieldType="mobileNo"
                    label={"Mobile No."}
                    value={profileForm.values.mobileNo}
                    showError={profileForm.touched.mobileNo}
                    onChange={(mobileNo) =>
                        handleFormikChange(mobileNo, profileForm, "mobileNo")
                    }
                    errorText={profileForm.errors.mobileNo}
                />

                <FormField
                    id="email"
                    name="email"
                    disabled
                    fieldType="email"
                    label={"Email"}
                    value={profileForm.values.email}
                    onChange={(e) => handleFormikChange(e, profileForm)}
                    showError={profileForm.touched.email}
                    errorText={profileForm.errors.email}
                    placeholder={"Enter Email Address"}
                />

                <FormFieldSelect
                    id="gender"
                    name="gender"
                    label={"Gender"}
                    options={[
                        { _id: "male", name: "Male" },
                        { _id: "female", name: "Female" }
                    ]}
                    value={profileForm.values.gender}
                    onChange={(e) => handleFormikChange(e, profileForm)}
                    showError={profileForm.touched.gender}
                    errorText={profileForm.errors.gender}
                    placeholder={"Select Gender"}
                />

                <DateFormField
                    id="dateOfBirth"
                    name="dateOfBirth"
                    label={"Date of Birth"}
                    value={profileForm.values.dateOfBirth}
                    onChange={(e) => handleFormikChange(e, profileForm)}
                    showError={profileForm.touched.dateOfBirth}
                    errorText={profileForm.errors.dateOfBirth}
                    placeholder={"Select Date of Birth"}
                />

                <div className="theme-formbtn">
                    <Button
                        className="btn-full"
                        variant="primary"
                        type="submit"
                        disabled={showingLoading}
                    >
                        Save Changes
                    </Button>
                </div>

            </Form>
        </>
    );
};

export default ProfileForm;
