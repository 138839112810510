import React from "react";
import { Button } from "react-bootstrap";

const ButtonElement = (props) => {

  return (
    <>
      <Button {...props}>{props?.text ? props?.text : props?.children}</Button>
    </>
  );
};

export default ButtonElement;
