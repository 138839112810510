import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SearchBar from "../../Components/Common/SearchBar";
import BlogsList from "../../Components/Site/Blog/BlogsList";
import { hideLoadingToast, showErrorToast, showLoadingToast } from "../../Redux/slices/toastSlice";
import { getDoctors } from "../../Services/admin-service";
import { getBlogDetails, getBlogs } from "../../Services/public-service";
import { haveValue, initialPaginationData } from "../../Utils/helpers";

const Blog = (props) => {

  const dispatch = useDispatch();

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm]: any = useState("");

  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [blog, setBlog]: any = useState(null);

  useEffect(() => {
    console.log(params);
    if (haveValue(params?.slug)) {
      setLoading(true);
      getBlogDetails(params?.slug).then((res: any) => {
        setBlog(res?.data);
        setLoading(false);
      }).catch((err: any) => {
        dispatch(showErrorToast("Failed to load blog details"));
      });
    }
  }, [params]);

  return (
    <>

      <section id="find-doctors" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>{blog?.title}</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="blog-content" dangerouslySetInnerHTML={{__html: blog?.body.toString()}}></div>
            </Col>
          </Row>

        </Container>
      </section>

    </>
  );
};

export default Blog;
