import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <>
      <div className="site-footer-bar">
        <Container>
          <Row>
            <Col md={12} lg={6} className="left">
              <p>All Rights Reserved</p>
            </Col>
            <Col md={12} lg={6} className="right">
              <ul>
                <li>
                  <Link to={"/privacy-policy"}>
                    <span>Privacy policy</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/terms-conditions"}>
                    <span>Terms & Conditions</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/contact-us"}>
                    <span>Contact Us</span>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
