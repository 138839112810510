import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const FormFieldRadios = (props: any) => {
  // Prop Destructuring
  const {
    label = "",
    errorText = "",
    onChange = () => { },
    placeholder = "",
    options = [],
    value = null,
    showError = false
  } = props;

  // State Variables
  const [haveError, setHaveError] = useState(false);

  // Mounting
  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
  }, [props]);

  return (
    <>
      <Form.Group
        className={`radio-bridge theme-forminput theme-forminput-radios ${haveError ? "erroractive" : ""}`}
      >
        {
          label != null ? <>
            <Form.Label>{label}</Form.Label>
          </> : null
        }
        <InputGroup>
          {
            options.map((optionItem, index) => {
              return <>
                <div className="form-Input-group-fields theme-forminput-radio-item">
                  <Form.Check
                    inline
                    type="radio"
                    id={optionItem.value}
                    value={optionItem.value}
                    label={optionItem.label}
                    className="theme-forminput-radio"
                    name={props?.name}
                    onChange={onChange}
                  />
                </div>
              </>;
            })
          }
        </InputGroup>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormFieldRadios;
