import { Rate } from "antd";


function RatingElement(props) {

    return (
        <>
            <Rate defaultValue={props?.value} disabled={props?.disabled} />
        </>
    );
}

export default RatingElement;
