import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";

import {
  handleFormikChange,
  handleFormikSubmit,
  haveValue,
} from "../../Utils/helpers";
import useTranslator from "../../Hooks/useTranslator";
import { Button, Col, Row } from "react-bootstrap";

import FormField from "../FormFields/FormField";
import { useDispatch } from "react-redux";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast,
  showSuccessToast,
} from "../../Redux/slices/toastSlice";
import { setLogin } from "../../Redux/slices/authSlice";
import ButtonElement from "../Common/ButtonElement";
import { FaBullseye } from "react-icons/fa";
import FormFieldRadios from "../FormFields/FormFieldRadios";
import TelephoneField from "../FormFields/TelephoneField";
import DateFormField from "../FormFields/DateFormField";
import FormFieldSelect from "../FormFields/FormFieldSelect";
import { registerDoctor } from "../../Services/auth-service";
import FormFieldFile from "../FormFields/FormFieldFile";

const DoctorRegisterForm = (props: any) => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const { showingLoading } = useSelector((state: any) => state.toast);

  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(_t("Enter valid Email"))
      .required(_t("Email is Required")),
    mobileNo: yup.string().required(_t("Phone No. is Required")),
    firstName: yup.string().required(_t("First Name is Required")),
    lastName: yup.string().required(_t("Last Name is Required")),
    gender: yup.string().required(_t("Gender is Required")),
    dateOfBirth: yup.string().required(_t("Date of Birth is Required")),
    experience: yup.string().required(_t("Experience is Required")),
    highestQualification: yup.string().required(_t("Highest Qualification is Required")),
    speciality: yup.string().required(_t("Speciality is Required")),
    // symptoms: yup.string().required(_t("Symptoms is Required")),
    appointmentPrice: yup.string().required(_t("Appointment Price is Required")),
    documents: yup.array().min(1, _t("Documents are Required")).required(_t("Documents are Required")),
  });

  const signupForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNo: "",
      email: "",
      gender: "",
      dateOfBirth: "",
      terms: "",
      symptoms: "",
      highestQualification: "",
      speciality: "",
      experience: "",
      appointmentPrice: "",
      documents: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(showLoadingToast(_t("submittingRegistrationDetails")));

      const dataToSend = { ...values };
      registerDoctor(dataToSend)
        .then((res: any) => {
          dispatch(showSuccessToast(res?.message));
          navigate("/login");
        })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    },
  });

  return (
    <>
      <Form onSubmit={(e) => handleFormikSubmit(e, signupForm)}>

        <Row>
          <Col>
            <Row>
              <Col>
                <FormField
                  id="firstName"
                  name="firstName"
                  fieldType="firstName"
                  label={"Full name"}
                  value={signupForm.values.firstName}
                  onChange={(e) => handleFormikChange(e, signupForm)}
                  showError={signupForm.touched.firstName}
                  errorText={signupForm.errors.firstName}
                  placeholder={"Enter First Name"}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormField
                  id="lastName"
                  name="lastName"
                  fieldType="lastName"
                  label={"Last name"}
                  value={signupForm.values.lastName}
                  onChange={(e) => handleFormikChange(e, signupForm)}
                  showError={signupForm.touched.lastName}
                  errorText={signupForm.errors.lastName}
                  placeholder={"Enter Last Name"}
                />
              </Col>
            </Row>

            <TelephoneField
              id="mobileNo"
              name="mobileNo"
              fieldType="mobileNo"
              label={"Mobile No."}
              value={signupForm.values.mobileNo}
              showError={signupForm.touched.mobileNo}
              onChange={(mobileNo) =>
                handleFormikChange(mobileNo, signupForm, "mobileNo")
              }
              errorText={signupForm.errors.mobileNo}
            />

            <FormField
              id="email"
              name="email"
              fieldType="email"
              label={"Email"}
              value={signupForm.values.email}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.email}
              errorText={signupForm.errors.email}
              placeholder={"Enter Email Address"}
            />

            <FormFieldSelect
              id="gender"
              name="gender"
              label={"Gender"}
              options={[
                { _id: "male", name: "Male" },
                { _id: "female", name: "Female" }
              ]}
              value={signupForm.values.gender}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.gender}
              errorText={signupForm.errors.gender}
              placeholder={"Select Gender"}
            />


          </Col>
          <Col>

            <DateFormField
              id="dateOfBirth"
              name="dateOfBirth"
              label={"Date of Birth"}
              value={signupForm.values.dateOfBirth}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.dateOfBirth}
              errorText={signupForm.errors.dateOfBirth}
              placeholder={"Select Date of Birth"}
            />

            <FormField
              id="highestQualification"
              name="highestQualification"
              fieldType="highestQualification"
              label={"Highest Qualification"}
              value={signupForm.values.highestQualification}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.highestQualification}
              errorText={signupForm.errors.highestQualification}
              placeholder={"Enter Highest Qualification"}
            />

            <FormField
              id="experience"
              name="experience"
              label={"Experience"}
              value={signupForm.values.experience}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.experience}
              errorText={signupForm.errors.experience}
              placeholder={"Enter Experience"}
            />

            <FormField
              id="speciality"
              name="speciality"
              label={"Speciality"}
              value={signupForm.values.speciality}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.speciality}
              errorText={signupForm.errors.speciality}
              placeholder={"Enter Speciality"}
            />

            {/* <FormField
              id="symptoms"
              name="symptoms"
              label={"Symptoms"}
              value={signupForm.values.symptoms}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.symptoms}
              errorText={signupForm.errors.symptoms}
              placeholder={"Enter Symptoms"}
            /> */}

            <FormField
              id="appointmentPrice"
              name="appointmentPrice"
              label={"Appointment Price"}
              value={signupForm.values.appointmentPrice}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.appointmentPrice}
              errorText={signupForm.errors.appointmentPrice}
              placeholder={"Enter Appointment Price"}
            />

          </Col>

        </Row>

        <Row>
          <Col>
            <FormFieldFile
              id="documents"
              name="documents"
              label={"Documents (Degrees/Medical Cerificates)"}
              multiple={true}
              // value={signupForm.values.documents}
              onChange={(e) => handleFormikChange(e, signupForm, "documents")}
              showError={signupForm.touched.documents}
              errorText={signupForm.errors.documents}
              placeholder={"Select Documents"}
            />  
          </Col>
        </Row>

        <div className="theme-formbtn">
          <ButtonElement variant="primary" type="submit" disabled={showingLoading}>
            Register
          </ButtonElement>
        </div>


        <div className="formbtngroups">
          <h6>
            Already have an Account?
            <Link className="linkbtn" to="/doctor/login">
              Login
            </Link>
          </h6>
        </div>


      </Form>
    </>
  );
};

export default DoctorRegisterForm;
