import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const addFaq = (payload: any = {}) => {
    return sendRequest(endpoint.faq, "POST", payload);
};

export const updateFaq = (id: any, payload: any = {}) => {
    return sendRequest(`${endpoint.faq}/${id}`, "POST", payload);
};

export const getFaq = (payload: any = {}) => {
    return sendRequest(endpoint.faq, "GET", payload);
};

export const deleteFaq = (faqId: any) => {
    return sendRequest(`${endpoint.faq}/${faqId}`, "DELETE");
};

