import React from "react";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

import PasswordField from "../FormFields/PasswordField";

import useTranslator from "../../Hooks/useTranslator";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast,
  showSuccessToast,
} from "../../Redux/slices/toastSlice";
import { resetPassword } from "../../Services/auth-service";
import { handleFormikChange, handleFormikSubmit } from "../../Utils/helpers";

const ResetPasswordForm = (props: any) => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, _t("passwordPatternMessage"))
      .required(_t("passwordIsRequired")),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "passwordMustMatch"),
  });

  const resetForm = useFormik({
    initialValues: {
      hash: hash,
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(showLoadingToast(_t("resettingPassword")));

      const dataToSend = { ...values };
      delete dataToSend.confirmPassword;

      // delete values["confirmPassword"];
      resetPassword(dataToSend)
        .then((res: any) => {
          dispatch(showSuccessToast(res?.message));
          navigate("/login");
        })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    },
  });

  return (
    <>
      <Form onSubmit={(e) => handleFormikSubmit(e, resetForm)}>
        <PasswordField
          id="password"
          name="password"
          label={_t("password")}
          value={resetForm.values.password}
          onChange={(e) => handleFormikChange(e, resetForm)}
          showError={resetForm.touched.password}
          errorText={resetForm.errors.password}
          placeholder={_t("passwordPlaceholder")}
        />
        <PasswordField
          id="confirmPassword"
          name="confirmPassword"
          label={_t("confirmPassword")}
          value={resetForm.values.confirmPassword}
          onChange={(e) => handleFormikChange(e, resetForm)}
          showError={resetForm.touched.confirmPassword}
          errorText={resetForm.errors.confirmPassword}
          placeholder={_t("confirmPasswordPlaceholder")}
        />
        <div className="theme-formbtn">
          <Button variant="primary" type="submit">
            {_t("confirm")}
          </Button>
        </div>
        <div className="text-center mt-2">
          <Link to="/login">{_t("backToLogin")}</Link>
        </div>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
