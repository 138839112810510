import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiLocationPlus } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { getAllAppointments, getCorporate, getPatients } from '../../Services/admin-service';
import { haveValue, initialPaginationData, printTime } from '../../Utils/helpers';
import { hideLoadingToast, showErrorToast, showLoadingToast } from '../../Redux/slices/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Components/Common/Pagination';
import NoTabRow from '../../Components/Common/NoTabRow';
import { useParams } from 'react-router-dom';
import PatientBox from '../../Components/Patient/PatientBox';
import FormField from '../../Components/FormFields/FormField';
import DateFormField from '../../Components/FormFields/DateFormField';
import AdminAppointmentItem from '../../Components/Appointment/AdminAppointmentItem';
import NoResultsFound from '../../Components/Common/NoResultsFound';

const CorporateReports = () => {

  const params = useParams();
  const dispatch = useDispatch();

  const [modelData, setModelData]: any = useState(null);
  const [searchTerm, setSearchTerm]: any = useState("");
  const [sortType, setSortType]: any = useState("newest");

  const [loading, setLoading]: any = useState(false);
  const [showModal, setShowModel]: any = useState(false);

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);

  const [selectedPatients, setSelectedPatients] = useState([]);

  const { showingLoading } = useSelector((state: any) => state.toast);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const hideModal = () => {
    setShowModel(false);
  }

  const addModalOpen = () => {
    setShowModel(true);
  }

  useEffect(() => {
    if (haveValue(params?.corporateId)) {
      setLoading(true);
      getCorporate({ id: params?.corporateId }).then((res: any) => {
        setModelData(res?.data);
        setLoading(false);
      }).catch((err: any) => {
        dispatch(showErrorToast("Failed to load corporate details"));
      });
    }
  }, [params]);

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getAllAppointments({
      page,
      search: searchTerm,
      startDate,
      endDate,
      sort: sortType,
      type: 'custom',
      corporate: params?.corporateId
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, sortType, page, startDate, endDate]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Corporate Reports</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4>{modelData?.name}</h4>
              <p className='mb-3'> <BiLocationPlus /> {modelData?.address}</p>
              <div className="table-head-options">
                <div className='left'>
                  <Row>
                    <Col>
                      <DateFormField
                        id="startDate"
                        name="startDate"
                        label={"Start Date"}
                        onChange={(e) => setStartDate(e?.target?.value)}
                        value={startDate}
                        placeholder={"Select Start Date"}
                      />
                    </Col>
                    <Col>
                      <DateFormField
                        id="endDate"
                        name="endDate"
                        label={"End Date"}
                        onChange={(e) => setStartDate(e?.target?.value)}
                        value={endDate}
                        placeholder={"Select End Date"}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="grid-table">
                <Row className="grid-table-head">
                  <Col lg={5} md={12}>
                    <Row>
                      <Col className="grid-table-head-column" lg={6} md={12}>
                        <h6>Patient</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={6} md={12}>
                        <h6>Doctor</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={7} md={12}>
                    <Row>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Date and Time</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Patient Type</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Prescription</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Actions</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="grid-table-body">
                  {
                    paginationData?.docs && paginationData?.docs.length > 0 ? <>
                      {paginationData?.docs?.map((data: any, index) => (
                        <Col lg={12} md={12} className="grid-table-item">
                          <AdminAppointmentItem onRefresh={() => loadData(page)} appointment={data} />
                        </Col>
                      ))}
                    </> : <>
                      <NoResultsFound />
                    </>
                  }
                </Row>
              </div>
            </Col>

            <Col md={12}>
              {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                  <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                </> : null
              }
            </Col>

          </Row>

        </div>
      </div>

    </>
  )
}

export default CorporateReports;