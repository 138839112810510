import axios from "axios";
import { getLoginToken, withBackendUrl } from "./../Utils/helpers";

let axiosClient = axios.create({
    timeout: 100000
});

axiosClient.interceptors.request.use(function (config) {
    config.headers['Authorization'] = getLoginToken();
    return config;
}, function (error) {
    return Promise.reject(error);
});

export const sendRequest = (path: string, method = "GET", data = null, headers = null, contentType = "application/json") => {

    return new Promise((resolve, reject) => {

        const params: any = {
            url: withBackendUrl(path),
            method,
            headers: {
                "Content-Type": contentType,
                ...(headers || {})
            },
            params: {},
            data: {}
        };

        if (data !== null) {
            if (method == 'GET') {
                params.params = data;
            } else if (method == 'POST') {
                params.data = data;
            }
        }

        axiosClient(params)
            .then((response) => {
                return resolve(response.data);
            })
            .catch((error) => {
                return reject(error?.response?.data);
            });

    });
};
