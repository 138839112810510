import { Col, Container, Row } from "react-bootstrap";
import ContactForm from "../../Components/Site/ContactForm";
import PageTitleBar from "../../Components/Site/PageTitleBar";

const ContactUs = (props) => {

  return (
    <>

      <PageTitleBar preTitle="Contact us" title="Let's Start a Conversation" subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim." />

      <section id="privacy-policy" className="section-pad">
        <Container>

          <Row>
            <Col lg={6} md={12} className="mx-auto">
              <ContactForm />
            </Col>
          </Row>

        </Container>
      </section>

    </>
  );
};

export default ContactUs;
