import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DoctorPreviousList from '../../Components/Site/Appointments/DoctorPreviousList';

const DoctorPreviousAppointments = () => {

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Previous Appointments</h1>
              </div>
            </Col>
          </Row>


          <DoctorPreviousList />

        </div>
      </div>
    </>
  )
}

export default DoctorPreviousAppointments;