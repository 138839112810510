import { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ButtonElement from "../../Components/Common/ButtonElement";
import ContentLoader from "../../Components/Loader/ContentLoader";
import DoctorAppointmentTestimonials from "../../Components/Site/Testimonials/DoctorAppointmentTestimonials";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { getAvailableSlots, getDoctor } from "../../Services/public-service";
import { haveValue, printAmount, withBackendUrl } from "../../Utils/helpers";
import ReactWeeklyDayPicker from 'react-weekly-day-picker';
import { bookAppointment, confirmPayment } from "../../Services/user-service";
import useRazorpay from "react-razorpay";

const Doctor = (props) => {

  const params = useParams();
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state: any) => state.auth);

  const [showBookingModal, setShowBookingModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [doctor, setDoctor]: any = useState(null);

  const [slots, setSlots] = useState([]);
  const [slotsLoading, setSlotsLoading] = useState(false);

  const [slotDate, setSlotDate]: any = useState(null);
  const [slotTime, setSlotTime] = useState(null);

  const [Razorpay] = useRazorpay();

  useEffect(() => {
    if (haveValue(params?.slug)) {
      setLoading(true);
      getDoctor({ id: params?.slug }).then((res: any) => {
        setDoctor(res?.data);
        setLoading(false);
      }).catch((err: any) => {
        dispatch(showErrorToast("Failed to load doctor details"));
      });
    }
  }, [params]);

  useEffect(() => {
    loadSlots();
  }, [doctor]);

  const handleSelectDay = (data) => {
    setSlotDate(data[0]);
    return true;
  }

  useEffect(() => {
    if (haveValue(slotDate)) {
      loadSlots();
    } else {
      setSlotDate(new Date());
    }
  }, [slotDate]);

  const loadSlots = () => {
    if (haveValue(doctor?._id)) {
      setSlotsLoading(true);
      setSlots([]);
      setSlotTime(null);
      getAvailableSlots({
        doctor: doctor._id,
        date: slotDate
      }).then((res: any) => {
        setSlots(res?.data);
      }).catch((err) => {
        dispatch(showErrorToast("Failed to load slots"));
      }).finally(() => {
        setSlotsLoading(false);
      });
    }
  }

  const chooseSlot = (slot) => {
    if (slot?.isBooked) {
      dispatch(showErrorToast("Slot Already Booked"));
    } else {
      setSlotTime(slot?.start);
    }
  }

  const processAndPay = () => {
    if (haveValue(slotDate) && haveValue(slotTime)) {
      dispatch(showLoadingToast(null));
      bookAppointment({
        time: slotTime,
        doctor: doctor._id
      }).then((res: any) => {
        dispatch(showSuccessToast(res?.message));
        makePayment(res?.data);
      }).catch((err) => {
        dispatch(showErrorToast(err?.message));
      }).finally(() => {
        dispatch(hideLoadingToast(null));
      });
    } else {
      dispatch(showErrorToast("Please select date and time"));
    }
  }

  const makePayment = (appointmentData: any) => {
    let appointment = appointmentData;

    if (appointment.paymentRequired.toString() === "true") {
      const options: any = {
        ...appointment.paymentOptions,
        handler: function (response) {
          dispatch(showLoadingToast("Confirming your payment..."));
          confirmPayment({
            transactionId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
            appointmentId: appointment?.appointment?._id,
          }).then((res: any) => {
            dispatch(showSuccessToast(res?.message));
            loadSlots();
          }).catch((err) => {
            dispatch(showErrorToast(err?.message));
          }).finally(() => {
            dispatch(hideLoadingToast(null));
          });
        },
      };

      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        dispatch(showErrorToast(response.error.reason));
      });
      rzp1.open();

    } else {
      loadSlots();
    }
  }

  const bookApptNow = () => {
    if (isAuthenticated) {
      setShowBookingModal(true)
    } else {
      dispatch(showErrorToast("Please login to book appointment"));
    }
  }

  return (
    <>

      <section id="find-doctors" className="section-pad">
        <Container>

          {
            loading && !haveValue(doctor) ? <>
              <ContentLoader />
            </> : <>
              <Row>
                <Col>
                  <div className="section-title section-title-3 left">
                    <h1>Dr. {doctor?.firstName} {doctor?.lastName}</h1>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div id="doctor-info-box">
                    <div className="doctor-info">
                      <div className="img-box" style={{ backgroundImage: 'url(' + withBackendUrl(doctor?.profileImage) + ')' }}></div>
                      <div className="title">
                        <h1>Dr. {doctor?.firstName} {doctor?.lastName}</h1>
                        <h2>{doctor?.highestQualification}</h2>
                      </div>
                    </div>
                    <div className="doctor-details">
                      <div className="info-card">
                        <h3>About Doctor</h3>
                        <p>{doctor?.about}</p>
                      </div>
                      <div className="info-card">
                        <h3>Experience</h3>
                        <p>{doctor?.experience}</p>
                      </div>
                      <div className="info-card with-actions">
                        {
                          haveValue(user?.corporate) ? <>
                          </> : <>
                            <div className="info">
                              <h3>Consultation Fee</h3>
                              <p className="price">{printAmount(doctor?.appointmentPrice)}</p>
                            </div>
                          </>
                        }
                        <div className="button-box">
                          <ButtonElement onClick={() => bookApptNow()} text="Book Appointment Now" variant="primary" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          }

        </Container>
      </section>

      {
        doctor !== null && doctor?.appointments.length > 0 ? <>
          <DoctorAppointmentTestimonials appointments={doctor.appointments} />
        </> : null
      }

      <Modal
        size="lg"
        className="verification_main_modal"
        show={showBookingModal}
        onHide={() => setShowBookingModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Set An Appointment</Modal.Header>
        <Modal.Body>
          <p>Select Date</p>
          <ReactWeeklyDayPicker
            startDay={new Date()}
            selectedDays={[slotDate]}
            multipleDaySelect={false}
            selectDay={handleSelectDay}
          />
          <div className="py-3">
            <p>Select Time</p>
            <div className="appointment-slots">
              {
                slotsLoading ? <>
                  <ContentLoader className="py-4" />
                </> : <>
                  {
                    slots.length > 0 ? <>
                      <ul>
                        {
                          slots.map((slot: any) => {
                            return <>
                              {
                                slot?.isBooked ? <></> : <>
                                  <li>
                                    <a onClick={(e) => chooseSlot(slot)} href={undefined} className={`${slotTime == slot?.start ? 'active' : ''} ${slot?.isBooked ? 'disabled' : ''} `}>{slot?.startHour}</a>
                                  </li>
                                </>
                              }
                            </>
                          })
                        }
                      </ul>
                    </> : <>
                      <p className="py-3">No Slots Available</p>
                    </>
                  }
                </>
              }
            </div>
          </div>
          <ButtonElement className="w-100" onClick={() => processAndPay()} text="Book Now" variant="primary" />
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Doctor;
