import { Col, Row } from "react-bootstrap";
import Pagination from "../../Common/Pagination";
import BlogItem from "./BlogItem";

const BlogsList = (props) => {

    const { paginationData } = props;

    return (
        <>

            <Row>
                {
                    paginationData?.docs && paginationData?.docs.length > 0 ? <>
                        {paginationData?.docs?.map((data: any, index) => (
                            <Col key={index} lg={12}>
                                <BlogItem blog={data} />
                            </Col>
                        ))}
                    </> : <>

                    </>
                }
            </Row>
            <Row>
                <Col>
                    {
                        paginationData?.docs && paginationData?.docs.length > 0 ? <>
                            <Pagination onPageChange={props?.handlePageChange} paginationData={paginationData} />
                        </> : null
                    }
                </Col>
            </Row>

        </>
    );
};

export default BlogsList;
