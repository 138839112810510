import AboutPlatform from "../../Components/Site/AboutPlatform";
import Banner from "../../Components/Site/Banner";
import DoctorsCarousel from "../../Components/Site/Doctors/DoctorsCarousel";
import Faqs from "../../Components/Site/Faq/Faqs";
import HomeAbout from "../../Components/Site/HomeAbout";
import Steps from "../../Components/Site/Steps";
import TestimonialsCarousel from "../../Components/Site/Testimonials/TestimonialsCarousel";

const Home = (props) => {

  return (
    <>
      <Banner />
      <Steps />
      {/* <AboutPlatform /> */}
      <HomeAbout/>
      <DoctorsCarousel/>
      <TestimonialsCarousel/>
      <Faqs/>
    </>
  );
};

export default Home;
