import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const getAppointmentDetail = (payload:any) => {
  return sendRequest(endpoint.appointment.DETAILS, "GET", payload);
}

export const getAppointmentRoomDetail = (payload:any) => {
  return sendRequest(endpoint.appointment.ROOM_DETAILS, "GET", payload);
}

export const cancelAppointment = (payload:any) => {
  return sendRequest(endpoint.appointment.CANCEL, "POST", payload);
}

export const submitAppointmentRating = (payload:any) => {
  return sendRequest(endpoint.appointment.RATING, "POST", payload);
}

export const submitErrorReason = (payload:any) => {
  return sendRequest(endpoint.appointment.ERROR_REASON, "POST", payload);
}
