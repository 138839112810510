import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import SearchBar from "../../Components/Common/SearchBar";
import BlogsList from "../../Components/Site/Blog/BlogsList";
import { hideLoadingToast, showErrorToast, showLoadingToast } from "../../Redux/slices/toastSlice";
import { getDoctors } from "../../Services/admin-service";
import { getBlogs } from "../../Services/public-service";
import { initialPaginationData } from "../../Utils/helpers";

const Blogs = (props) => {

  const dispatch = useDispatch();

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm]: any = useState("");

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getBlogs({
      page,
      search: searchTerm
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  return (
    <>

      <section id="find-doctors" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>Blogs</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={12}>
              <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm}  placeholder="Search here..." />
            </Col>
          </Row>

          <Row>
            <Col>
              <BlogsList handlePageChange={handlePageChange} paginationData={paginationData}/>
            </Col>
          </Row>

        </Container>
      </section>

    </>
  );
};

export default Blogs;
