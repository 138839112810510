import { Link } from "react-router-dom";
import { withBackendUrl } from "../../../Utils/helpers";

const DoctorBox2 = (props) => {

    const { doctor, link } = props;

    return (
        <>
            <Link to={link} className="doctor-box doctor-box-2 no-pad-no-border">
                <div className="img-box" style={{ backgroundImage: 'url(' + withBackendUrl(doctor?.profileImage) + ')' }}></div>
                <div className="content-box">
                    <div className="title">
                        <h1>Dr. {doctor?.firstName} {doctor?.lastName}, {doctor?.experience}</h1>
                        <p>{doctor?.speciality}</p>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default DoctorBox2;
