import React from "react";
import { Spin } from 'antd';

const ContentLoader = (props) => {

    return (
        <>
            <Spin {...props}/>
        </>
    );
};

export default ContentLoader;
