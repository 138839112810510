import { configureStore } from '@reduxjs/toolkit'

import toastReducer from './slices/toastSlice';
import authReducer from './slices/authSlice';
import configurationsReducer from './slices/configurationsSlice';
import companyReducer from './slices/companySlice';
import { setLocalStorageItem } from '../Utils/localStorage';
import { haveValue } from '../Utils/helpers';

const store = configureStore({
    reducer: {
        toast: toastReducer,
        auth: authReducer,
        configurations: configurationsReducer,
        company: companyReducer,
    }
});

function handleStateChange() {
    const state = store.getState();
    console.log('New state:', state);
    if (haveValue(state?.auth)) {
        setLocalStorageItem('user', JSON.stringify(state?.auth?.user));
        setLocalStorageItem('token', state?.auth?.token);
    }
}

const state = store.getState();
// console.log('Current state:', state);
store.subscribe(handleStateChange);

export default store;