import { BiPlus } from "react-icons/bi";
import { LuImagePlus } from "react-icons/lu";
import { uploadBannerImage } from "../../Services/media-service";
import { useDispatch } from "react-redux";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { useRef } from "react";


const ImageSelector = (props) => {

    const inputElement: any = useRef()
    const dispatch = useDispatch();

    const openFilePicker = () => {
        inputElement.current.click();
    }

    const handleChange = (e: any) => {
        console.log(e.target.files[0]);
        var formData = new FormData();
        formData.append("media", e.target.files[0]);
        dispatch(showLoadingToast("Uploading Image"))
        uploadBannerImage(formData)
            .then(res => {
                dispatch(showSuccessToast("Image Uploaded"))
            })
            .catch(err => {
                dispatch(showErrorToast(err?.message))
            })
            .finally(() => {
                dispatch(hideLoadingToast(null));
            })
    }

    return (
        <>
            <div className="d-none">
                <input ref={inputElement} name="media" type="file" onChange={handleChange} />
            </div>
            <div onClick={openFilePicker} className="img-selector img-selector-clicker">
                <LuImagePlus />
                <h6>
                    <BiPlus />
                    <span>Add Image</span>
                </h6>
                <p>(Recommended size 1080X1080)</p>
            </div>
        </>
    )
}

export default ImageSelector;