import React from 'react'
import Card from 'react-bootstrap/Card';
import GraphData from './GraphData';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormFieldSelect from '../../../Components/FormFields/FormFieldSelect';
import { BsCurrencyRupee, BsPeople } from 'react-icons/bs';
import { BiCalendar, BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';
import { AiOutlineMedicineBox } from 'react-icons/ai';


const AdminDashboard = () => {

    return (
        <>
            <div className='dashboard_layout ml-0'>
                <div className="cardcontainer">

                    <Row>
                        <Col>
                            <div className="section-title section-title-5 left">
                                <h1>Dashboard</h1>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mb-2'>
                        <Col md={2}>
                            <FormFieldSelect
                                id="gender"
                                name="gender"
                                label={false}
                                options={[
                                    { _id: "weekly", name: "Weekly" },
                                    { _id: "monthly", name: "Monthly" }
                                ]}
                                value={"weekly"}
                                placeholder={"Select Duration Type"}
                            />
                        </Col>
                    </Row>

                    <Row gap={5}>
                        <Col md={3} sm={12}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <BsCurrencyRupee />
                                        </div>
                                        <div className="text_data">
                                            <p>Total Earnings</p>
                                            <h1>
                                                <span className="val">₹1,500,00</span>
                                                <span className='growth up'>
                                                    <span><BiUpArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={3} sm={12}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <AiOutlineMedicineBox />
                                        </div>
                                        <div className="text_data">
                                            <p>Doctors</p>
                                            <h1>
                                                <span className="val">120</span>
                                                <span className='growth down'>
                                                    <span><BiDownArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <BsPeople />
                                        </div>
                                        <div className="text_data">
                                            <p>Patients</p>
                                            <h1>
                                                <span className="val">322</span>
                                                <span className='growth up'>
                                                    <span><BiUpArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className='stats-card'>
                                <Card.Body>
                                    <div className='card_dashboard'>
                                        <div className='icon'>
                                            <BiCalendar />
                                        </div>
                                        <div className="text_data">
                                            <p>Total Appointments</p>
                                            <h1>
                                                <span className="val">574</span>
                                                <span className='growth up'>
                                                    <span><BiUpArrowAlt /></span>
                                                    <span>0.8%</span>
                                                </span>
                                            </h1>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <GraphData />
            </div>
        </>
    )
}

export default AdminDashboard