import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { getDoctors } from "../../../Services/public-service";
import { slickOptions } from "../../../Utils/constants";
import DoctorBox from "./DoctorBox";

const DoctorsCarousel = (props) => {

  const settings = {
    ...slickOptions,
    slidesToShow: 5
  };

  const [doctors, setDoctors] = useState([]);

  const loadData = () => {
    getDoctors().then((res: any) => {
      setDoctors(res?.data?.docs);
    }).catch((err) => {

    });
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <section id="doctors" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title">
                <p>Our</p>
                <h1>Doctors</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="mx-auto">
              <Slider {...settings}>
                {
                  doctors.map((doctor:any) => {
                    return <>
                      <DoctorBox link={`/doctors/${doctor?._id}`} doctor={doctor} />
                    </>
                  })
                }
              </Slider>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  );
};

export default DoctorsCarousel;
