import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useTranslator() {

  const { t } = useTranslation();

  const _t = (key: any) => {
    return t(key);
  }

  return {
    _t
  };

}
