import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentLoader from "../../Components/Loader/ContentLoader";
import useTranslator from "../../Hooks/useTranslator";
import { useDispatch } from "react-redux";

import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { activateAccount } from "../../Services/auth-service";
import { haveValue } from "../../Utils/helpers";

const EmailActivation = (props: any) => {

  const { _t } = useTranslator();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hash } = useParams();

  useEffect(() => {
    if (haveValue(hash)) {
      dispatch(showLoadingToast(_t("activatingAccount")));
      activateAccount({
        hash
      })
        .then((res: any) => {
          dispatch(showSuccessToast(res?.message));
        })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
          navigate("/login");
        });
    }
  }, [hash]);

  return (
    <>
      <div className="formtitlebar mb-0">
        <h4>{_t("emailActivationingTitle")}</h4>
        <p className="mb-3">{_t('emailActivationingSubTitle')}</p>
        <ContentLoader />
      </div>
    </>
  );
};

export default EmailActivation;
