import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { haveValue, printAmount, withBackendUrl } from "../../../Utils/helpers";

const DoctorBox = (props) => {

    const { doctor } = props;

    const { user, isAuthenticated } = useSelector((state: any) => state.auth);

    return (
        <>
            <Link to={props?.link} className="doctor-box">
                <div className="img-box" style={{ backgroundImage: 'url(' + withBackendUrl(doctor?.profileImage) + ')' }}></div>
                <div className="content-box">
                    <div className="title">
                        <h1>Dr. {doctor?.firstName} {doctor?.lastName}, {doctor?.experience}</h1>
                        <p>{doctor?.speciality}</p>
                    </div>
                    {
                        isAuthenticated && haveValue(user?.corporate) ? <>
                            <div className="info">
                                <span>Education:- {doctor?.highestQualification}</span>
                            </div>
                        </> : <>
                            <div className="info">
                                <span className="price">{printAmount(doctor?.appointmentPrice)}<span>/30 Mins</span></span>
                                <span>Consultation fee</span>
                            </div>
                        </>
                    }
                </div>
            </Link>
        </>
    );
};

export default DoctorBox;
