import { Col, Row } from "react-bootstrap";
import { demoPaginationData, MEDICINE_LIST } from "../../../Utils/constants";
import Pagination from "../../Common/Pagination";
import PharmacyItem from "./PharmacyItem";

const Pharmacies = (props) => {

    return (
        <>

            <Row>
                {
                    MEDICINE_LIST.map((medicine: string) => {
                        return <>
                            <Col lg={3} md={3} sm={6}>
                                <PharmacyItem medicine={medicine}/>
                            </Col>
                        </>
                    })
                }
            </Row>
            {/* <Row>
                <Col>
                    <Pagination paginationData={demoPaginationData} />
                </Col>
            </Row> */}

        </>
    );
};

export default Pharmacies;
