import { createSlice } from '@reduxjs/toolkit'
import { UserConstants } from '../../Constants/user';
import { updatePreference } from '../../Services/user-service';
import { cloneObject, makeSureObject } from '../../Utils/helpers';
import { getLocalStorageItem } from '../../Utils/localStorage';

const initialState = {
    isAuthenticated: getLocalStorageItem('token') != null ? true : false,
    user: getLocalStorageItem('user') != null ? JSON.parse(getLocalStorageItem('user')) : null,
    token: getLocalStorageItem('token')
}

const updateUserPreference = (userPreference) => {
    updatePreference({ preference: userPreference }).then((res: any) => {
        //update done  
    }).catch((err: any) => {
        console.log("Failed to Update Prefernce", err);
    })
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLogin(state: any, action) {
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                user: action.payload.user
            }
        },
        updateUserDetails(state: any, action) {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
            }
        },
        switchToRole(state: any, action) {
            let userData = cloneObject(state.user);
            if (userData && userData.role.length > 0) {
                if (userData.role.includes(action.payload)) {
                    userData.preference = makeSureObject(userData.preference);
                    userData.preference['currentRole'] = action.payload;
                    updateUserPreference(userData.preference);
                }
            }
            return {
                ...state,
                user: userData
            }
        },
        logout(state: any, action) {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null
            }
        }
    }
});

export const { setLogin, updateUserDetails, logout, switchToRole } = authSlice.actions;

export default authSlice.reducer;