import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { hideLoadingToast, showErrorToast, showLoadingToast } from "../../Redux/slices/toastSlice";
import { uploadMultiple } from "../../Services/media-service";

const FormFieldFile = (props: any) => {
  const {
    label,
    errorText,
    multiple = false,
    disabled = false,
    readOnly = false,
    showError = false
  } = props;

  const dispatch = useDispatch();

  const [haveError, setHaveError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
  }, [props]);

  const handleFileChange = (event) => {
    let files = event.target.files;
    if (files.length > 0) {
      dispatch(showLoadingToast("Uploading..."))
      uploadMultiple({
        media: files
      })
        .then((res: any) => {
          if (props?.multiple) {
            props?.onChange(res.data);
          } else {
            props?.onChange(res.data[0]);
          }
        })
        .catch(err => {
          dispatch(showErrorToast(err?.message))
          if (props?.multiple) {
            props?.onChange([]);
          } else {
            props?.onChange(null);
          }
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    }
  }

  return (
    <>
      <Form.Group
        className={`theme-forminput ${haveError ? "erroractive" : ""}`}
      >
        {
          label ? <>
            <Form.Label>{label}</Form.Label>
          </> : null
        }
        <InputGroup>
          <div className="form-Input-group-fields">
            <Form.Control multiple={multiple} {...props} type={"file"} onChange={handleFileChange} />
          </div>
        </InputGroup>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormFieldFile;
