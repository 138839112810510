import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Sidebar from './Sidebar';

const DoctorLayout = () => {
    return (
        <div className="main_dashboard_block">
            <div className='top_navbar'>
                <Navbar collapseOnSelect fixed="top" expand="xl" className='' variant="light">
                    <Navbar.Brand className='logoimg' href="/"><img src='/images/logo-hd.png' alt='logo' /></Navbar.Brand>
                    <Navbar.Toggle onClick={() => document.body.classList.toggle('sidebar-open')} aria-controls="responsive-navbar-nav" />
                </Navbar>
            </div>
            <div className="dashboard_wrapper">
                <div className='dashboardsidebar'>
                    <Sidebar />
                </div>
                <div className="mainoutlet adminooutlet">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default DoctorLayout;