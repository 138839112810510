import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

const CheckboxField = (props: any) => {
  // Prop Destructuring
  const { label = "", errorText = "", showError = false } = props;

  // State Variables
  const [haveError, setHaveError] = useState(false);

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
  }, [props]);

  return (
    <>
      <div className="theme-checkbox-container">
        <Form.Group
          className={`theme-forminput themechecboxbar ${showError ? "erroractive" : ""
            }`}
        >
          <input {...props} className="form-check-input" type="checkbox" />
          <label htmlFor={props?.id}>{label}</label>
        </Form.Group>
        {showError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CheckboxField;
