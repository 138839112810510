import FormField from "./FormField";


const DateFormField = (props: any) => {

  return (
    <>
      <FormField {...props} fieldType="date" />
    </>
  );

};

export default DateFormField;
