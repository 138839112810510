import { Accordion, Col, Form, Modal, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import useTranslator from '../../Hooks/useTranslator';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import ButtonElement from '../Common/ButtonElement';
import FormFieldSelect from '../FormFields/FormFieldSelect';
import { handleFormikChange, handleFormikSubmit, haveValue, withBackendUrl } from '../../Utils/helpers';
import FormField from '../FormFields/FormField';
import TelephoneField from '../FormFields/TelephoneField';
import DateFormField from '../FormFields/DateFormField';
import { AiOutlineCamera } from 'react-icons/ai';
import FormFieldArea from '../FormFields/FormFieldArea';
import { addDoctor, editDoctor } from '../../Services/admin-service';
import { upload } from '../../Services/media-service';

const DoctorFormModal = (props) => {

    const { showModal, modelData } = props;

    const hideModal = () => {
        props?.onModalHide();
    }

    const inputRef = useRef(null);
    const { _t } = useTranslator();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showingLoading } = useSelector((state: any) => state.toast);

    let schemaObject: any = {
        email: yup
            .string()
            .email(_t("Enter valid Email"))
            .required(_t("Email is Required")),
        about: yup.string().required(_t("About is Required")),
        mobileNo: yup.string().required(_t("Phone No. is Required")),
        firstName: yup.string().required(_t("First Name is Required")),
        lastName: yup.string().required(_t("Last Name is Required")),
        gender: yup.string().required(_t("Gender is Required")),
        dateOfBirth: yup.string().required(_t("Date of Birth is Required")),
        experience: yup.string().required(_t("Experience is Required")),
        highestQualification: yup.string().required(_t("Highest Qualification is Required")),
        speciality: yup.string().required(_t("Speciality is Required")),
        // symptoms: yup.string().required(_t("Symptoms is Required")),
        appointmentPrice: yup.string().required(_t("Appointment Price is Required")),
        profileImage: yup.string().optional(),
    };

    useEffect(() => {
        for (let k in modelData) {
            if (haveValue(schemaObject[k])) {
                handleFormikChange(modelData[k], modalForm, k);
            }
        }
    }, [modelData]);

    const validationSchema = yup.object(schemaObject);

    const modalForm = useFormik({
        initialValues: {
            profileImage: haveValue(modelData?.profileImage) ? modelData?.profileImage : "static/user-dp.png",
            about: modelData?.about,
            firstName: modelData?.firstName,
            lastName: modelData?.lastName,
            mobileNo: modelData?.mobileNo,
            email: modelData?.email,
            gender: modelData?.gender,
            dateOfBirth: modelData?.dateOfBirth,
            experience: modelData?.experience,
            highestQualification: modelData?.highestQualification,
            speciality: modelData?.speciality,
            symptoms: modelData?.symptoms,
            appointmentPrice: modelData?.appointmentPrice
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {
            dispatch(showLoadingToast(null));

            let request: any = null;

            if (haveValue(modelData?._id)) {
                request = editDoctor({
                    _id: modelData?._id,
                    ...values
                });
            } else {
                request = addDoctor(values);
            }

            request.then((res: any) => {
                dispatch(showSuccessToast(res?.message));
                hideModal();
            })
                .catch((err: any) => {
                    dispatch(showErrorToast(err?.message));
                })
                .finally(() => {
                    dispatch(hideLoadingToast(null));
                });
        },
    });

    const mediaChange = (e: any) => {
        dispatch(showLoadingToast("Uploading Image"))
        upload({
            media: e.target.files[0]
        })
            .then((res: any) => {
                handleFormikChange(res?.data, modalForm, "profileImage")
                dispatch(hideLoadingToast(null));
            })
            .catch(err => {
                dispatch(showErrorToast(err?.message))
            })
            .finally(() => {
                dispatch(hideLoadingToast(null));
            })
    }

    const openFilePicker = () => {
        if (inputRef?.current !== null) {
            // @ts-ignore
            inputRef.current.click();
        }
    }

    return (
        <>
            <div className='d-none'>
                <input ref={inputRef} type="file" placeholder="Upload Video" onChange={mediaChange} />
            </div>
            <Modal
                className="verification_main_modal"
                show={showModal}
                size="lg"
                onHide={() => hideModal()}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>Manage Doctor</Modal.Header>
                <Modal.Body>

                    <Form onSubmit={(e) => handleFormikSubmit(e, modalForm)}>

                        <Row>
                            <Col md="4">
                                <div className="big-img-box center">
                                    <p><span>Add Photo</span></p>
                                    <div className="img-box" style={{ backgroundImage: 'url(' + withBackendUrl(modalForm.values.profileImage) + ')' }}>
                                        <a onClick={openFilePicker} href={undefined}>
                                            <AiOutlineCamera />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md="8">
                                <FormFieldArea
                                    rows="10"
                                    id="about"
                                    name="about"
                                    fieldType="textarea"
                                    label={"About"}
                                    value={modalForm.values.about}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.about}
                                    errorText={modalForm.errors.about}
                                    placeholder={"Enter About"}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <FormField
                                            id="firstName"
                                            name="firstName"
                                            fieldType="firstName"
                                            label={"Full name"}
                                            value={modalForm.values.firstName}
                                            onChange={(e) => handleFormikChange(e, modalForm)}
                                            showError={modalForm.touched.firstName}
                                            errorText={modalForm.errors.firstName}
                                            placeholder={"Enter First Name"}
                                        />
                                    </Col>
                                    <Col>
                                        <FormField
                                            id="lastName"
                                            name="lastName"
                                            fieldType="lastName"
                                            label={"Last name"}
                                            value={modalForm.values.lastName}
                                            onChange={(e) => handleFormikChange(e, modalForm)}
                                            showError={modalForm.touched.lastName}
                                            errorText={modalForm.errors.lastName}
                                            placeholder={"Enter Last Name"}
                                        />
                                    </Col>
                                </Row>

                                <TelephoneField
                                    id="mobileNo"
                                    name="mobileNo"
                                    fieldType="mobileNo"
                                    label={"Mobile No."}
                                    value={modalForm.values.mobileNo}
                                    showError={modalForm.touched.mobileNo}
                                    onChange={(mobileNo) =>
                                        handleFormikChange(mobileNo, modalForm, "mobileNo")
                                    }
                                    errorText={modalForm.errors.mobileNo}
                                />

                                <FormField
                                    id="email"
                                    name="email"
                                    fieldType="email"
                                    label={"Email"}
                                    value={modalForm.values.email}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.email}
                                    errorText={modalForm.errors.email}
                                    placeholder={"Enter Email Address"}
                                />

                                <FormFieldSelect
                                    id="gender"
                                    name="gender"
                                    label={"Gender"}
                                    options={[
                                        { _id: "male", name: "Male" },
                                        { _id: "female", name: "Female" }
                                    ]}
                                    value={modalForm.values.gender}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.gender}
                                    errorText={modalForm.errors.gender}
                                    placeholder={"Select Gender"}
                                />

                                <DateFormField
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    label={"Date of Birth"}
                                    value={modalForm.values.dateOfBirth}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.dateOfBirth}
                                    errorText={modalForm.errors.dateOfBirth}
                                    placeholder={"Select Date of Birth"}
                                />
                            </Col>
                            <Col>

                                <FormField
                                    id="highestQualification"
                                    name="highestQualification"
                                    fieldType="highestQualification"
                                    label={"Highest Qualification"}
                                    value={modalForm.values.highestQualification}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.highestQualification}
                                    errorText={modalForm.errors.highestQualification}
                                    placeholder={"Enter Highest Qualification"}
                                />

                                <FormField
                                    id="experience"
                                    name="experience"
                                    label={"Experience"}
                                    value={modalForm.values.experience}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.experience}
                                    errorText={modalForm.errors.experience}
                                    placeholder={"Enter Experience"}
                                />

                                <FormField
                                    id="speciality"
                                    name="speciality"
                                    label={"Speciality"}
                                    value={modalForm.values.speciality}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.speciality}
                                    errorText={modalForm.errors.speciality}
                                    placeholder={"Enter Speciality"}
                                />

                                <FormField
                                    id="symptoms"
                                    name="symptoms"
                                    label={"Symptoms"}
                                    value={modalForm.values.symptoms}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.symptoms}
                                    errorText={modalForm.errors.symptoms}
                                    placeholder={"Enter Symptoms"}
                                />

                                <FormField
                                    id="appointmentPrice"
                                    name="appointmentPrice"
                                    label={"Appointment Price"}
                                    value={modalForm.values.appointmentPrice}
                                    onChange={(e) => handleFormikChange(e, modalForm)}
                                    showError={modalForm.touched.appointmentPrice}
                                    errorText={modalForm.errors.appointmentPrice}
                                    placeholder={"Enter Appointment Price"}
                                />

                            </Col>

                           

                        </Row>


                        <div className="theme-formbtn text-end">
                            <ButtonElement
                                variant="primary"
                                type="submit"
                                disabled={showingLoading}
                            >
                                Save Doctor
                            </ButtonElement>
                        </div>

                    </Form>

                </Modal.Body>
            </Modal>

        </>
    )
}

export default DoctorFormModal;