import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const getGoogleLoginLink = () => {
  return sendRequest(endpoint.auth.GOOGLE_LOGIN_URL);
};

export const loginWithGoogleCode = (payload: any) => {
  return sendRequest(endpoint.auth.GOOGLE_LOGIN_CALLBACK, "POST", payload);
};

export const loginWithEmail = (payload: any) => {
  return sendRequest(endpoint.auth.LOGIN_EMAIL, "POST", payload);
};

export const login = (payload: any) => {
  return sendRequest(endpoint.auth.LOGIN, "POST", payload);
};

export const register = (payload: any) => {
  return sendRequest(endpoint.auth.REGISTER, "POST", payload);
};

export const registerDoctor = (payload: any) => {
  return sendRequest(endpoint.auth.REGISTER_DOCTOR, "POST", payload);
};

export const forgotPassword = (payload: any) => {
  return sendRequest(endpoint.auth.FORGOT_PASSWORD, "POST", payload);
};

export const resetPassword = (payload: any) => {  
  return sendRequest(endpoint.auth.RESET_PASSWORD, "POST", payload);
};

export const activateAccount = (payload: any) => {  
  return sendRequest(endpoint.auth.ACTIVATE_ACCOUNT, "POST", payload);
};

export const checkAccount = (payload: any) => {
  return sendRequest(endpoint.auth.CHECK_ACCOUNT, "POST", payload);
};