import { Col, Container, Row } from "react-bootstrap";
import { FiEdit2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import ButtonLinkElement from "../../Components/Common/ButtonLinkElement";
import { withBackendUrl } from "../../Utils/helpers";

const Profile = (props) => {

  const { user } = useSelector((state: any) => state.auth);

  return (
    <>

      <section id="profile" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>Profile</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={12} className="mx-auto">
              <div className="profile-info-box">
                <Row>
                  <Col>
                    <div className="big-img-box center">
                      <div className="img-box" style={{ backgroundImage: 'url("'+withBackendUrl(user?.profileImage)+'")' }}></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="user-list-info">
                      <ul>
                        <li>  
                          <h2>{user?.firstName} {user?.lastName}</h2>
                          <ButtonLinkElement to="/edit-profile"> <FiEdit2/> Edit Profile</ButtonLinkElement>
                        </li>
                        <li>
                          <p>Email:</p>
                          <h5>{user?.email}</h5>
                        </li>
                        <li>
                          <p>Phone No:</p>
                          <h5>{user?.mobileNo}</h5>
                        </li>
                        <li>
                          <p>Gender:</p>
                          <h5>{user?.gender}</h5>
                        </li>
                        <li>
                          <p>Date of Birth::</p>
                          <h5>{user?.dateOfBirth}</h5>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

        </Container>
      </section>

    </>
  );
};

export default Profile;
