import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from '../../Components/Common/Pagination';
import NoResultsFound from '../../Components/Common/NoResultsFound';
import ContentLoader from '../../Components/Loader/ContentLoader';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { initialPaginationData } from '../../Utils/helpers';
import { showErrorToast } from '../../Redux/slices/toastSlice';
import AdminAppointmentItem from '../../Components/Appointment/AdminAppointmentItem';
import { getAllAppointments } from '../../Services/admin-service';

const AdminAppointments = () => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm]: any = useState("");

  const loadData = (page) => {
    setLoading(true);
    getAllAppointments({
      page,
      type: 'upcoming'
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  return (
    <>

      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Appointments</h1>
              </div>
            </Col>
          </Row>

          {
            loading ? <>
              <ContentLoader />
            </> : <>

              <div className="grid-table">
                <Row className="grid-table-head">
                  <Col lg={5} md={12}>
                    <Row>
                      <Col className="grid-table-head-column" lg={6} md={12}>
                        <h6>Patient</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={6} md={12}>
                        <h6>Doctor</h6>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={7} md={12}>
                    <Row>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Date and Time</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Patient Type</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Prescription</h6>
                      </Col>
                      <Col className="grid-table-head-column" lg={3} md={6}>
                        <h6>Actions</h6>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="grid-table-body">
                  {
                    paginationData?.docs && paginationData?.docs.length > 0 ? <>
                      {paginationData?.docs?.map((data: any, index) => (
                        <Col lg={12} md={12} className="grid-table-item">
                          <AdminAppointmentItem onRefresh={() => loadData(page)} appointment={data} />
                        </Col>
                      ))}
                    </> : <>
                      <NoResultsFound />
                    </>
                  }
                </Row>
              </div>

              <Row>
                <Col>
                  {
                    paginationData?.docs && paginationData?.docs.length > 0 ? <>
                      <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                    </> : null
                  }
                </Col>
              </Row>

            </>
          }

        </div>
      </div>

    </>
  );

}

export default AdminAppointments;