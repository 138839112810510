import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppointmentsList from "../../Components/Site/Appointments/List";
import PreviousList from "../../Components/Site/Appointments/PreviousList";
import AppointmentsTodayList from "../../Components/Site/Appointments/TodayList";


const Appointments = (props) => {

  const [activeTab, setActiveTab] = useState("upcoming");

  return (
    <>

      <section id="appointments-title" className="section-pad pb-0">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>Appointments</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="theme-tabs">
                <ul>
                  <li>
                    <a onClick={(e) => setActiveTab("upcoming")} className={`${activeTab == "upcoming" ? 'active' : ''}`} href={undefined}>Upcoming</a>
                  </li>
                  <li>
                    <a onClick={(e) => setActiveTab("previous")} className={`${activeTab == "previous" ? 'active' : ''}`} href={undefined}>Previous</a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

        </Container>
      </section>

      {
        activeTab === "upcoming" ? <>
          <section id="today-appointments" className="section-pad pb-0">
            <Container>

              <Row>
                <Col>
                  <div className="section-title section-title-4 left">
                    <h1>Today</h1>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mx-auto">
                  <AppointmentsTodayList />
                </Col>
              </Row>

            </Container>
          </section>

          <section id="appointments" className="section-pad">
            <Container>

              <Row>
                <Col>
                  <div className="section-title section-title-4 left">
                    <h1>Upcoming</h1>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mx-auto">
                  <AppointmentsList />
                </Col>
              </Row>

            </Container>
          </section>
        </> : <>

          <section id="today-appointments" className="section-pad pb-0">
            <Container>

              <Row>
                <Col>
                  <div className="section-title section-title-4 left">
                    <h1>Previous</h1>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mx-auto mb-5">
                  <PreviousList />
                </Col> 
              </Row>

            </Container>
          </section>

        </>
      }

    </>
  );
};

export default Appointments;
