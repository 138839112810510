
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppointmentConstants } from "../../Constants/appointment";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { cancelAppointment, submitAppointmentRating, submitErrorReason } from "../../Services/appointment-service";
import { haveValue, printAmount, printTime, withBackendUrl } from "../../Utils/helpers";
import ButtonElement from "../Common/ButtonElement";
import ButtonLinkElement from "../Common/ButtonLinkElement";
import AppointmentFeedbackModal from "../Common/Modals/AppointmentFeedbackModal";
import CancelAppointmentModal from "../Common/Modals/CancelAppointmentModal";
import ErrorReasonAppointmentModal from "../Common/Modals/ErrorReasonAppointmentModal";
import ViewAppointmentFeedback from "../Common/Modals/ViewAppointmentFeedback";
import ViewCancelAppointmentReason from "../Common/Modals/ViewCancelAppointmentReason";
import DoctorBox2 from "../Site/Doctors/DoctorBox2";
import AppointmentPrescriptionModal from "../Common/Modals/AppointmentPrescriptionModal";

const PatientAppointmentItem = (props) => {

    const { appointment } = props;
    const dispatch = useDispatch();

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [viewCancelReasonModal, setViewCancelReasonModal] = useState(false);
    const [viewAppointmentFeedbackModal, setViewAppointmentFeedbackModal] = useState(false);
    const [selectedAppointment, setSelectedAppointment]: any = useState(null);

    const cancel = (appointment) => {
        setShowCancelModal(true);
        setSelectedAppointment(appointment);
    }

    const feedback = (appointment) => {
        setShowFeedbackModal(true);
        setSelectedAppointment(appointment);
    }

    const shareReason = (appointment) => {
        setShowErrorModal(true);
        setSelectedAppointment(appointment);
    }

    const showCancelReason = (appointment) => {
        setViewCancelReasonModal(true);
        setSelectedAppointment(appointment);
    }

    const showFeedback = (appointment) => {
        setViewAppointmentFeedbackModal(true);
        setSelectedAppointment(appointment);
    }

    const doSaveReason = (formValues: any) => {
        dispatch(showLoadingToast(null));
        submitErrorReason({
            ...formValues,
            appointmentId: selectedAppointment?._id
        }).then((res: any) => {
            dispatch(showSuccessToast(res?.message));
            setShowErrorModal(false);
            props?.onRefresh();
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    const doCancel = (formValues: any) => {
        dispatch(showLoadingToast(null));
        cancelAppointment({
            ...formValues,
            appointmentId: selectedAppointment?._id
        }).then((res: any) => {
            dispatch(showSuccessToast(res?.message));
            setShowCancelModal(false);
            props?.onRefresh();
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    const doFeedback = (formValues: any) => {
        dispatch(showLoadingToast(null));
        submitAppointmentRating({
            ...formValues,
            appointmentId: selectedAppointment?._id
        }).then((res: any) => {
            dispatch(showSuccessToast(res?.message));
            setShowFeedbackModal(false);
            props?.onRefresh();
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    return (
        <>
            <div className="grid-table-row">
                <Row >
                    <Col className="grid-table-data-column" lg={3} md={12}>
                        <DoctorBox2 doctor={appointment?.doctor} />
                    </Col>
                    <Col lg={9} md={12}>
                        <Row>
                            <Col className="grid-table-data-column" lg={4} md={12}>
                                <p>{printTime(appointment?.start)}</p>
                                <p>Status: <span className={`appointment-status bold-title ${appointment?.status}`}>{appointment?.status}</span></p>
                            </Col>
                            <Col className="grid-table-data-column" lg={4} md={12}>
                                {/* <p>{appointment?.doctor?.experience}</p> */}
                                {
                                    haveValue(appointment?.prescription) ? <>
                                        <a className="text-success" target="_blank" href={withBackendUrl(`public/prescription/${appointment?._id}`)}>View Prescription</a>
                                    </> : <>
                                        <span>No Prescription Added Yet</span>
                                    </>
                                }
                            </Col>
                            <Col className="grid-table-data-column" lg={4} md={12}>
                                <div className="data-n-actions">
                                    <p className="me-3">{printAmount(appointment?.amount)}</p>
                                    <div className="data-n-action-btns">
                                        {
                                            (appointment.status === AppointmentConstants.STATUS.ongoing || appointment.status === AppointmentConstants.STATUS.scheduled) ? <>
                                                <ButtonLinkElement to={`/join-appointment/${appointment?._id}`} variant="primary" text="Join" />
                                            </> : null
                                        }
                                        {
                                            appointment.status === AppointmentConstants.STATUS.cancelled ? <>
                                                <ButtonElement onClick={(e) => showCancelReason(appointment)} variant="outline-danger" text="View Reason" />
                                            </> : null
                                        }
                                        {
                                            appointment.status === AppointmentConstants.STATUS.scheduled ? <>
                                                <ButtonElement onClick={(e) => cancel(appointment)} variant="outline-danger" text="Cancel" />
                                            </> : null
                                        }

                                        {
                                            appointment.status === AppointmentConstants.STATUS.completed ? <>
                                                {
                                                    haveValue(appointment.userJoinedAt) ? <>
                                                        {
                                                            (!haveValue(appointment?.ratingByPatient?.rating)) ? <>
                                                                <ButtonElement onClick={(e) => feedback(appointment)} variant="outline-primary" text="Send Feedback" />
                                                            </> : null
                                                        }
                                                        {
                                                            (haveValue(appointment?.ratingByPatient?.rating)) ? <>
                                                                <ButtonElement onClick={(e) => showFeedback(appointment)} variant="outline-primary" text="View Feedback" />
                                                            </> : null
                                                        }
                                                    </> : <>
                                                        <div className="no-join-error">
                                                            <p>You were not able to Join</p>
                                                            {
                                                                (!haveValue(appointment?.userErrorReason)) ? <>
                                                                    <ButtonElement onClick={(e) => shareReason(appointment)} variant="outline-warning" text="Share Reason" />
                                                                </> : null
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </> : null
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <ErrorReasonAppointmentModal onConfirm={doSaveReason} showModal={showErrorModal} closeModal={(e) => setShowErrorModal(false)} />

            <CancelAppointmentModal onConfirm={doCancel} showModal={showCancelModal} closeModal={(e) => setShowCancelModal(false)} />
            <AppointmentFeedbackModal onConfirm={doFeedback} showModal={showFeedbackModal} closeModal={(e) => setShowFeedbackModal(false)} />

            <ViewCancelAppointmentReason appointment={selectedAppointment} showModal={viewCancelReasonModal} closeModal={(e) => setViewCancelReasonModal(false)} />
            <ViewAppointmentFeedback appointment={selectedAppointment} showModal={viewAppointmentFeedbackModal} closeModal={(e) => setViewAppointmentFeedbackModal(false)} />

        
        </>
    );
};

export default PatientAppointmentItem;
