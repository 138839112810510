import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "../../Components/Common/SearchBar";
import Pharmacies from "../../Components/Site/Pharmacy/Pharmacies";

const Pharmacy = (props) => {

  return (
    <>

      <section id="pharmacy" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>Pharmacy</h1>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col md={12} lg={12}>
              <SearchBar placeholder="Search medicine here..." />
            </Col>
          </Row> */}

          <Row>
            <Col>
              <Pharmacies />
            </Col>
          </Row>

        </Container>
      </section>

    </>
  );
};

export default Pharmacy;
