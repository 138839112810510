import React from "react";
import { FiSearch } from "react-icons/fi";
import Form from "react-bootstrap/Form";

const SearchBar = (props) => {
  // Prop Destructuring
  const { searchTerm = "", setSearchTerm = () => { } } = props;

  return (
    <>
      <div className={`searchbar ${props?.className ? props?.className : ''}`}>
        <FiSearch />
        <Form.Control
          {...props}
          size={props.size}
          type="text"
          placeholder={props.placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </>
  );
};

export default SearchBar;
