import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import FormFieldSelect from '../../Components/FormFields/FormFieldSelect';
import ButtonElement from '../../Components/Common/ButtonElement';
import SearchBar from '../../Components/Common/SearchBar';
import DoctorBox2 from '../../Components/Site/Doctors/DoctorBox2';
import { useEffect, useState } from 'react';
import { deleteCorporate, deletePatient, getCorporates, markCorporateActive, markCorporateInActive } from '../../Services/admin-service';
import { haveValue, initialPaginationData, printFullName, printTime } from '../../Utils/helpers';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../Components/Common/Pagination';
import NoTabRow from '../../Components/Common/NoTabRow';
import CorporateFormModal from '../../Components/Modal/CorporateFormModal';
import { Link } from 'react-router-dom';
import { FiCalendar, FiUsers } from 'react-icons/fi';

const AdminCorporates = () => {

  const dispatch = useDispatch();

  const [modelData, setModelData]: any = useState(null);
  const [searchTerm, setSearchTerm]: any = useState("");
  const [sortType, setSortType]: any = useState("newest");

  const [showModal, setShowModel]: any = useState(false);
  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);

  const addModalOpen = () => {
    setModelData(null);
    setShowModel(true);
  }

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getCorporates({
      page,
      search: searchTerm,
      sort: sortType
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, sortType, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  const deleteIt = (data) => {
    deleteCorporate({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Deleted successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to delete"));
    });
  }

  const closeModal = () => {
    setShowModel(false);
    loadData(page);
    setModelData(null);
  }

  const handleEdit = (data) => {
    setModelData(data);
    setShowModel(true);
  }

  //  markInActive markActive

  const markInActive = (data) => {
    markCorporateInActive({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Marked as inactive successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to complete your request"));
    });
  }

  const markActive = (data) => {
    markCorporateActive({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Marked as active successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to complete your request"));
    });
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Corporates</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="table-head-options">
                <div className='left'>
                  <FormFieldSelect
                    id="type"
                    name="type"
                    label={false}
                    onChange={(e) => setSortType(e?.target?.value)}
                    options={[
                      { _id: "newest", name: "Newest" },
                      { _id: "oldest", name: "Oldest" },
                    ]}
                    value={sortType}
                    placeholder={"Select Type"}
                  />
                </div>
                <div className='right'>
                  <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  <ButtonElement onClick={addModalOpen} className="ms-2" variant="primary" >
                    Add Corporate
                  </ButtonElement>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Address</th>
                      <th>Doctors</th>
                      <th>Mobile No.</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paginationData?.docs && paginationData?.docs.length > 0 ? <>
                        {paginationData?.docs?.map((data: any, index) => (
                          <tr key={index}>
                            <td>{data?.name}</td>
                            <td>{data?.type}</td>
                            <td>{data?.address}</td>
                            <td>
                              {
                                haveValue(data?.doctors) ? <>
                                  {
                                    data?.doctors.map((doctor) => {
                                      return <>
                                        <p>{printFullName(doctor)} ({doctor?.speciality})</p>
                                      </>
                                    })
                                  }
                                </> : null
                              }
                            </td>
                            <td>{data?.user?.mobileNo}</td>
                            <td>{data?.user?.email}</td>
                            <td>
                              <div className='table-actions'>
                                {
                                  data?.isActive.toString() === "true" ? <>
                                    <p>
                                      <a href="javascript:;" onClick={(e) => markInActive(data)}><AiOutlineDelete /> Mark In-active</a>
                                    </p>
                                  </> : <>
                                    <p>
                                      <a href="javascript:;" onClick={(e) => markActive(data)}><BiCheck /> Mark Active</a>

                                    </p>
                                  </>
                                }
                              </div>
                            </td>
                            <td>
                              <div className='table-actions'>
                                <Link to={`/admin/corporate/${data?._id}`}><FiUsers /></Link>
                                <Link to={`/admin/report/${data?._id}`}><FiCalendar /></Link>
                                <a onClick={(e) => handleEdit(data)} href={undefined}><BsPencil /></a>
                                <a onClick={(e) => deleteIt(data)} href={undefined} className="danger"><AiOutlineDelete /></a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </> : <>
                        <NoTabRow colSpan={5} />
                      </>
                    }

                  </tbody>
                </table>
              </div>
            </Col>

            <Col md={12}>
              {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                  <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                </> : null
              }
            </Col>

          </Row>

        </div>
      </div>

      <CorporateFormModal modelData={modelData} onModalHide={(e) => closeModal()} showModal={showModal} />

    </>
  )
}

export default AdminCorporates;