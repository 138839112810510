import { endpoint } from "../Config/endpoints";
import { objectToFormData } from "../Utils/helpers";
import { sendRequest } from "./http-service";

export const updateProfile = (payload: any) => {
  return sendRequest(endpoint.user.UPDATE_PROFILE, "POST", payload);
};

export const updateUserRole = (payload: any) => {
  return sendRequest(endpoint.user.UPDATE_ROLE, "POST", payload);
};

export const updatePreference = (payload: any) => {
  return sendRequest(endpoint.user.UPDATE_PREFERENCE, "POST", payload);
};

export const saveSlots = (payload: any) => {
  return sendRequest(endpoint.user.CREATE_SLOTS, "POST", payload);
};

export const deleteSlots = (payload: any) => {
  return sendRequest(endpoint.user.DELETE_SLOTS, "POST", payload);
};

export const bookAppointment = (payload: any) => {
  return sendRequest(endpoint.user.BOOK_APPOINTMENT, "POST", payload);
};

export const confirmPayment = (payload: any) => {
  return sendRequest(endpoint.user.CONFIRM_PAYMENT, "POST", payload);
};

export const getAppointments = (payload: any) => {
  return sendRequest(endpoint.user.APPOINTMENTS, "GET", payload);
};
