import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DoctorAppointmentsList from '../../Components/Site/Appointments/DoctorList';

const DoctorAppointments = () => {

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Upcoming Appointments</h1>
              </div>
            </Col>
          </Row>


          <DoctorAppointmentsList />

        </div>
      </div>
    </>
  )
}

export default DoctorAppointments;