import React from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import FormField from "../FormFields/FormField";

import useTranslator from "./../../Hooks/useTranslator";
import { handleFormikChange, handleFormikSubmit, haveValue } from "../../Utils/helpers";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast,
  showSuccessToast,
} from "../../Redux/slices/toastSlice";
import { forgotPassword } from "../../Services/auth-service";

const ForgotPasswordForm = (props: any) => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(_t("enterValidEmail"))
      .required(_t("emailIsRequired")),
  });

  const forgotForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(showLoadingToast(_t("sendingVerification")));
      forgotPassword(values)
        .then((res: any) => {
          dispatch(showSuccessToast(res?.message));
          // setTimeout(() => navigate("/resetpassword"), 2000);
        })
        .catch((err: any) => dispatch(showErrorToast(err?.message)))
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    },
  });

  return (
    <>
      <Form onSubmit={(e) => handleFormikSubmit(e, forgotForm)}>
        <FormField
          id="email"
          name="email"
          fieldType="email"
          label={_t("email")}
          value={forgotForm.values.email}
          onChange={(e) => handleFormikChange(e, forgotForm)}
          showError={forgotForm.touched.email}
          errorText={forgotForm.errors.email}
          placeholder={_t("emailPlaceholder")}
        />
        <div className="theme-formbtn">
          <Button variant="primary" type="submit">
            {_t("sendVerificationLink")}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
