import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import HeaderTopBar from "./HeaderTopBar";
import HeaderNav from "./HeaderNav";
  
const Header = (props) => {

  return (
    <>
      <div className="site-header">    
        <HeaderTopBar />
        <HeaderNav />
      </div>
    </>
  );
};

export default Header;
