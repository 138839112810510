import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RiDashboardLine } from 'react-icons/ri';
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../../Redux/slices/authSlice';
import { NavDropdown } from 'react-bootstrap';
import { LuUsers } from 'react-icons/lu';
import { BiCalendar, BiCalendarCheck, BiChevronRight } from 'react-icons/bi';
import { withBackendUrl } from '../../../Utils/helpers';
import { MdLogout } from 'react-icons/md';

const Sidebar = () => {

    const dispatch = useDispatch();
    const { user } = useSelector((state: any) => state.auth);

    const handleLogout = () => {
        dispatch(logout(null));
    }

    return (
        <>
            <div className="sidebar-panel">
                <div className="sidebarhead">
                    <Link to="/" className="logobar">
                        <img src="/images/logo-hd.png" alt="logo" />
                    </Link>
                    <ul>
                        <li>
                            <NavLink to="/doctor/profile">
                                <div className="user-profile-name">
                                    <div className="img" style={{ backgroundImage: 'url("' + withBackendUrl(user?.profileImage) + '")' }}></div>
                                    <div>
                                        <h4>{user?.firstName} {user?.lastName}</h4>
                                    </div>
                                    <BiChevronRight />
                                </div>
                            </NavLink>
                        </li>
                        <li className="divider"></li>
                        <li>
                            <NavLink to='/doctor/index'>
                                <RiDashboardLine size={20} />
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/doctor/appointments'>
                                <BiCalendar size={20} />
                                Appointments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/doctor/previous-appointments'>
                                <BiCalendarCheck size={20} />
                                Previous Appointments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/doctor/patients'>
                                <LuUsers size={20} />
                                Patients
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleLogout} to='/'>
                                <MdLogout size={20} />
                                Logout
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>


        </>
    )
}

export default Sidebar