import { Dropdown } from "react-bootstrap";
import { AiOutlineCalendar, AiOutlineDashboard, AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserConstants } from "../../../Constants/user";
import { useAppContext } from "../../../Hooks/useAppContext";
import { logout } from "../../../Redux/slices/authSlice";
import { withBackendUrl } from "../../../Utils/helpers";

const UserDropdown = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentRole }: any = useAppContext();
    const { user } = useSelector((state: any) => state.auth);

    const handleLogout = () => {
        dispatch(logout(null));
    }

    return (
        <>
            <Dropdown className="user-dropdown ms-3">
                <Dropdown.Toggle id="user-dropdown-toggle">
                    <div className="img-box" style={{ backgroundImage: 'url(' + withBackendUrl(user?.profileImage) + ')' }}></div>
                </Dropdown.Toggle>
                <Dropdown.Menu>

                    {currentRole === UserConstants.ROLES.patient ? (
                        <>
                            <Dropdown.Item onClick={(e) => navigate("/appointments")}>
                                <span><AiOutlineCalendar /></span>
                                <span>Appointments</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => navigate("/profile")}>
                                <span><AiOutlineUser /></span>
                                <span>View Profile</span>
                            </Dropdown.Item>
                        </>
                    ) : null}

                    {currentRole === UserConstants.ROLES.doctor ? (
                        <>
                            <Dropdown.Item onClick={(e) => navigate("/doctor/index")}>
                                <span><AiOutlineDashboard /></span>
                                <span>Dashboard</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => navigate("/doctor/profile")}>
                                <span><AiOutlineUser /></span>
                                <span>View Profile</span>
                            </Dropdown.Item>
                        </>
                    ) : null}

                    {currentRole === UserConstants.ROLES.admin ? (
                        <>
                            <Dropdown.Item onClick={(e) => navigate("/admin/dashboard")}>
                                <span><AiOutlineDashboard /></span>
                                <span>Dashboard</span>
                            </Dropdown.Item>
                        </>
                    ) : null}

                    <Dropdown.Item onClick={(e) => handleLogout()}>
                        <span><AiOutlineLogout /></span>
                        <span>Logout</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        </>
    );
};

export default UserDropdown;
