import ReactPaginate from "react-paginate";

function Pagination(props) {

    const { paginationData } = props;

    const handlePageClick = (event) => {
        if (props.hasOwnProperty('onPageChange')) {
            props?.onPageChange((event.selected + 1));
        }
    };


    return (
        <>
            {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                    <div className="pagination-wrapper">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            forcePage={paginationData?.page - 1}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={paginationData?.totalPages}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </> : null
            }
        </>
    );
}

export default Pagination;
