import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../Redux/slices/toastSlice";
import { deleteBlog } from "../../../Services/blogs-service";
import { printTime, withBackendUrl } from "../../../Utils/helpers";

const BlogItem = (props) => {

    const { actions, blog } = props;

    const dispatch = useDispatch();

    const deleteItem = () => {
        if (window.confirm("Are you sure you want to delete?") === true) {
            deleteBlog(blog?._id).then((res) => {
                dispatch(showSuccessToast("Blog has been deleted"));
                props?.onRefresh();
            }).catch((err) => {
                dispatch(showErrorToast("Failed to delete Blog"));
            });
        }
    }

    return (
        <>

            <div className="blog-box">
                {
                    actions ? <>
                        <div className='item-actions'>
                            <a href={undefined} onClick={(e) => props?.handleEdit(blog)}><BsPencil /></a>
                            <a href={undefined} onClick={deleteItem} className="danger"><AiOutlineDelete /></a>
                        </div>
                    </> : null
                }
                <Row>
                    <Col md={12} lg={6} className="img-content">
                        <Link to={`/blog/${blog._id}`} className="img-box" style={{ backgroundImage: `url("${withBackendUrl(blog?.imgUrl)}")` }}></Link>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="content">
                            <div className="title">
                                <h1>
                                    <Link to={`/blog/${blog._id}`}>{blog?.title}</Link>
                                </h1>
                                <p>By {blog?.author}  |  {printTime(blog?.createdAt)}</p>
                            </div>
                            <div className="info">
                                {blog?.body.toString().replace(/<[^>]*>?/gm, '').substring(0, 750)}...
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    );
};

export default BlogItem;
