import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import { handleFormikChange, handleFormikSubmit, printFullName, printTime } from "../../../Utils/helpers";
import FormField from "../../FormFields/FormField";
import { useDispatch } from "react-redux";
import ButtonElement from "../ButtonElement";
import { useFormik } from "formik";
import * as yup from "yup";
import FormFieldArea from "../../FormFields/FormFieldArea";

function ViewCancelAppointmentReason(props) {

  const { appointment } = props;

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        className=""
        size="lg"
      >
        <Modal.Header closeButton>Appointment Cancelled</Modal.Header>
        <Modal.Body>

            <h6>Cancel Reason</h6>
            <p>{appointment?.cancelledReason}</p>
            <br/>
            <p>Cancelled On: <strong>{printTime(appointment?.cancelledAt)}</strong></p>
            <p>Cancelled By: <strong>{printFullName(appointment?.cancelledBy)}</strong></p>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewCancelAppointmentReason;
