import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const getConfigurations = () => {
  return sendRequest(endpoint.public.CONFIGURATIONS);
};

export const getBlogs = (payload) => {
  return sendRequest(endpoint.public.BLOGS, 'GET', payload);
};

export const getBlogDetails = (id) => {
  return sendRequest(endpoint.public.BLOG_DETAILS + "/" + id);
};

export const getTestimonials = () => {
  return sendRequest(endpoint.public.TESTIMONIALS);
};

export const getFaqs = () => {
  return sendRequest(endpoint.public.FAQ);
};

export const getAllAbouts = () => {
  return sendRequest(endpoint.public.ALL_ABOUTS);
};

export const getBannerImage = () => {
  return sendRequest(endpoint.public.BANNER_IMG);
};

export const getDoctors = (payload: any = {}) => {
  return sendRequest(endpoint.public.DOCTORS, 'GET', payload);
};

export const getDoctor = (payload) => {
  return sendRequest(endpoint.public.DOCTOR, 'GET', payload);
};

export const getAvailableSlots = (payload) => {
  return sendRequest(endpoint.public.AVAILABLE_SLOTS, 'GET', payload);
};

export const submitContact = (payload) => {
  return sendRequest(endpoint.public.CONTACT, 'POST', payload);
};