export const RatingConstants = {
  TYPE: {
    veryPoor: "veryPoor",
    poor: "poor",
    average: "average",
    good: "good",
    veryGood: "veryGood",
    excellent: "excellent"
  },
  STARS: {
    veryPoor: 0,
    poor: 1,
    average: 2,
    good: 3,
    veryGood: 4,
    excellent: 5
  },
};   
