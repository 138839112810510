import React from 'react';
import ForgotPasswordForm from '../../Components/Auth/ForgotPasswordForm';
import useTranslator from '../../Hooks/useTranslator';

const ForgotPassword = (props: any) => {

    const { _t } = useTranslator();

    return (
        <>
            <div className="formtitlebar">
                <h4>{_t("forgotPasswordTitle")}</h4>
                <p>{_t("forgotPasswordSubTitle")}</p>
            </div>
            <ForgotPasswordForm />
        </>
    );
}

export default ForgotPassword;