import { Col, Container, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

const AuthLayout = () => {


    return (
        <>
            <div className="auth-layout">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={12} lg={10} className="mx-auto">
                            <div className="auth-box">
                                <Row>
                                    <Col lg={6} md={12}>
                                        <div className="banner">
                                            <Link to="/">
                                                <img src='/images/logo-hd.png' alt='Joyfullmind' />
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12}>
                                        <div className="auth-form">
                                            <Outlet />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AuthLayout;