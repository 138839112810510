import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfigurations } from "../Redux/slices/configurationsSlice";
import { showErrorToast } from "../Redux/slices/toastSlice";
import { getConfigurations } from "../Services/public-service";
import { haveValue } from "../Utils/helpers";
import usePersistedState from "./usePersistedState";
import useTranslator from "./useTranslator";


const AppContext = createContext({});

export function AppProvider({ children }: any) {

  const { _t } = useTranslator();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state: any) => state.auth);

  const [darkMode, setDarkMode] = usePersistedState("darkMode", false);
  const [currentRole, setCurrentRole] = useState(null);

  useEffect(() => {
    if (isAuthenticated && haveValue(user?.role)) {
      if (haveValue(user?.preference?.currentRole)) {
        setCurrentRole(user?.preference?.currentRole);
      }
    } else {
      setCurrentRole(null);
    }
  }, [isAuthenticated, user]);

  const loadAppConfigurations = () => {
    getConfigurations().then((res: any) => {
      dispatch(updateConfigurations(res?.data));
    }).catch((err: any) => {
      dispatch(showErrorToast(_t("failedToLoadConfigurations")));
    });
  }

  return (
    <AppContext.Provider value={{ darkMode, setDarkMode, currentRole, loadAppConfigurations }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
