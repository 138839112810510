import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";


export const addDoctor = (payload: any = {}) => {
    return sendRequest(endpoint.admin.addDoctor, "POST", payload);
};

export const editDoctor = (payload: any = {}) => {
    return sendRequest(endpoint.admin.editDoctor, "POST", payload);
};

export const deleteDoctor = (payload: any = {}) => {
    return sendRequest(endpoint.admin.deleteDoctor, "POST", payload);
};

export const markDoctorApproved = (payload: any = {}) => {
    return sendRequest(endpoint.admin.approveDoctor, "POST", payload);
};

export const markDoctorRejected = (payload: any = {}) => {
    return sendRequest(endpoint.admin.rejectDoctor, "POST", payload);
};

export const getDoctors = (payload: any = {}) => {
    return sendRequest(endpoint.admin.doctors, "GET", payload);
};

export const addPatient = (payload: any = {}) => {
    return sendRequest(endpoint.admin.addPatient, "POST", payload);
};

export const editPatient = (payload: any = {}) => {
    return sendRequest(endpoint.admin.editPatient, "POST", payload);
};

export const deletePatient = (payload: any = {}) => {
    return sendRequest(endpoint.admin.deletePatient, "POST", payload);
};

export const getAllAppointments = (payload: any = {}) => {
    return sendRequest(endpoint.admin.appointments, "GET", payload);
};

export const getPatients = (payload: any = {}) => {
    return sendRequest(endpoint.admin.patients, "GET", payload);
};

export const addCorporate = (payload: any = {}) => {
    return sendRequest(endpoint.admin.addCorporate, "POST", payload);
};

export const editCorporate = (payload: any = {}) => {
    return sendRequest(endpoint.admin.editCorporate, "POST", payload);
};

export const deleteCorporate = (payload: any = {}) => {
    return sendRequest(endpoint.admin.deleteCorporate, "POST", payload);
};

export const getCorporates = (payload: any = {}) => {
    return sendRequest(endpoint.admin.corporates, "GET", payload);
};

export const getCorporate = (payload: any = {}) => {
    return sendRequest(endpoint.admin.corporate, "GET", payload);
};

export const addPatientsToCorporates = (payload: any = {}) => {
    return sendRequest(endpoint.admin.addPatientsToCorporate, "POST", payload);
};

export const removePatientFromCorporate = (payload: any = {}) => {
    return sendRequest(endpoint.admin.removePatientFromCorporate, "POST", payload);
};

export const markCorporateActive = (payload: any = {}) => {
    return sendRequest(endpoint.admin.activateCorporate, "POST", payload);
};

export const markCorporateInActive = (payload: any = {}) => {
    return sendRequest(endpoint.admin.deActivateCorporate, "POST", payload);
};