import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";

function NoTabRow(props) {

    return (
        <>
            <tr>
                <td colSpan={props?.colSpan ?? 1} className="text-center">No Results Found</td>
            </tr>
        </>
    );
}

export default NoTabRow;
