import { Col, Row } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";


const AuthBlankLayout = () => {

    const navigate = useNavigate();

    const handlelogo = () => {
        navigate("/home");
    };

    return (
        <>
            <div className="authblanklayout">
                <Row>
                    <Col md={4} className="mx-auto">
                        <div className='singlelogo'>
                            <img onClick={handlelogo} src='/images/logo-hd.png' alt='whitelogo' />
                        </div>
                        <div className="content-area">
                            <Outlet />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AuthBlankLayout;