
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppointmentConstants } from "../../Constants/appointment";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { cancelAppointment, submitAppointmentRating } from "../../Services/appointment-service";
import { haveValue, printAmount, printDate, printTime } from "../../Utils/helpers";
import ButtonElement from "../Common/ButtonElement";
import ButtonLinkElement from "../Common/ButtonLinkElement";
import AppointmentFeedbackModal from "../Common/Modals/AppointmentFeedbackModal";
import CancelAppointmentModal from "../Common/Modals/CancelAppointmentModal";
import ViewAppointmentFeedback from "../Common/Modals/ViewAppointmentFeedback";
import ViewCancelAppointmentReason from "../Common/Modals/ViewCancelAppointmentReason";
import PatientBox from "../Patient/PatientBox";
import DoctorBox2 from "../Site/Doctors/DoctorBox2";

const AdminAppointmentItem = (props) => {

    const { appointment } = props;
    const dispatch = useDispatch();

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [viewCancelReasonModal, setViewCancelReasonModal] = useState(false);
    const [viewAppointmentFeedbackModal, setViewAppointmentFeedbackModal] = useState(false);
    const [selectedAppointment, setSelectedAppointment]: any = useState(null);

    const cancel = (appointment) => {
        setShowCancelModal(true);
        setSelectedAppointment(appointment);
    }

    const feedback = (appointment) => {
        setShowFeedbackModal(true);
        setSelectedAppointment(appointment);
    }

    const showCancelReason = (appointment) => {
        setViewCancelReasonModal(true);
        setSelectedAppointment(appointment);
    }

    const showFeedback = (appointment) => {
        setViewAppointmentFeedbackModal(true);
        setSelectedAppointment(appointment);
    }

    const doCancel = (formValues: any) => {
        dispatch(showLoadingToast(null));
        cancelAppointment({
            ...formValues,
            appointmentId: selectedAppointment?._id
        }).then((res: any) => {
            dispatch(showSuccessToast(res?.message));
            setShowCancelModal(false);
            props?.onRefresh();
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    const doFeedback = (formValues: any) => {
        dispatch(showLoadingToast(null));
        submitAppointmentRating({
            ...formValues,
            appointmentId: selectedAppointment?._id
        }).then((res: any) => {
            dispatch(showSuccessToast(res?.message));
            setShowFeedbackModal(false);
            props?.onRefresh();
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    return (
        <>
            <div className="grid-table-row">
                <Row >
                    <Col lg={5} md={12}>
                        <Row>
                            <Col className="grid-table-data-column" lg={6} md={12}>
                                <PatientBox patient={appointment?.user} />
                            </Col>
                            <Col className="grid-table-data-column" lg={6} md={12}>
                                <DoctorBox2 doctor={appointment?.doctor} />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={7} md={12}>
                        <Row>
                            <Col className="grid-table-data-column" lg={3} md={6}>
                                <p>{printTime(appointment?.start)}</p>
                                <p>Status: <span className={`appointment-status bold-title ${appointment?.status}`}>{appointment?.status}</span></p>
                                <p>Amount: <span className={`appointment-status bold-title`}>{printAmount(appointment?.amount)}</span></p>
                            </Col>
                            <Col className="grid-table-data-column" lg={3} md={6}>
                                <p>-</p>
                            </Col>
                            <Col className="grid-table-data-column" lg={3} md={6}>
                                <p>-</p>
                            </Col>
                            <Col className="grid-table-data-column" lg={3} md={6}>
                                <div className="data-n-actions">
                                    {
                                        appointment.status === AppointmentConstants.STATUS.cancelled ? <>
                                            <ButtonElement onClick={(e) => showCancelReason(appointment)} variant="outline-danger" text="View Reason" />
                                        </> : null
                                    }
                                    <ButtonElement onClick={(e) => showFeedback(appointment)} variant="outline-primary" text="View Feedback" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <CancelAppointmentModal onConfirm={doCancel} showModal={showCancelModal} closeModal={(e) => setShowCancelModal(false)} />
            <AppointmentFeedbackModal onConfirm={doFeedback} showModal={showFeedbackModal} closeModal={(e) => setShowFeedbackModal(false)} />

            <ViewCancelAppointmentReason appointment={selectedAppointment} showModal={viewCancelReasonModal} closeModal={(e) => setViewCancelReasonModal(false)} />
            <ViewAppointmentFeedback appointment={selectedAppointment} showModal={viewAppointmentFeedbackModal} closeModal={(e) => setViewAppointmentFeedbackModal(false)} />

        </>
    );
};

export default AdminAppointmentItem;
