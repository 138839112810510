import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import AppointmentPrescription from "../../Appointment/AppointmentPrescription";

function AppointmentPrescriptionModal(props) {

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        className=""
        size="lg"
      >
        <Modal.Header closeButton>Prescription</Modal.Header>
        <Modal.Body>
          <AppointmentPrescription appointment={props.appointment}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AppointmentPrescriptionModal;
