import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useTranslator from "./../../Hooks/useTranslator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import FormField from "../FormFields/FormField";
import CountryField from "../FormFields/CountryField";
import TelephoneField from "../FormFields/TelephoneField";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast,
  showSuccessToast,
} from "../../Redux/slices/toastSlice";
import { register } from "../../Services/auth-service";
import { handleFormikChange, handleFormikSubmit } from "../../Utils/helpers";
import { Col, Row } from "react-bootstrap";
import CheckboxField from "../FormFields/CheckboxField";
import DateFormField from "../FormFields/DateFormField";
import FormFieldSelect from "../FormFields/FormFieldSelect";
import PasswordField from "../FormFields/PasswordField";

const SignupForm = (props: any) => {
  // Locaization
  const { _t } = useTranslator();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showingLoading } = useSelector((state: any) => state.toast);

  // Prop Destructuring
  const { selectedRole = [] } = props;

  // Mounting
  useEffect(() => { }, []);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(_t("Enter a valid Email"))
      .required(_t("Email is Required")),
    mobileNo: yup.string().required(_t("Mobile no is Required")),
    firstName: yup.string().required(_t("First name is required")),
    lastName: yup.string().required(_t("Last name is required")),
    gender: yup.string().required(_t("Gender is required")),
    dateOfBirth: yup.string().required(_t("DOB is required")),
  });

  const signupForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNo: "",
      email: "",
      gender: "",
      dateOfBirth: "",
      terms: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(showLoadingToast(null));

      const dataToSend = { ...values };

      register(dataToSend)
        .then((res: any) => {
          dispatch(showSuccessToast(res?.message));
          navigate("/login");
        })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    },
  });

  return (
    <>
      <Form onSubmit={(e) => handleFormikSubmit(e, signupForm)}>

        <Row>
          <Col>
            <FormField
              id="firstName"
              name="firstName"
              fieldType="firstName"
              label={"First name"}
              value={signupForm.values.firstName}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.firstName}
              errorText={signupForm.errors.firstName}
              placeholder={"Enter First Name"}
            />
          </Col>
          <Col>
            <FormField
              id="lastName"
              name="lastName"
              fieldType="lastName"
              label={"Last name"}
              value={signupForm.values.lastName}
              onChange={(e) => handleFormikChange(e, signupForm)}
              showError={signupForm.touched.lastName}
              errorText={signupForm.errors.lastName}
              placeholder={"Enter Last Name"}
            />
          </Col>
        </Row>

        <TelephoneField
          id="mobileNo"
          name="mobileNo"
          fieldType="mobileNo"
          label={"Mobile No."}
          value={signupForm.values.mobileNo}
          showError={signupForm.touched.mobileNo}
          onChange={(mobileNo) =>
            handleFormikChange(mobileNo, signupForm, "mobileNo")
          }
          errorText={signupForm.errors.mobileNo}
        />

        <FormField
          id="email"
          name="email"
          fieldType="email"
          label={"Email"}
          value={signupForm.values.email}
          onChange={(e) => handleFormikChange(e, signupForm)}
          showError={signupForm.touched.email}
          errorText={signupForm.errors.email}
          placeholder={"Enter Email Address"}
        />

        <FormFieldSelect
          id="gender"
          name="gender"
          label={"Gender"}
          options={[
            { _id: "male", name: "Male" },
            { _id: "female", name: "Female" }
          ]}
          value={signupForm.values.gender}
          onChange={(e) => handleFormikChange(e, signupForm)}
          showError={signupForm.touched.gender}
          errorText={signupForm.errors.gender}
          placeholder={"Select Gender"}
        />

        <DateFormField
          id="dateOfBirth"
          name="dateOfBirth"
          label={"Date of Birth"}
          value={signupForm.values.dateOfBirth}
          onChange={(e) => handleFormikChange(e, signupForm)}
          showError={signupForm.touched.dateOfBirth}
          errorText={signupForm.errors.dateOfBirth}
          placeholder={"Select Date of Birth"}
        />

        <div className="d-flex align-items-center justify-content-between">
          <CheckboxField
            id="terms"
            name="terms"
            label={"I accept the terms and conditions"}
            checked={signupForm.values.terms}
            onChange={signupForm.handleChange}
            errorText={signupForm.errors.terms}
          />
        </div>


        <div className="theme-formbtn">
          <Button
            // to="/getdetail"
            variant="primary"
            type="submit"
            disabled={showingLoading}
          >
            Register
          </Button>
        </div>
        <div className="formbtngroups">
          <h6>
            Already Have an Account?
            <Link className="linkbtn" to="/login">
              Login
            </Link>
          </h6>
        </div>

      </Form>
    </>
  );
};

export default SignupForm;
