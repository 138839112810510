import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import AsyncSelect from 'react-select/async';
import { haveValue } from "../../Utils/helpers";

const FormFieldSelectSearch = (props: any) => {
  // Prop Destructuring
  const {
    label = "",
    errorText = "",
    onChange = () => { },
    placeholder = "",
    options = [],
    value = null,
    showError = false,
    icon = ""
  } = props;

  // State Variables   
  const [haveError, setHaveError] = useState(false);
  const [selectDefaultValue, setSelectDefaultValue]: any = useState({});

  // Mounting
  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
    if (haveValue(props?.value)) {
      setSelectDefaultValue(props?.value);
    } else {
      setSelectDefaultValue({});
    }

  }, [props]);

  const handleSelectChange = (data) => {
    if(props?.multiple){
      props?.onChange(data);
    }else{
      props?.onChange(data?.value);
    }
  }

  return (
    <>
      <Form.Group
        className={`theme-forminput theme-formselectsearch ${haveError ? "erroractive" : ""}`}
      >
        {/* <Form.Label>{label}</Form.Label> */}
        <InputGroup>
          <InputGroup.Text id="basic-addon1"> <i className={icon} aria-hidden="true"></i></InputGroup.Text>
          <AsyncSelect
            isMulti={props?.multiple}
            defaultOptions={props?.defaultOptions || []}
            value={selectDefaultValue}  
            defaultValue={selectDefaultValue}
            onChange={handleSelectChange}
            loadOptions={props?.loadOptions}
            placeholder={props?.placeholder || "Select"}
          />
        </InputGroup>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormFieldSelectSearch;