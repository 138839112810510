import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SignatureCanvas from 'react-signature-canvas'
import { BiRefresh } from "react-icons/bi";
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from "../../Redux/slices/toastSlice";
import { haveValue } from "../../Utils/helpers";
import { savePrescription, sendPrescription } from "../../Services/doctor-service";
import DateFormField from "../FormFields/DateFormField";
import ButtonElement from "../Common/ButtonElement";
import PrescriptionSlip from "./PrescriptionSlip";
import CreatableSelect from 'react-select/creatable';
import { MEDICINE_LIST } from "../../Utils/constants";


function AppointmentPrescription(props) {

  const { appointment } = props;
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [signRef, setSignRef]: any = useState(null);

  const [medicalHistories, setMedicalHistories] = useState([{
    medicalHistory: ""
  }]);

  const [diagnosisHistories, setDiagnosisHistories] = useState([{
    diagnosisHistory: ""
  }]);

  const [currentDiagnosis, setCurrentDiagnosis] = useState([{
    currentDiagnosis: ""
  }]);

  const [medicines, setMedicines] = useState([{
    medicine: "",
    dosage: "",
    duration: ""
  }]);

  const [advice, setAdvice] = useState([{
    advice: ""
  }]);

  const [followUp, setFollowUp] = useState(null);
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    if (haveValue(appointment?.prescription)) {
      setStep(3);
      let prescription = appointment?.prescription;
      setMedicalHistories(prescription?.medicalHistories);
      setDiagnosisHistories(prescription?.diagnosisHistories);
      setCurrentDiagnosis(prescription?.currentDiagnosis);
      setMedicines(prescription?.medicines);
      setAdvice(prescription?.advice);
      setFollowUp(prescription?.followUp);
      setSignature(prescription?.signature);
    } else {
      setStep(1);
    }
  }, [appointment]);

  //medicines
  const addMoreMedicines = () => {
    let medicinesTemp = [...medicines];
    medicinesTemp.push({
      medicine: "",
      dosage: "",
      duration: ""
    });
    setMedicines(medicinesTemp);
  }

  const removeMedicine = (index) => {
    let medicinesTemp = [...medicines];
    if (medicinesTemp.length > 1) {
      medicinesTemp.splice(index, 1);
      setMedicines(medicinesTemp);
    } else {
      dispatch(showErrorToast("Atleast one is required"));
    }
  }

  const updateMedicine = (index, e, fieldType) => {
    // console.log(e)
    let medicinesTemp = [...medicines];
    medicinesTemp[index][fieldType] = e.target.value;
    // console.log(medicinesTemp)
    setMedicines(medicinesTemp);
  }

  //advice
  const addMoreAdvice = () => {
    let adviceTemp = [...advice];
    adviceTemp.push({
      advice: ""
    });
    setAdvice(adviceTemp);
  }

  const removeAdvice = (index) => {
    let adviceTemp = [...advice];
    if (adviceTemp.length > 1) {
      adviceTemp.splice(index, 1);
      setAdvice(adviceTemp);
    } else {
      dispatch(showErrorToast("Atleast one is required"));
    }
  }

  const updateAdvice = (index, e) => {
    let adviceTemp = [...advice];
    adviceTemp[index]['advice'] = e.target.value;
    setAdvice(adviceTemp);
  }

  //medicalHistories
  const addMoreMedicalHistroy = () => {
    let medicalHistoriesTemp = [...medicalHistories];
    medicalHistoriesTemp.push({
      medicalHistory: ""
    });
    setMedicalHistories(medicalHistoriesTemp);
  }

  const removeMedicalHistory = (index) => {
    let medicalHistoriesTemp = [...medicalHistories];
    if (medicalHistoriesTemp.length > 1) {
      medicalHistoriesTemp.splice(index, 1);
      setMedicalHistories(medicalHistoriesTemp);
    } else {
      dispatch(showErrorToast("Atleast one is required"));
    }
  }

  const updateMedicalHistory = (index, e) => {
    let medicalHistoriesTemp = [...medicalHistories];
    medicalHistoriesTemp[index]['medicalHistory'] = e.target.value;
    setMedicalHistories(medicalHistoriesTemp);
  }

  //diagnosisHistories
  const addMoreDiagnosisHistory = () => {
    let diagnosisHistoriesTemp = [...diagnosisHistories];
    diagnosisHistoriesTemp.push({
      diagnosisHistory: ""
    });
    setDiagnosisHistories(diagnosisHistoriesTemp);
  }

  const removeDiagnosisHistory = (index) => {
    let diagnosisHistoriesTemp = [...diagnosisHistories];
    if (diagnosisHistoriesTemp.length > 1) {
      diagnosisHistoriesTemp.splice(index, 1);
      setDiagnosisHistories(diagnosisHistoriesTemp);
    } else {
      dispatch(showErrorToast("Atleast one is required"));
    }
  }

  const updateDiagnosisHistory = (index, e) => {
    let diagnosisHistoriesTemp = [...diagnosisHistories];
    diagnosisHistoriesTemp[index]['diagnosisHistory'] = e.target.value;
    setDiagnosisHistories(diagnosisHistoriesTemp);
  }

  //currentDiagnosis
  const addMoreCurrentDiagnosis = () => {
    let currentDiagnosisTemp = [...currentDiagnosis];
    currentDiagnosisTemp.push({
      currentDiagnosis: ""
    });
    setCurrentDiagnosis(currentDiagnosisTemp);
  }

  const removeCurrentDiagnosis = (index) => {
    let currentDiagnosisTemp = [...currentDiagnosis];
    if (currentDiagnosisTemp.length > 1) {
      currentDiagnosisTemp.splice(index, 1);
      setCurrentDiagnosis(currentDiagnosisTemp);
    } else {
      dispatch(showErrorToast("Atleast one is required"));
    }
  }

  const updateCurrentDiagnosis = (index, e) => {
    let currentDiagnosisTemp = [...currentDiagnosis];
    currentDiagnosisTemp[index]['currentDiagnosis'] = e.target.value;
    setCurrentDiagnosis(currentDiagnosisTemp);
  }

  const goToSignStage = () => {
    setStep(2);
  }

  const goBackFromSignStage = () => {
    setStep(1);
  }

  const redoSign = () => {
    if (signRef !== null) {
      // @ts-ignore   
      signRef.clear();
    }
  }

  const signDone = () => {
    if (signRef !== null) {
      // @ts-ignore   
      let imageUrl = signRef.getTrimmedCanvas().toDataURL('image/png');
      setSignature(imageUrl);
    }
  }

  const saveAndContinue = () => {
    if (haveValue(signature)) {
      dispatch(showLoadingToast("Saving Prescription"));
      savePrescription({
        appointment: appointment?._id,
        medicalHistories,
        diagnosisHistories,
        currentDiagnosis,
        medicines,
        advice,
        followUp,
        signature
      }).then((res: any) => {
        dispatch(showSuccessToast(res?.message));
        setStep(3);
      }).catch((err: any) => {
        dispatch(showErrorToast(err?.message));
      }).finally(() => {
        dispatch(hideLoadingToast(null));
      });
    } else {
      dispatch(showErrorToast("Signature is required"));
    }
  }

  const goBacktoEdit = () => {
    setStep(1);
  }

  const sendToPatient = () => {
    dispatch(showLoadingToast("Sending Prescription to Patient"));
    sendPrescription({
      appointment: appointment?._id
    }).then((res: any) => {
      dispatch(showSuccessToast(res?.message));
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  } 

  useEffect(() => {
    if (step == 2 && signRef !== null) {
      if (haveValue(signature)) {
        // signRef.fromDataURL(signature);
      }
    }
  }, [step, signRef]);

  const medicineOptions:any = MEDICINE_LIST.map(item => ({
    label: item,
    value: item
  }));

  return (
    <>
      <div className="appointment-prescription-area">

        {
          step === 1 ? <>
            <div className="form-group">
              <label>Medical History</label>
              <div className="fields">
                {
                  medicalHistories.map((medicalHistory, index) => {
                    return <>
                      <Row className="my-2">
                        <Col md={9}>
                          <input onChange={(e) => updateMedicalHistory(index, e)} className="form-control" value={medicalHistory?.medicalHistory} placeholder="Enter Medical History" />
                        </Col>
                        <Col md={3}>
                          <div className="inline-actions">
                            <a className="btn btn-outline-primary" onClick={(e) => addMoreMedicalHistroy()} href={undefined}>Add More</a>
                            <a className="btn btn-outline-danger" onClick={(e) => removeMedicalHistory(index)} href={undefined}>
                              <AiOutlineClose />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  })
                }
              </div>
            </div>

            <div className="form-group">
              <label>Diagnosis History</label>
              <div className="fields">
                {
                  diagnosisHistories.map((diagnosisHistory, index) => {
                    return <>
                      <Row className="my-2">
                        <Col md={9}>
                          <input onChange={(e) => updateDiagnosisHistory(index, e)} className="form-control" value={diagnosisHistory?.diagnosisHistory} placeholder="Enter Diagnosis History" />
                        </Col>
                        <Col md={3}>
                          <div className="inline-actions">
                            <a className="btn btn-outline-primary" onClick={(e) => addMoreDiagnosisHistory()} href={undefined}>Add More</a>
                            <a className="btn btn-outline-danger" onClick={(e) => removeDiagnosisHistory(index)} href={undefined}>
                              <AiOutlineClose />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  })
                }
              </div>
            </div>

            <div className="form-group">
              <label>Current Diagnosis</label>
              <div className="fields">
                {
                  currentDiagnosis.map((currentDiagnos, index) => {
                    return <>
                      <Row className="my-2">
                        <Col md={9}>
                          <input onChange={(e) => updateCurrentDiagnosis(index, e)} className="form-control" value={currentDiagnos?.currentDiagnosis} placeholder="Enter Current Diagnosis" />
                        </Col>
                        <Col md={3}>
                          <div className="inline-actions">
                            <a className="btn btn-outline-primary" onClick={(e) => addMoreCurrentDiagnosis()} href={undefined}>Add More</a>
                            <a className="btn btn-outline-danger" onClick={(e) => removeCurrentDiagnosis(index)} href={undefined}>
                              <AiOutlineClose />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  })
                }
              </div>
            </div>

            <hr />


            {
              medicines.map((medicine, index) => {
                return <>
                  <div className="form-group">
                    <div className="fields">
                      <Row className="my-2">
                        <Col md={9}> 
                          <Row>
                            <Col md={6}>
                              <label className="mb-2">Suggested Medicine</label>
                              <CreatableSelect onChange={(e:any) => updateMedicine(index, {target: {value: e.value}}, "medicine")} isClearable options={medicineOptions} />
                            </Col>
                            <Col md={3}>
                              <label className="mb-2">Dosage</label>
                              <input onChange={(e) => updateMedicine(index, e, "dosage")} className="form-control" value={medicine?.dosage} placeholder="Enter Dosage" />
                            </Col>
                            <Col md={3}>
                              <label className="mb-2">Duration</label>
                              <input onChange={(e) => updateMedicine(index, e, "duration")} className="form-control" value={medicine?.duration} placeholder="Enter Duraion" />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={3}>
                          <div className="inline-actions label">
                            <a className="btn btn-outline-primary" onClick={(e) => addMoreMedicines()} href={undefined}>Add More</a>
                            <a className="btn btn-outline-danger" onClick={(e) => removeMedicine(index)} href={undefined}>
                              <AiOutlineClose />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              })
            }

            <hr />

            <div className="form-group">
              <label>Advice</label>
              <div className="fields">
                {
                  advice.map((adviceItem, index) => {
                    return <>
                      <Row className="my-2">
                        <Col md={9}>
                          <input onChange={(e) => updateAdvice(index, e)} className="form-control" value={adviceItem?.advice} placeholder="Enter Advice" />
                        </Col>
                        <Col md={3}>
                          <div className="inline-actions">
                            <a className="btn btn-outline-primary" onClick={(e) => addMoreAdvice()} href={undefined}>Add More</a>
                            <a className="btn btn-outline-danger" onClick={(e) => removeAdvice(index)} href={undefined}>
                              <AiOutlineClose />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </>
                  })
                }
              </div>
            </div>

            <hr />

            <DateFormField
              id="followUp"
              name="followUp"
              label={"Follow Up"}
              value={followUp}
              onChange={(e) => {
                setFollowUp(e.target.value);
              }}
              placeholder={"Select Followup Date"}
            />

            <div className="theme-formbtn">
              <ButtonElement variant="primary" type="button" onClick={goToSignStage} className="w-100">
                Next
              </ButtonElement>
            </div>

          </> : null
        }

        {
          step === 2 ? <>
            <div className="sign-pad center">
              <SignatureCanvas onEnd={signDone} penColor='black'
                canvasProps={{ width: 700, height: 300, className: 'sigCanvas' }} ref={(ref) => { setSignRef(ref) }} />
            </div>
            <div className="text-center">
              <a onClick={redoSign} className="text-primary my-2 d-inline-block" href={undefined}>
                <BiRefresh />
                <span>Re-do Signature</span>
              </a>
            </div>

            <div className="theme-formbtn center">
              <ButtonElement variant="outline-primary" type="button" onClick={goBackFromSignStage} className="me-2">
                Back
              </ButtonElement>
              <ButtonElement variant="primary" type="button" onClick={saveAndContinue} className="">
                Save and Next
              </ButtonElement>
            </div>

          </> : null
        }


        {
          step === 3 ? <>

            <p>Preview Prescription</p>
            <div className="prescription-preview-box">
              <PrescriptionSlip appointment={appointment} />
            </div>

            <div className="theme-formbtn center">
              <ButtonElement variant="outline-primary" type="button" onClick={goBacktoEdit} className="me-2">
                Back
              </ButtonElement>
              <ButtonElement variant="primary" type="button" onClick={sendToPatient} className="">
                Send
              </ButtonElement>
            </div>

          </> : null
        }
      </div>

    </>
  );
}

export default AppointmentPrescription;
