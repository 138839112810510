import { Tabs, TabsProps } from "antd";
import React from "react";
import DoctorLoginForm from "../../Components/Auth/DoctorLoginForm";

import LoginForm from "../../Components/Auth/LoginForm";
import useTranslator from "../../Hooks/useTranslator";

const Login = (props: any) => {
  const { _t } = useTranslator();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Patient',
      children: <LoginForm />
    },
    {
      key: '2',
      label: 'Doctor', 
      children: <DoctorLoginForm />
    }
  ];

  return (
    <>
      <div className="formtitlebar">
        <h4>Welcome</h4>
        <p>Welcome! Please enter your details.</p>
      </div>
      <Tabs
        className="theme-tabs"
        defaultActiveKey="1"
        items={items}
      />
    </>
  );
};

export default Login;
