import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BsCurrencyRupee, BsPeople } from 'react-icons/bs';
import { BiCalendar, BiDownArrowAlt, BiPlus, BiUpArrowAlt } from 'react-icons/bi';
import { AiOutlineMedicineBox } from 'react-icons/ai';
import ButtonElement from '../../Components/Common/ButtonElement';
import CaseStudyItem from '../../Components/Site/CaseStudyItem';
import FaqItem from '../../Components/Site/Faq/FaqItem';
import TestimonialBox from '../../Components/Site/Testimonials/TestimonialBox';
import ImageSelector from '../../Components/FormFields/ImageSelector';
import { Accordion, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { addTestimonial, getTestimonial, updateTestimonial } from '../../Services/testimonial-service';
import { addFaq, getFaq, updateFaq } from '../../Services/faq-service';

import { useDispatch } from 'react-redux';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import { haveValue } from '../../Utils/helpers';
import { getAllAbouts } from '../../Services/public-service';
import { uploadBlogImage } from '../../Services/media-service';
import { addAllAbout } from '../../Services/all-about-service';


const HomeSettings = () => {

  const dispatch = useDispatch();
  const [showModalTestimonial, setShowModalTestimonial] = useState(false);
  const [showModalFaq, setShowModalFaq] = useState(false);
  const [allAboutModalShow, setAllAboutModalShow] = useState(false);

  const [testimonials, setTestimonials]: any = useState([]);
  const [faqs, setFaqs]: any = useState([]);
  const [faqId, setFaqId] = useState(null);
  const [testimonialId, setTestimonialId] = useState(null);
  const [allAbouts, setAllAbouts] = useState([]);

  const [testimonial, setTestimonial] = useState({
    rating: "",
    body: "",
    author: "",
    imgUrl: "https://images.unsplash.com/photo-1612349317150-e413f6a5b16d",
    authorAddress: ""
  })

  const [faq, setFaq] = useState({
    question: "",
    answer: "",
  });

  const [allAbout, setAllAbout] = useState({
    title: "",
    body: "",
    videoUrl: "",
  });

  useEffect(() => {
    loadBanner();
    loadTestimonials();
    loadFaqs();
    loadAllAbouts();
  }, []);

  const loadBanner = () => {
    getTestimonial()
      .then((res: any) => {
        setTestimonials(res?.data?.docs);
      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
        setShowModalTestimonial(false);
      })
  }

  const loadTestimonials = () => {
    getTestimonial()
      .then((res: any) => {
        setTestimonials(res?.data?.docs);
      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
        setShowModalTestimonial(false);
      });
  }

  const loadFaqs = () => {
    getFaq()
      .then((res: any) => {
        setFaqs(res?.data?.docs);

      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      })
  }

  const loadAllAbouts = () => {
    getAllAbouts()
      .then((res: any) => {
        setAllAbouts(res?.data?.docs);
      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      })
  }

  const handleChange = (e) => {
    setTestimonial({ ...testimonial, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("testimonial submiited",testimonial);
    dispatch(showLoadingToast("Saving Testimonial"))
    let request: any = null;
    if (haveValue(testimonialId)) {
      request = updateTestimonial(testimonialId, testimonial);
    } else {
      request = addTestimonial(testimonial);
    }
    request.then(res => {
      dispatch(showSuccessToast("Testimonial Saved"))
    })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
        setShowModalTestimonial(false);
        loadTestimonials();
        setTestimonialId(null);
      })
  }

  const handleChangeFaq = (e) => {
    setFaq({ ...faq, [e.target.name]: e.target.value })
  }

  const handleSubmitFaq = (e) => {
    e.preventDefault();
    //console.log("faq submiited",testimonial);
    dispatch(showLoadingToast("Saving Faq"));
    let request: any = null;
    if (haveValue(faqId)) {
      request = updateFaq(faqId, faq);
    } else {
      request = addFaq(faq);
    }
    request.then(res => {
      dispatch(showSuccessToast("Faq Saved"))
    })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
        setShowModalFaq(false);
        loadFaqs();
        setFaqId(null);
      })
  }

  const onTestimonialEdit = (testimonial: any) => {
    setTestimonialId(testimonial?._id);
    setTestimonial({
      rating: testimonial?.rating,
      body: testimonial?.body,
      author: testimonial?.author,
      imgUrl: testimonial?.imgUrl,
      authorAddress: testimonial?.authorAddress
    });
    setShowModalTestimonial(true);
  }

  const onFaqEdit = (faq: any) => {
    setFaqId(faq?._id);
    setFaq({
      question: faq?.question,
      answer: faq?.answer
    });
    setShowModalFaq(true);
  }

  const mediaChange = (e: any) => {
    var formData = new FormData();
    formData.append("media", e.target.files[0]);
    dispatch(showLoadingToast("Uploading Image"))
    uploadBlogImage(formData)
      .then((res: any) => {
        console.log(res);
        setAllAbout({ ...allAbout, videoUrl: res?.data[0]?.path })
        dispatch(hideLoadingToast(null));

      })
      .catch(err => {
        dispatch(showErrorToast(err?.message))
      })
      .finally(() => {
        dispatch(hideLoadingToast(null));
      })
  }

  const handleAllAboutChange = (e) => {
    setAllAbout({ ...allAbout, [e.target.name]: e.target.value })
  }

  const submitAllAbout = (e) => {
    e.preventDefault();
    dispatch(showLoadingToast(true));
    addAllAbout(allAbout)
      .then((res: any) => {
        dispatch(showSuccessToast(res?.message));
        loadAllAbouts();
      }).catch(err => {
        dispatch(showErrorToast(err?.message));
      })
      .finally(() => {
        setAllAboutModalShow(false);
        dispatch(hideLoadingToast(null));
      })
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Home</h1>
              </div>
            </Col>
          </Row>


          <Row>
            <Col md={12}>
              <h1 className='section-title-6'>Banner</h1>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col>
              <ImageSelector />
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md={12}>
              <h1 className='section-title-6'>All About Our Platform</h1>
              <ButtonElement onClick={() => setAllAboutModalShow(true)}>
                <BiPlus /> Add Video
              </ButtonElement>
            </Col>
          </Row>

          <Row>
            {allAbouts && allAbouts.map((allAbout, index) => (
              <Col md={3}>
                <CaseStudyItem onRefresh={loadAllAbouts} actions={true} allAbout={allAbout} />
              </Col>
            ))}
          </Row>

          <hr />

          <Row>
            <Col md={12}>
              <h1 className='section-title-6'>Testimonials</h1>
              <ButtonElement onClick={() => setShowModalTestimonial(true)}>
                <BiPlus /> Add Testimonial
              </ButtonElement>
            </Col>
          </Row>

          <Row>
            {testimonials && testimonials.map((testimonial, index) => (
              <Col md={3}>
                <TestimonialBox handleEdit={onTestimonialEdit} onRefresh={loadTestimonials} actions={true} testimonial={testimonial} />
              </Col>
            ))}
          </Row>

          <hr />

          <Row>
            <Col md={12}>
              <h1 className='section-title-6'>FAQs</h1>
              <ButtonElement onClick={() => setShowModalFaq(true)}>
                <BiPlus /> Add FAQ
              </ButtonElement>
            </Col>
          </Row>

          <Row>
            {faqs && faqs.map((faq, index) => (
              <Col md={12}>
                <Accordion>
                  <FaqItem handleEdit={onFaqEdit} onRefresh={loadFaqs} actions={true} faq={faq} i={index + 1} />
                </Accordion>
              </Col>
            ))}
          </Row>

        </div>
      </div>

      <Modal
        className="verification_main_modal"
        show={showModalTestimonial}
        onHide={() => setShowModalTestimonial(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Add Testimonial</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className='theme-forminput'>
              <label>Rating</label>
              <input value={testimonial?.rating} name="rating" type="text" placeholder="1 to 5" onChange={handleChange} />
            </div>
            <div className='theme-forminput'>
              <label>Message</label>
              <textarea value={testimonial?.body} name="body" className='form-control' rows={10} placeholder="Enter Message" onChange={handleChange}></textarea>
            </div>
            <div className='theme-forminput'>
              <label>Author</label>
              <input value={testimonial?.author} name="author" type="text" placeholder="John Doe" onChange={handleChange} />
            </div>
            <div className='theme-forminput'>
              <label>Author Address</label>
              <input value={testimonial?.authorAddress} name="authorAddress" type="text" placeholder="LA, Usa" onChange={handleChange} />
            </div>
            <ButtonElement type="submit" className="mt-2 w-100">
              <BiPlus /> Add
            </ButtonElement>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="verification_main_modal"
        show={showModalFaq}
        onHide={() => setShowModalFaq(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Add Faq</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmitFaq}>
            <div className='theme-forminput'>
              <label>Question</label>
              <input value={faq?.question} name="question" type="text" placeholder="Enter Question" onChange={handleChangeFaq} />
            </div>
            <div className='theme-forminput'>
              <label>Answer</label>
              <textarea value={faq?.answer} name="answer" className='form-control' rows={10} placeholder="Enter Answer" onChange={handleChangeFaq}></textarea>
            </div>
            <ButtonElement type="submit" className="mt-2 w-100">
              <BiPlus /> Add
            </ButtonElement>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="verification_main_modal"
        show={allAboutModalShow}
        onHide={() => setAllAboutModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Add All About</Modal.Header>
        <Modal.Body>
          <form onSubmit={submitAllAbout}>
            <div className='theme-forminput'>
              <label>Upload Video</label>
              <input name="media" type="file" placeholder="Upload Video" onChange={mediaChange} />
            </div>
            <div className='theme-forminput'>
              <label>Title</label>
              <input value={allAbout?.title} name="title" type="text" placeholder="Enter Question" onChange={handleAllAboutChange} />
            </div>
            <div className='theme-forminput'>
              <label>Description</label>
              <textarea value={allAbout?.body} name="body" className='form-control' rows={10} placeholder="Enter Answer" onChange={handleAllAboutChange}></textarea>
            </div>
            <ButtonElement type="submit" className="mt-2 w-100">
              <BiPlus /> Add
            </ButtonElement>
          </form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default HomeSettings;