import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import { useSelector } from "react-redux";
import ButtonLinkElement from "../Common/ButtonLinkElement";

const HomeAbout = (props) => {

  const { configurations } = useSelector((state: any) => state);
  
  return (
    <>
      <h2 className="text-center">Serving <b>{configurations?.totalPatients}+</b> Patients Globally</h2>
      <section id="about-us" className="section-pad">
        <Container>
          <div id="about-us-content">
            <Row>
              <Col md={12} lg={6}></Col>
              <Col md={12} lg={6}>
                <div className="about-us-box-main">
                  <div className="about-us-box">
                    <h3>About Us</h3>
                    <h1>Compassionate experts empowering mental health</h1>
                    <p>We're a team of experienced psychiatrists devoted to supporting
                      mental health. Our focus is on helping with all kinds of mental health issues for a
                      brighter, healthier tomorrow.</p>
                    <div className="about-features">
                      <ul>
                        <li>
                          <span className="icon"><BiSelectMultiple /></span>
                          <span>Tailored, effective therapies</span>
                        </li>
                        <li>
                          <span className="icon"><AiOutlineClockCircle /></span>
                          <span>24 Hours Mental health care service</span>
                        </li>
                      </ul>
                    </div>
                    <ButtonLinkElement to={"/about-us"} text="More About Us" variant="primary" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HomeAbout;
