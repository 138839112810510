import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const addAllAbout = (payload: any = {}) => {
    return sendRequest(endpoint.allAbout, "POST", payload);
};

export const getAllAbout = (payload: any = {}) => {
    return sendRequest(endpoint.allAbout, "GET", payload);
};

export const deleteAllAbout = (id: any) => {
    return sendRequest(`${endpoint.allAbout}/${id}`, "DELETE");
};

export const updateAllAbout = (id: any, payload: any = {}) => {
    return sendRequest(`${endpoint.allAbout}/${id}`, "POST", payload);
};


