import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ButtonElement from "./ButtonElement";

const ButtonLinkElement = (props) => {

  return (
    <>
      <Link to={props?.to}>
        <ButtonElement {...props}>{props?.text ? props?.text : props?.children}</ButtonElement>
      </Link>
    </>
  );
};

export default ButtonLinkElement;
