import moment from "moment";
import * as Joi from "joi-browser";
import config from "./../Config";
import { getLocalStorageItem } from "./localStorage";

export const getLoginToken = () => {
  return "Bearer " + getLocalStorageItem("token");
}

export const haveValue = (val: any) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const validateJOIProperty = (schema: any, name: string, value: any) => {
  const obj = { [name]: value };
  if (schema.hasOwnProperty(name)) {
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema, {
      stripUnknown: true,
    });
    const { error } = result;
    return error ? error.details[0].message : null;
  } else {
    return null;
  }
};

export const validateJOIFormField = (formFields: any, schema: any) => {
  const result = Joi.validate(formFields, schema, {
    abortEarly: false,
    stripUnknown: true,
  });
  const { error } = result;
  if (!error) {
    return null;
  } else {
    const errorData: any = {};
    for (let item of error.details) {
      const name = item.path[0];
      errorData[name] = item.message;
    }
    return errorData;
  }
};


export const withBackendUrl = (path: any, defaultPath = null) => {
  if (typeof path === "object") {
    let src = URL.createObjectURL(path);
    return src;
  } else {
    if (haveValue(path)) {
      path = path?.toString();
      if (path?.startsWith("http://") || path?.startsWith("https://")) {
        return path;
      } else {
        if (path.startsWith("/")) {
          path = path.substring(1);
        }
        return config.BACKEND_URL + "/" + path;
      }
    } else {
      return '';
    }
  }
};

export const toLowerCase = (data: any) => {
  return data.toString().toLowerCase();
};

export const toUpperCase = (data: any) => {
  return data.toString().toUpperCase();
};

export const makeSureObject = (data: any) => {
  if (!haveValue(data)) {
    data = {};
  }
  return data;
}

export const cloneObject = (data: any) => {
  return JSON.parse(JSON.stringify(data));
}

export const printDate = (timeData: any) => {
  return moment(timeData).format("ll");
}

export const printTime = (timeData: any) => {
  return moment(timeData).format("ll LT");
}

export const printOnlyTime = (timeData: any) => {
  return moment(timeData).format("LT");
}

export const printFullName = (userModel: any) => {
  return `${userModel?.firstName} ${userModel?.lastName}`;
}

export const printAmount = (moneyData: any, defaultVal: string = '-') => {
  if (haveValue(moneyData)) {
    return "₹" + moneyData;
  } else {
    return defaultVal;
  }
}

export const printNumber = (numData: any, defaultVal: string = '-') => {
  if (haveValue(numData)) {
    return numData;
  } else {
    return defaultVal;
  }
}

export const initialPaginationData = () => {
  return {
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    offset: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0
  };
}

export const objectToFormData = (obj: any, form: any = null, namespace: any = null) => {
  let fd = form || new FormData();
  let formKey: any = null;
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof Blob) &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Array)
      ) {
        objectToFormData(obj[property], fd, property);
      } else if (obj[property] instanceof Array) {
        for (var i = 0; i < obj[property].length; i++) {
          fd.append(formKey + "[]", obj[property][i]);
        }
      } else {
        if ((obj[property] instanceof File)) {
          fd.append(formKey.toString().replace(/\[\d+\]/, ''), obj[property]);
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
  }
  return fd;
}

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const isInputEvent = (event: any) => {
  if (event !== null) {
    if ((typeof event) === "object") {
      if (event.hasOwnProperty('target')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const handleFormikChange = (event, formik: any, fieldName: any = null) => {
  if (isInputEvent(event)) {
    // console.log("target", target, target.name, target.type, target.value, target.checked);
    let target = event.target;
    fieldName = target?.name;
    if (target.type !== "checkbox") {
      formik?.handleChange(event);
    } else {
      formik?.setFieldValue(fieldName, target.checked);
    }
  } else {
    formik?.setFieldValue(fieldName, event);
  }
  setTimeout((e) => {
    formik?.setFieldTouched(fieldName, true);
  }, 300);
}

export const handleFormikSubmit = (e, formik: any) => {
  e?.preventDefault();
  e?.stopPropagation();
  let fieldsToMarkTouched = {};
  const fields = Object.keys(formik?.initialValues);
  for (let field of fields) {
    fieldsToMarkTouched[field] = true;
  }
  formik?.handleSubmit();
  setTimeout((e) => {
    formik?.setTouched(fieldsToMarkTouched, true);
  }, 300);
}

export const underScoreToTitlecase = (inputString: any) => {
  inputString = inputString.toString().replace(/([a-z])([A-Z])/g, '$1 $2');
  return inputString.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const isValidMobileNumber = (number: any) => {
  const regex = /^(\+\d{2,4}\s?)?(\d{10}|\d{3}[-\s]?\d{3}[-\s]?\d{4})/;
  return regex.test(number);
}

export const isValidEmail = (email) => {
  const regex = /^[\w\.+-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export const clearGoogleRecapcthaElem = () => {
  let gElement: any = document.getElementById("recaptcha-container");
  if (gElement != null) {
    gElement.remove();
  }
  var div = document.createElement('div');
  div.id = 'recaptcha-container';
  document.body.appendChild(div);
}

export const getFirstWordOfString = (dataString: string) => {
  return dataString.split(" ")[0];
}