import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Steps = (props) => {
  return (
    <>
      <section id="steps" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title">
                <p>Three Steps</p>
                <h1>Our Doctors can help you</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={10} className="mx-auto">
              <Row>
                <Col md={12} lg={4}>
                  <div className="step-box">
                    <div className="img-box">
                      <img src="/images/step1.png" />
                    </div>
                    <div className="content-box">
                      <div className="title">
                        <h2>Step</h2>
                        <h1>1</h1>
                      </div>
                      <p>Sign up and create your personal account, providing necessary information about yourself and your mental health concerns. Ensure privacy and confidentiality with secure account settings.</p>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={4}>
                  <div className="step-box">
                    <div className="img-box">
                      <img src="/images/step2.png" />
                    </div>
                    <div className="content-box">
                      <div className="title">
                        <h2>Step</h2>
                        <h1>2</h1>
                      </div>
                      <p>Explore our pool of licensed mental health professionals and read their profiles to find the perfect match for your needs. Filter by specialization, availability, and preferred communication method.</p>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={4}>
                  <div className="step-box">
                    <div className="img-box">
                      <img src="/images/step3.png" />
                    </div>
                    <div className="content-box">
                      <div className="title">
                        <h2>Step</h2>
                        <h1>3</h1>
                      </div>
                      <p>Book a convenient online consultation session with your chosen Psychiatrist. Engage in a secure video call , sharing your concerns and receiving expert guidance and support from the comfort of your own space.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  );
};

export default Steps;
