import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const getDashboard = (payload: any = {}) => {
  return sendRequest(endpoint.doctor.DASHBOARD, "GET", payload);
};

export const getAppointments = (payload: any = {}) => {
  return sendRequest(endpoint.doctor.APPOINTMENTS, "GET", payload);
};

export const getPatients = (payload: any = {}) => {
  return sendRequest(endpoint.doctor.PATIENTS, "GET", payload);
};

export const savePrescription = (payload: any = {}) => {
  return sendRequest(endpoint.doctor.SAVE_PRESCRIPTIONN, "POST", payload);
};

export const sendPrescription = (payload: any = {}) => {
  return sendRequest(endpoint.doctor.SEND_PRESCRIPTIONN, "GET", payload);
};
