import { Col, Container, Row } from "react-bootstrap";

const AboutUs = (props) => {

  return (
    <>

      <section id="about" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>About Us</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={10} className="mx-auto">
              <div id="about-title">
                <Row>
                  <Col md={12} lg={7}>
                    <h1>We care for your mental health and Privacy</h1>
                  </Col>
                  <Col md={12} lg={5}>
                    <p>At Joyfullminds, we believe that everyone deserves access to the best mental health support available. Our team of renowned psychiatrists brings years of experience and expertise to the table, ensuring that you receive the highest standard of care. We prioritize your mental well-being and are dedicated to helping you navigate life's challenges, conquer mental health disorders, and achieve a fulfilling and balanced life.</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col md={12} lg={12} className="mx-auto">
              <div id="about-sec-image">
                <div id="about-stats">
                  <Row>
                    <Col md={12} lg={10} className="mx-auto">
                      <Row>
                        <Col md={12} lg={7}>
                          <div id="about-stats-box">
                            <ul>
                              <li>
                                <h1>12+</h1>
                                <p>Countries</p>
                              </li>
                              <li>
                                <h1>18K+</h1>
                                <p>Doctors</p>
                              </li>
                              <li>
                                <h1>30K+</h1>
                                <p>Total active Users</p>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col md={12} lg={12} className="mx-auto">
              <div id="about-vision-box">
                <Row>
                  <Col md={12} lg={10} className="mx-auto">
                    <div id="about-vision-content">
                      <Row>
                        <Col md={12} lg={6}>
                          <div className="about-vision-mission-box">
                            <h1>Our mision</h1>
                            <p>Our mission at Joyfullminds is to provide exceptional mental health services through a holistic approach, empowering individuals to overcome challenges, embrace their true potential, and lead meaningful lives. We are dedicated to delivering compassionate and evidence-based care, guided by the expertise of top psychiatrists, to help our clients achieve optimal mental well-being.</p>
                          </div>
                        </Col>
                        <Col md={12} lg={6}>
                          <div className="about-vision-mission-box">
                            <h1>Our Vision</h1>
                            <p>At Joyfullminds, our vision is to create a world where mental health is prioritized and individuals can experience joy, fulfillment, and emotional well-being. We aspire to be the leading provider of mental health services, guided by top psychiatrists, and to make a positive impact on the lives of those who seek our care.</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

        </Container>
      </section>

      <section className="section-pad">
        <Container>
          <div className="about-content-card right">
            <Row>
              <Col md={12} lg={6}>
                <div className="content-box">
                  <h1>Our team of creatives</h1>
                  <p>At Joyfullminds our psychiatrists are extensively trained in addressing a wide spectrum of mental health challenges, equipped with a diverse skill set to cater to every individual's needs. With unwavering commitment and a deep understanding of human psychology, they bring empathy and expertise to every session. Whether it's anxiety, depression, bipolar disorder, or any other mental health concern, our trained psychiatrists provide personalized and evidence-based treatments, empowering you to navigate life's challenges with resilience. Take the first step toward healing and embrace the support of our accomplished psychiatrists, who are devoted to helping you reclaim your mental wellness and rediscover a fulfilling life.</p>
                </div>
              </Col>
              <Col md={12} lg={6}>
                <div className="img-box" style={{ backgroundImage: "url('/images/about1-txt.png')" }}></div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="section-pad">
        <Container>
          <div className="about-content-card">
            <Row>
              <Col md={12} lg={6}>
                <div className="img-box" style={{ backgroundImage: "url('/images/about2-txt.png')" }}></div>
              </Col>
              <Col md={12} lg={6}>
                <div className="content-box">
                  <h1>Our team of creatives</h1>
                  <p>At JoyfullMinds, our team of psychiatrists comprises seasoned experts who possess unparalleled proficiency in addressing a diverse range of mental health problems. With a wealth of experience and a deep-rooted passion for mental wellness, our psychiatrists are devoted to providing exceptional care and support to every individual who seeks our services.At JoyfullMinds, we take pride in fostering an environment of collaboration, where our psychiatrists work in synergy with other mental health specialists to design personalized treatment plans tailored to each individual's unique needs. Rest assured that with our expert psychiatrists by your side, you will receive the compassionate guidance and comprehensive solutions required to reclaim joy and well-being in your life.</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

    </>
  );
};

export default AboutUs;
