import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";

function NoResultsFound(props) {

    return (
        <> 
            <p className="py-3 text-center">No Results Found</p>
        </>
    );
}

export default NoResultsFound;
