import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const FormFieldArea = (props: any) => {
  const {
    label = "",
    errorText = "",
    fieldType = "",
    showError = false,
  } = props;

  const [type, setType] = useState("text");

  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordField, setIsPasswordField] = useState(false);
  const [haveError, setHaveError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
    setIsPasswordField(fieldType === "password");

    if (isPasswordField) {
      if (showPassword) {
        setType("text");
      } else {
        setType("password");
      }
    } else {
      setType(fieldType ?? "text");
    }
  }, [props, showPassword]);

  return (
    <>
      <Form.Group
        className={`theme-forminput ${haveError ? "erroractive" : ""}`}
      >
        <div className="d-flex align-items-center justify-content-between label-with-btn">
          <Form.Label>{label}</Form.Label>
        </div>
        <InputGroup>
          <div className="form-Input-group-fields">
            <Form.Control as="textarea" rows={3} {...props} type={type} />
            {isPasswordField ? (
              <>
                <InputGroup.Text
                  onClick={handleTogglePassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </InputGroup.Text>
              </>
            ) : null}
          </div>
        </InputGroup>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormFieldArea;
