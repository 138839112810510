
import { printDate } from "../../Utils/helpers";
import DoctorBox2 from "../Site/Doctors/DoctorBox2";
import PatientBox from "./PatientBox";

const DoctorPatientRow = (props) => {

    const { patient } = props;

    return (
        <>
            <tr>
                <td className="table-doctor-box">
                    <PatientBox patient={patient} />
                </td>
                <td>{printDate(patient?.dateOfBirth)}</td>
                <td>{patient?.gender}</td>
                <td>{patient?.email}</td>
                <td>{patient?.mobileNo}</td>
            </tr>
        </>
    );
};

export default DoctorPatientRow;
