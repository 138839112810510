import FormField from "./FormField";


const PasswordField = (props: any) => {

  return (
    <>
      <FormField {...props} fieldType="password" />
    </>
  );

};

export default PasswordField;
