import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const addTestimonial = (payload: any = {}) => {
    return sendRequest(endpoint.testimonial, "POST", payload);
};

export const updateTestimonial = (id: any,payload: any = {}) => {
    return sendRequest(`${endpoint.testimonial}/${id}`, "POST", payload);
};

export const getTestimonial = (payload: any = {}) => {
    return sendRequest(endpoint.testimonial, "GET", payload);
};

export const deleteTestimonial = (id: any) => {
    return sendRequest(`${endpoint.testimonial}/${id}`, "DELETE");
};