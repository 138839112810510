import { useDispatch } from "react-redux";
import { withBackendUrl } from "../../Utils/helpers";

const PrescriptionSlip = (props) => {

    const { appointment } = props;
    const dispatch = useDispatch();

    return (
        <>
            <div className="prescription-box">
                <iframe src={withBackendUrl(`public/prescription/${appointment?._id}`)} id="prescription-pdf-iframe"></iframe>
            </div>
        </>
    );
};

export default PrescriptionSlip;
