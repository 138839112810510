import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ButtonLinkElement from "../../Common/ButtonLinkElement";
import { getFirstWordOfString } from "../../../Utils/helpers";

const PharmacyItem = (props) => {

    const {medicine} = props;

    return (
        <>

            <div className="pharmacy-box">
                <div className="img-box" style={{ backgroundImage: `url(https://ui-avatars.com/api/?name=${encodeURI(getFirstWordOfString(medicine))})` }}></div>
                <div className="content-box">
                    <div className="title">
                        <h1>{medicine}</h1>
                        {/* <p>15ml Syrup in Bottle</p> */}
                    </div>
                    {/* <div className="info">
                        <span className="price">₹150</span>
                        <span>₹250</span>
                    </div>
                    <ButtonLinkElement text="Add to Cart" variant="primary" to="/" /> */}
                </div>
            </div>

        </>
    );
};

export default PharmacyItem;
