import { Col, Container, Row } from "react-bootstrap";
import ProfileForm from "../../Components/User/ProfileForm";

const EditProfile = (props) => {

  return (
    <>

      <section id="edit-profile" className="section-pad">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-3 left">
                <h1>Edit Profile</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} md={12} className="mx-auto">
              <ProfileForm />
            </Col>
          </Row>

        </Container>
      </section> 
 
    </>
  );
};

export default EditProfile;
