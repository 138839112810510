import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showErrorToast } from "../../../Redux/slices/toastSlice";
import { getAppointments } from "../../../Services/doctor-service";
import { initialPaginationData } from "../../../Utils/helpers";
import DoctorAppointmentItem from "../../Appointment/DoctorAppointmentItem";
import NoResultsFound from "../../Common/NoResultsFound";
import Pagination from "../../Common/Pagination";
import ContentLoader from "../../Loader/ContentLoader";

const DoctorAppointmentsList = (props) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [paginationData, setPaginationData] = useState(initialPaginationData());
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm]: any = useState("");

    const loadData = (page) => {
        setLoading(true);
        getAppointments({
            page,
            type: 'upcoming'
        }).then((res: any) => {
            setPaginationData(res?.data);
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        loadData(page);
    }, [searchTerm, page]);

    const handlePageChange = (page) => {
        setPage(page);
    }

    return (
        <>
            {
                loading ? <>
                    <ContentLoader />
                </> : <>

                    <div className="grid-table">
                        <Row className="grid-table-head">
                            <Col className="grid-table-head-column" lg={2} md={12}>
                                <h6>Patient</h6>
                            </Col>
                            <Col lg={10} md={12}>
                                <Row>
                                    <Col className="grid-table-head-column" lg={2} md={6}>
                                        <h6>Date of Birth</h6>
                                    </Col>
                                    <Col className="grid-table-head-column" lg={2} md={6}>
                                        <h6>Sex</h6>
                                    </Col>
                                    <Col className="grid-table-head-column" lg={2} md={6}>
                                        <h6>Date and Time</h6>
                                    </Col>
                                    <Col className="grid-table-head-column" lg={2} md={6}>
                                        <h6>Patient Type</h6>
                                    </Col>
                                    <Col className="grid-table-head-column" lg={2} md={6}>
                                        <h6>Prescription</h6>
                                    </Col>
                                    <Col className="grid-table-head-column" lg={2} md={6}>
                                        <h6>Actions</h6>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="grid-table-body">
                            {
                                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                                    {paginationData?.docs?.map((data: any, index) => (
                                        <Col lg={12} md={12} className="grid-table-item">
                                            <DoctorAppointmentItem onRefresh={() => loadData(page)} appointment={data} />
                                        </Col>
                                    ))}
                                </> : <>
                                    <NoResultsFound />
                                </>
                            }
                        </Row>
                    </div>

                    <Row>
                        <Col>
                            {
                                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                                    <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                                </> : null
                            }
                        </Col>
                    </Row>

                </>
            }

        </>
    );
};

export default DoctorAppointmentsList;
