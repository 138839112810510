import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

const HeaderTopBar = (props) => {
  const { isAuthenticated } = useSelector((state: any) => state.auth);

  return (
    <>
      <div className="site-header-bar">
        <Container>
          <Row>
            <Col md={12} lg={6} className="left">
              <p>Welcome to Joyfullminds. We care for your mental health and privacy.</p>
            </Col>
            <Col md={12} lg={6} className="right">
              <ul>
                <li>
                  <Link to={"/"}>
                    <span><FiPhoneCall /></span>
                    <span>+91 96251-68580</span>
                  </Link>
                </li>
                <li>
                  <Link to={"/"}>
                    <span><AiOutlineMail /></span>
                    <span>munish.kanwar@joyfullminds.com</span>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeaderTopBar;
