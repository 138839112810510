import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import DoctorBox2 from '../../Components/Site/Doctors/DoctorBox2';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { initialPaginationData } from '../../Utils/helpers';
import { getPatients } from '../../Services/doctor-service';
import { showErrorToast } from '../../Redux/slices/toastSlice';
import Pagination from '../../Components/Common/Pagination';
import ContentLoader from '../../Components/Loader/ContentLoader';
import NoTabRow from '../../Components/Common/NoTabRow';
import DoctorPatientRow from '../../Components/Patient/DoctorPatientRow';

const DoctorPatients = () => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm]: any = useState("");

  const loadData = (page) => {
    setLoading(true);
    getPatients({
      page,
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Patients</h1>
              </div>
            </Col>
          </Row>

          {
            loading ? <>
              <ContentLoader />
            </> : <>

              <Row>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table theme-table">
                      <thead>
                        <tr>
                          <th>Patient</th>
                          <th>Date of Birth</th>
                          <th>Sex</th>
                          <th>Email</th>
                          <th>Phone No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          paginationData?.docs && paginationData?.docs.length > 0 ? <>
                            {paginationData?.docs?.map((data: any, index) => (
                                <DoctorPatientRow patient={data} />
                            ))}
                          </> : <>
                            <NoTabRow />
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  {
                    paginationData?.docs && paginationData?.docs.length > 0 ? <>
                      <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                    </> : null
                  }
                </Col>
              </Row>

            </>
          }

        </div>
      </div>
    </>
  )
}

export default DoctorPatients;