import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  login,
} from "../../Services/auth-service";
import {
  handleFormikChange,
  handleFormikSubmit,
  haveValue,
} from "../../Utils/helpers";
import useTranslator from "../../Hooks/useTranslator";
import FormField from "../FormFields/FormField";
import CheckboxField from "../FormFields/CheckboxField";
import { useDispatch } from "react-redux";
import {
  hideLoadingToast,
  showErrorToast,
  showLoadingToast
} from "../../Redux/slices/toastSlice";
import { setLogin } from "../../Redux/slices/authSlice";
import ButtonElement from "../Common/ButtonElement";
import PasswordField from "../FormFields/PasswordField";

const AdminLoginForm = (props: any) => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const { showingLoading } = useSelector((state: any) => state.toast);

  const validationSchema = yup.object({
    username: yup
      .string()
      .required("Mobile no. or Email is Required"),
    password: yup
      .string()
      .required("Password is Required"),
  });

  const loginForm = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(showLoadingToast("Checking Login details"));
      login({
        ...values,
        type: 'admin',
        hash: values?.password,
        loginMethod: 'password',
        otp: '123456'
      })
        .then((res: any) => {
          // If email is not verified
          if (!haveValue(res?.data?.user?.emailVerifiedAt)) {
            return dispatch(showErrorToast("Email is not verified"));
          }
          dispatch(
            setLogin({
              token: res.data.token,
              user: res.data.user,
            })
          );
        })
        .catch((err: any) => {
          dispatch(showErrorToast(err?.message));
        })
        .finally(() => {
          dispatch(hideLoadingToast(null));
        });
    },
  });

  return (
    <>
      <Form className="mt-3" onSubmit={(e) => handleFormikSubmit(e, loginForm)}>

        <FormField
          id="username"
          name="username"
          label={"Mobile no. or Email"}
          value={loginForm.values.username}
          onChange={(e) => handleFormikChange(e, loginForm)}
          showError={loginForm.touched.username}
          errorText={loginForm.errors.username}
          placeholder={"Enter Mobile no. or Email"}
        />

        <PasswordField
          id="password"
          name="password"
          label={"Password"}
          value={loginForm.values.password}
          onChange={(e) => handleFormikChange(e, loginForm)}
          showError={loginForm.touched.password}
          errorText={loginForm.errors.password}
          placeholder={"Enter Password"}
        />

        <div className="theme-formbtn">
          <ButtonElement className="w-100" variant="primary" type="submit" disabled={showingLoading}>
            Login
          </ButtonElement>
        </div>

      </Form>
    </>
  );
};

export default AdminLoginForm;
