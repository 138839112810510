import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { slickOptions } from "../../../Utils/constants";
import DoctorAppointmentTestimonial from "./DoctorAppointmentTestimonial";

const DoctorAppointmentTestimonials = (props) => {

  const { appointments } = props;

  const settings = {
    ...slickOptions,
    slidesToShow: 3
  };

  return (
    <>
      <section id="doctors" className="section-pad pt-0">
        <Container>

          <Row>
            <Col>
              <div className="section-title section-title-4 left">
                <h1>Patient Reviews</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="mx-auto">
              <Slider {...settings}>
              {
                  appointments.map((appointment) => {
                    return <>
                      <DoctorAppointmentTestimonial appointment={appointment} />
                    </>
                  })
                }
              </Slider>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  );
};

export default DoctorAppointmentTestimonials;
