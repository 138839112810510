import { Spin } from "antd";
import React from "react";

const MainLoader = () => {

    return (
        <>
            <div className="main-loader">
                <Spin />
            </div>
        </>
    );
};

export default MainLoader;
