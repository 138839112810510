import SignupForm from "../../Components/Auth/SignupForm";
import { useLocation, useNavigate } from "react-router-dom";
import DoctorRegisterForm from "../../Components/Auth/DoctorRegisterForm";
import { Tabs, TabsProps } from "antd";

const Signup = (props: any) => {
  // Router Variables
  const { state } = useLocation();
  const navigate = useNavigate();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Patient',
      children: <SignupForm {...state} />
    },
    {
      key: '2',
      label: 'Doctor', 
      children: <DoctorRegisterForm/>
    }
  ];
  
  return (
    <>
      <div className="formtitlebar">
        <h4>Register</h4>
        <p>Please register your account</p>
      </div>
      <Tabs
        className="theme-tabs"
        defaultActiveKey="1"
        items={items}
      />
    </>
  );
};

export default Signup;
