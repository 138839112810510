import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getBannerImage } from "../../Services/public-service";
import { withBackendUrl } from "../../Utils/helpers";

const Banner = (props) => {

  const [bannerImg,setBannerImg] = useState();

  useEffect(()=>{
    loadData();
  },[])

  const loadData = () =>{
    getBannerImage()
    .then((res:any)=>{
      //console.log(res);
      
      setBannerImg(res?.data?.imgUrl)
    })
    .catch(err=>{
      console.log(err);
    })
  }

  return (
    <>

      <section id="banner">
        <Container>
          <div className="site-banner">
            <Row>
              <Col md={12} lg={6} className="content">
                <h1>Enhancing Your Mental Well-being Improves Our Collective Wellness</h1>
                <p>Our team of skilled experts utilizes cutting-edge therapeutic approaches to help you achieve mental well-being with speed and simplicity.</p>
              </Col>
              <Col md={12} lg={6} className="right">
                <img src={withBackendUrl(bannerImg)} />
              </Col>
            </Row>
          </div>
        </Container>
      </section>

    </>
  );
};

export default Banner;
