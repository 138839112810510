import React from "react";
import { useDispatch } from "react-redux";

import LoginForm from "../../Components/Auth/LoginForm";
import ButtonElement from "../../Components/Common/ButtonElement";
import FormFieldRadios from "../../Components/FormFields/FormFieldRadios";
import { UserConstants } from "../../Constants/user";
import useTranslator from "../../Hooks/useTranslator";
import { setLogin } from "../../Redux/slices/authSlice";
import { hideLoadingToast, showLoadingToast } from "../../Redux/slices/toastSlice";
import { updateUserRole } from "../../Services/user-service";

const ChooseRole = (props: any) => {
  const { _t } = useTranslator();

  const dispatch = useDispatch();

  const updateRole = (role) => {
    dispatch(showLoadingToast(null));
    updateUserRole({ role }).then((res: any) => {
      dispatch(hideLoadingToast(null));
      dispatch(
        setLogin({
          token: res.data.token,
          user: res.data.user,
        })
      );
    }).catch((err) => {
      dispatch(hideLoadingToast(null));
    });
  }

  const iAmAPatient = () => {
    updateRole(UserConstants.ROLES.patient);
  }

  const iAmADoctor = () => {
    updateRole(UserConstants.ROLES.doctor);
  }

  return (
    <>
      <div className="formtitlebar">
        <h4>Choose your Role to Continue</h4>
      </div>

      <div className="theme-formbtn">
        <ButtonElement onClick={iAmAPatient} variant="primary">
          I am a Patient
        </ButtonElement>
      </div>

      <div className="googlebtn mt-3">
        <button
          type="button"
          onClick={iAmADoctor}
          className="googlebtn btn-green"
        >
          I am a Doctor
        </button>
      </div>

    </>
  );
};

export default ChooseRole;
