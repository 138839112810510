import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiEdit } from 'react-icons/bi';
import { BsPencil } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import FormFieldSelect from '../../Components/FormFields/FormFieldSelect';
import ButtonElement from '../../Components/Common/ButtonElement';
import SearchBar from '../../Components/Common/SearchBar';
import DoctorBox2 from '../../Components/Site/Doctors/DoctorBox2';
import PatientFormModal from '../../Components/Modal/PatientFormModal';
import { useEffect, useState } from 'react';
import { deleteDoctor, deletePatient, getDoctors, getPatients } from '../../Services/admin-service';
import { initialPaginationData, printTime } from '../../Utils/helpers';
import { hideLoadingToast, showErrorToast, showLoadingToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import { useDispatch } from 'react-redux';
import Pagination from '../../Components/Common/Pagination';
import NoTabRow from '../../Components/Common/NoTabRow';
import PatientBox from '../../Components/Patient/PatientBox';

const AdminPatients = () => {

  const dispatch = useDispatch();

  const [modelData, setModelData]: any = useState(null);
  const [searchTerm, setSearchTerm]: any = useState("");
  const [sortType, setSortType]: any = useState("newest");

  const [showModal, setShowModel]: any = useState(false);
  const [paginationData, setPaginationData] = useState(initialPaginationData());
  const [page, setPage] = useState(1);

  const addDoctorOpen = () => {
    setShowModel(true);
  }

  const loadData = (page) => {
    dispatch(showLoadingToast(null));
    getPatients({
      page,
      search: searchTerm,
      sort: sortType
    }).then((res: any) => {
      setPaginationData(res?.data);
    }).catch((err: any) => {
      dispatch(showErrorToast(err?.message));
    }).finally(() => {
      dispatch(hideLoadingToast(null));
    });
  }

  useEffect(() => {
    loadData(page);
  }, [searchTerm, sortType, page]);

  const handlePageChange = (page) => {
    setPage(page);
  }

  const deleteIt = (data) => {
    deletePatient({
      _id: data?._id
    }).then((res) => {
      dispatch(showSuccessToast("Deleted successfully"));
      loadData(page);
    }).catch((err) => {
      dispatch(showErrorToast("Failed to delete"));
    });
  }

  const closeModal = () => {
    setShowModel(false);
    loadData(page);
    setModelData(null);
  }

  const handleEdit = (data) => {
    setModelData(data);
    setShowModel(true);
  }

  return (
    <>
      <div className='dashboard_layout ml-0'>
        <div className="cardcontainer">

          <Row>
            <Col>
              <div className="section-title section-title-5 left">
                <h1>Patients</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="table-head-options">
                <div className='left'>
                  <FormFieldSelect
                    id="type"
                    name="type"
                    label={false}
                    onChange={(e) => setSortType(e?.target?.value)}
                    options={[
                      { _id: "newest", name: "Newest" },
                      { _id: "oldest", name: "Oldest" },
                    ]}
                    value={sortType}
                    placeholder={"Select Type"}
                  />
                </div>
                <div className='right'>
                  <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                  <ButtonElement onClick={addDoctorOpen} className="ms-2" variant="primary" >
                    Add Patient
                  </ButtonElement>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date of Birth</th>
                      <th>Gender</th>
                      <th>Date Joined</th>
                      <th>Mobile No.</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paginationData?.docs && paginationData?.docs.length > 0 ? <>
                        {paginationData?.docs?.map((data: any, index) => (
                          <tr key={index}>
                            <td className="table-doctor-box">
                              <PatientBox patient={data} />
                            </td>
                            <td>{data?.dateOfBirth}</td>
                            <td>{data?.gender}</td>
                            <td>{printTime(data?.createdAt)}</td>
                            <td>{data?.mobileNo}</td>
                            <td>{data?.email}</td>
                            <td>
                              <div className='table-actions'>
                                {/* <a href={""}>View Details</a> */}
                                <a onClick={(e) => handleEdit(data)} href={undefined}><BsPencil /></a>
                                <a onClick={(e) => deleteIt(data)} href={undefined} className="danger"><AiOutlineDelete /></a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </> : <>
                        <NoTabRow colSpan={5} />
                      </>
                    }

                  </tbody>
                </table>
              </div>
            </Col>

            <Col md={12}>
              {
                paginationData?.docs && paginationData?.docs.length > 0 ? <>
                  <Pagination onPageChange={handlePageChange} paginationData={paginationData} />
                </> : null
              }
            </Col>

          </Row>

        </div>
      </div>

      <PatientFormModal modelData={modelData} onModalHide={(e) => closeModal()} showModal={showModal} />

    </>
  )
}

export default AdminPatients;