import CustomNextArrow from "../Components/Common/Carousel/CustomNextArrow";
import CustomPrevArrow from "../Components/Common/Carousel/CustomPrevArrow";

export const slickOptions = {
  dots: true,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  marginRight: "20px",
  responsive: [
    {
      breakpoint: 1299,
      settings: {
        slidesToShow: 3
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1
      },
    },
  ],
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
}

export const demoPaginationData = {
  docs: [[], []],
  hasNextPage: true,
  hasPrevPage: true,
  limit: 10,
  offset: 2,
  page: 2,
  pagingCounter: 10,
  totalDocs: 100,
  totalPages: 10
}

export const MEDICINE_LIST = [
  "Duloxetine 30 mg gastro-resistant",
  "Desvenlafaxine 50 mg",
  "Desvenlafaxine 100 mg",
  "Paroxetine 12.5 mg",
  "Paroxetine 25 mg",
  "Natural Vitamin E Omega 3 Fatty Acid and Wheat Germ Oil Soft Gelatin Capsules",
  "Rabeprazole Sodium Enteric Coated Itopride Hcl",
  "ALPHA LIPOIC ACID+BENFOTIAMINE+FOLIC ACID+PYRIDOXAL PHOSPHATE & CYANOCOBALAMIN TAB(NEUROVOIZ PLUS TAB)",
  "Sodium valproate and valproic acid controlled release tablets 500mg",
  "Sertraline hydrochloride tablets ip 50mg",
  "Thiamine tablets 100mg",
  "Calcium Citrate Maleate Calcitriol Methylcobalamin Folic Acid And Pyridoxine HCL Softgel Capsules(merish ct capsule)",
  "Bupropion hydrochloride extended-release tablets 150 mg",
  "Lorazepam 2mg",
  "Clomipramine hydrochloride 50 mg",
  "Clozepam 0.5mg Tablet MD",
  "Trihexyphenidyl hydrochloride 2 mg tablet",
  "Imipramine hydrochloride 25mg",
  "Aripiprazole 10 mg",
  "Etizolam tablets 0.5 mg",
  "Mirtazapine 15 mg",
  "Memantine hydrochloride and donepezil hydrochloride tablet 10mg",
  "Trifluoperazine and trihexyphenidyl tablets 5mg",
  "Modafinil 100mg",
  "Naltrexone hydrochloride 50 mg",
  "Amisulpride sustained release tablets 200mg",
  "Lithium carbonate tablets 300 mg",
  "Lurasidone hydrochloride 40 mg",
  "Acamprosate calcium 333 mg",
  "Quetiapine fumarate 50 mg+100mg",
  "Olanzapine 10 mg",
  "Flunarizine 10 mg",
  "Escitaloprám 10 mg",
  "Oxcarbazepine 600 mg tablet",
  "Telmisartan and hydrochlorothiazide 40/12.5 tablet",
  "Telmisartan 40 mg",
  "Telmisartan and amlodipine 40/5",
  "Ferrous ascorbate and folic acid and zinc soft gelatin capsules",
  "Calcium Citrate Calcitriol Vitamin K2-7 Omega-3 fatty Acid,Folic acid ,cyanocabalamin & Boron Softgel Capsules",
  "Levodopa and carbidopa prolonged release tablets 200mg + 50 mg",
  "Levodopa and carbidopa tablets 100mg + 25 mg",
  "Propranolol hydrochloride long acting tablets 40mg",
  "Cinnarizine sustained release tablets 75 mg",
  "Febuxostat 40 mg", 
  "Pantoprazole sodium gastro resistant tablets 40 mg",
  "Montelukast sodium and fexofenadine hydrochloride 10 mg + 120 mg",
  "Betahistine hydrochloride tablets 16 mg",
  "Pregabalin sustained release tablets 75 mg",
  "Pregabalin (75mg) + nortriptyline (10mg)",
  "Coenzyme q10 lycopene omega-3 fatty acids",
  "Fluoxetine 60mg capsules",
  "Fluoxetine 20mg capsules",
  "Nortriptyline hydrochloride 25 mg",
  "Sumatriptan and naproxen tablet",
  "Gabapentin 400 mg+nortriptyline 10 mg",
  "Citicoline and piracetam tablets",
  "Lamotrigine 50 mg",
  "Topiramate 50 mg",
  "Clobazam 10 mg",
  "Glycopyrrolate 1 mg",
  "Metformin hydrochloride prolonged release 500 mg and glimepiride tablets 2mg",
  "Vildagliptin 50 mg",
  "Pregabalin 150 mg",
  "Metformin hydrochloride prolonged release 1000 mg and glimepiride tablets 2mg",
  "Levetiracetam 500 mg",
  "Silymarin l-ornithine l-glutathione, n-acetyl cysteine co q10 inositol antioxidants tablet",
  "Syrup sucralfate and oxetacaine oral suspension",
  "Syrup Lycopene with multivitamin and multimineral",
  "Syrup Ferrous ascorbate and folic acid suspension",
  "Syrup cyproheptadine hydrochloride and tricholine citrate",
  "Syrup Liquid paraffin milk of magnesia and sodium picosulfate",
  "Syrup Dextromethorphan hydrobromide phenylephrine hydrochloride and chlorpheniramine maleate",
  "Syrup lycopene vitamin b1 b2 b6 b12 and niacinamide",
  "Syrup sodium valproate oral solution"
];